<script>
import Layout from "./base";
import ProductosDestacados from "../componentes/destacados";
import Swal from "sweetalert2";
import pdf from 'vue-pdf';

/**
 * Starter component
 */
export default {
    components: { Layout, ProductosDestacados,Swal, pdf },
    props:{
        submiturl: {
        type: String,
        required: true
        },
        autherror: {
        type: String,
        required: false,
        default: () => null
        },
        autenticado:'',
        producto:{}
    },
    data() {
        return {
            item:1,
            scroll:0,
            orden:[],
            espscroll:0,
            productos_dest:[],
            seleccion:{},
            precio_variable:false,
            screen:{},
            text: '',
            email:'',
            windowWidth4: window.innerWidth,
            windowHeight4: window.innerHeight,
            isLoading:false,
            leyenda:'',
        };
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        promocion(){
            if (this.producto.promocion) {
                if(this.producto.prom_tipo=='producto'){
                    if(this.producto.promo_prod.tipo=='volumenventa'){
                        if (this.item>=this.producto.promo_prod.cantidad) {
                            this.producto.precio_rebajado = this.producto.precio_normal- this.producto.precio_normal*(this.producto.promo_prod.porc_descuento/100)
                            this.producto.porcentaje = this.producto.promo_prod.porc_descuento
                            this.seleccion.precio = this.producto.precio_rebajado
                        }else{
                            this.producto.precio_rebajado=null;
                            this.producto.porcentaje=0;
                            this.seleccion.precio = this.producto.precio_normal
                        }
                    }else if (this.producto.promo_prod.tipo=='comprexllevey') {
                        if (this.item>=this.producto.promo_prod.cantidad) {
                        //consultar como manejar la cantidad
                        this.leyenda = this.producto.promo_prod.regalo.nombre;
                        }else{
                            this.leyenda=''
                        }
                    }
                    
                }
                if(this.producto.prom_tipo=='categoria'){
                    if(this.producto.promo_cat.tipo=='volumenventa'){
                        if (this.item>=this.producto.promo_cat.cantidad) {
                            this.producto.precio_rebajado = this.producto.precio_normal- this.producto.precio_normal*(this.producto.promo_cat.porc_descuento/100)
                            this.producto.porcentaje = this.producto.promo_cat.porc_descuento
                            this.seleccion.precio = this.producto.precio_rebajado
                        }else{
                            this.producto.precio_rebajado=null;
                            this.producto.porcentaje=0;
                            this.seleccion.precio = this.producto.precio_normal
                        }
                    }/*else if (this.producto.promo_cat.tipo=='comprexllevey') {
                        //consultar como manejar la cantidad
                    }*/
                    
                }
                if(this.producto.prom_tipo=='marca'){
                    if(this.producto.promo_marc.tipo=='volumenventa'){
                        if (this.item>=this.producto.promo_marc.cantidad) {
                            this.producto.precio_rebajado = this.producto.precio_normal- this.producto.precio_normal*(this.producto.promo_marc.porc_descuento/100)
                            this.producto.porcentaje = this.producto.promo_marc.porc_descuento
                            this.seleccion.precio = this.producto.precio_rebajado
                        }else{
                            this.producto.precio_rebajado=null;
                            this.producto.porcentaje=0;
                            this.seleccion.precio = this.producto.precio_normal
                        }
                    }/*else if (this.producto.promo_marc.tipo=='comprexllevey') {
                        //consultar como manejar la cantidad
                    }*/
                    
                }
            }
        },
        sumar(){
            if (this.item<this.producto.stock) {
                this.item++;
                this.promocion();
            }
        },
        restar(){
            if (this.item>0) {
                this.item--;
                this.promocion();
            }
        },
        variacion(){
            let atributos = this.producto.atributos;
            let titulo=this.orden.join("-");
            this.producto.variaciones.forEach(element => {
                if (element.titulo==titulo) {
                    this.seleccion.variacion_id=element.id;
                    this.seleccion.precio=element.precio_rebajado!=''?parseInt(element.precio_rebajado):parseInt(element.precio_normal);
                    this.seleccion.precio_rebajado=element.precio_rebajado;
                    this.seleccion.precio_normal=element.precio_normal;
                    this.producto.estado_inventario=element.estado_inventario;
                    this.producto.stock=element.stock;
                    this.seleccion.stock=element.stock;
                    this.seleccion.titulo=element.titulo;
                    this.seleccion.variacion=this.orden;
                    this.seleccion.porcentaje=element.porcentaje;
                    this.seleccion.ndescuento=element.ndescuento;
                    this.precio_variable=true;
                }
            });
        },
        agregar_carro(){
            this.seleccion.cantidad=this.item;
            //console.log(this.seleccion);
            localStorage.setItem('orden',JSON.stringify(this.seleccion));
            this.$store.dispatch('addToCart', this.seleccion);
            if(this.producto.promocion){
                if(this.producto.promo_prod){
                    if(this.producto.promo_prod.tipo=='comprexllevey' && this.seleccion.cantidad==this.producto.promo_prod.cantidad ){
                        //console.log(this.seleccion);
                        let regalo = {
                            cantidad:parseInt(this.producto.promo_prod.cant_regalar),
                            codigo:this.producto.promo_prod.regalo.codigo,
                            imagen:this.producto.promo_prod.regalo.foto_dir,
                            marca:this.producto.promo_prod.regalo.marca,
                            nombre:this.producto.promo_prod.regalo.nombre,
                            precio:0,
                            precio_normal:0,
                            precio_rebajado:0,
                            producto_id:this.producto.promo_prod.regalo.id,
                            stock:this.producto.promo_prod.regalo.stock,
                            tipo:this.producto.promo_prod.regalo.tipo,
                            titulo:this.producto.promo_prod.regalo.nombre,
                            promocion:this.seleccion.producto_id
                        }
                        localStorage.setItem('orden',JSON.stringify(regalo));
                        this.$store.dispatch('addToCart', regalo);
                    }
                }
            }
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Se ha agregado un producto",
                showConfirmButton: false,
                timer: 1500
            });
            //window.location.reload();
        },
        comunicate(){
            this.$refs['modal-comunicate'].show();
            this.text = 'Quiero solicitar más información sobre '+ this.producto.nombre+'.'
        },
        enviar_mensaje(producto){ //Enviar correo solicitando mayor información
            this.isLoading=true
            let payload = {
                mail: this.email,
                texto: this.text,
                producto_id:producto.id
            };
            this.isLoading=true
            axios.post('/api/envio_info_reserva',payload).then(res=>{
                if(res.data.estado){
                    console.log('enviado')
                    console.log(res.data.producto)
                    console.log(res.data.mail)
                    console.log(res.data.texto)
                }
                this.isLoading=false
            })
        },
        handleResize() {
            this.windowWidth4 = window.innerWidth;
            this.windowHeight4 = window.innerHeight;
        }
    },
    created () {
        axios.get('/api/get_productos_web_destacados').then(res=>{
            this.productos_dest = res.data.productos;
            //console.table(this.productos);
        })
        this.seleccion.producto_id=this.producto.id;
        this.seleccion.tipo=this.producto.tipo;
        this.seleccion.nombre=this.producto.nombre;
        this.seleccion.codigo=this.producto.codigo;
        this.seleccion.marca=this.producto.marca;
        this.seleccion.imagen=this.producto.foto_dir;
        this.seleccion.promocion='';
        this.seleccion.cantidad='';
        if (this.producto.tipo=='Simple') {
            this.seleccion.precio=this.producto.precio_rebajado!=null?this.producto.precio_rebajado:this.producto.precio_normal;
            this.seleccion.precio_rebajado=this.producto.precio_rebajado;
            this.seleccion.precio_normal=this.producto.precio_normal;
            this.seleccion.titulo=this.producto.nombre;
            this.seleccion.stock=this.producto.stock;
        }else if (this.producto.tipo=='Variable') {
            this.seleccion.precio='';
            this.seleccion.precio_rebajado='';
            this.seleccion.precio_normal='';
            this.seleccion.titulo='';
        }
        this.screen=window.screen
    },
};
</script>
<template>
    <Layout :submiturl='submiturl' :autherror="autherror" :autenticado="autenticado" >
        <div class="mt-5 pt-5">
            <div class="loading-overlay" v-if="isLoading">
                <send-message  />
            </div>
            <div :id="windowWidth4<=576? '':'cover_container'" class="sticky_cover_container">
                <div :id="windowWidth4<=576? '':'cover_sticky'" class="two_column_cover_sticky">
                    <div>
                        <b-tabs pills vertical align="center">
                            <b-tab v-for="(imagen,index) in producto.imagenes" :key="index">
                                <template #title>
                                    <img
                                    :src="imagen"
                                    alt
                                    :class="windowWidth4<=768? 'avatar-sm':'avatar-lg'"
                                    />
                                </template>
                                <div class="cont">
                                    <img
                                        :src="imagen"
                                        alt
                                        class=" cont_img"
                                        />
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
                <div :id="windowWidth4<=576? '':'cover_text'" class="two_column_cover_text">
                    <div class="mb-3">
                        <a :href="'/filtrar/marca/'+producto.marca" class="text-primary">{{producto.nombre_marca}}</a>
                        <div v-if="producto.cat.length>=1 && (producto.cat[0]!=null || producto.cat[0]!='')">
                            <a :href="'/filtrar/categoria/'+categoria.id" class="text-primary" v-for="(categoria,index) in producto.cat" :key="index">{{categoria.nombre}}</a> 
                        </div>
                        <h4 class="mt-1 mb-3">{{producto.nombre}}</h4>
                        <p>SKU: {{producto.codigo}}</p>
                        <div v-if="producto.promocion" class="promotion-etiquet mb-2">Promoción</div>
                        <div v-if="producto.promocion">
                            <div v-if="producto.promo_prod">
                                <div v-if="producto.promo_prod.tipo=='volumenventa'">
                                    <p>{{ producto.promo_prod.porc_descuento }} % de descuento por la compra de {{ producto.promo_prod.cantidad }} {{producto.nombre}}</p>
                                </div>
                                <div v-else-if="producto.promo_prod.tipo=='comprexllevey'">
                                    <p>Por la compra de {{producto.promo_prod.cantidad}} {{producto.nombre}}
                                    Se regala {{ producto.promo_prod.cant_regalar }} de {{ producto.promo_prod.regalo.nombre }}</p>
                                </div>
                            </div>
                            <div v-else-if="producto.promo_cat">
                                <div v-if="producto.promo_cat.tipo=='volumenventa'">
                                    <p>{{ producto.promo_cat.porc_descuento }} % de descuento por la compra de {{ producto.promo_cat.cantidad }} {{producto.nombre}}</p>
                                </div>
                                <!-- <div v-else-if="producto.promo_prod.tipo=='comprexllevey'">
                                    <p>Por la compra de {{producto.promo_cat.cantidad}} {{producto.nombre}}
                                    Se regala 1 de </p>
                                </div> -->
                            </div>
                            <div v-else-if="producto.promo_marc">
                                <div v-if="producto.promo_marc.tipo=='volumenventa'">
                                    <p>{{ producto.promo_marc.porc_descuento }} % de descuento por la compra de {{ producto.promo_marc.cantidad }} {{producto.nombre}}</p>
                                </div>
                                <!-- <div v-else-if="producto.promo_marc.tipo=='comprexllevey'">
                                    <p>Por la compra de {{producto.promo_marc.cantidad}} {{producto.nombre}}
                                    Se regala 1 de </p>
                                </div> -->
                            </div>
                        </div>
                        <div v-if="producto.tipo=='Simple' && producto.precio_normal && producto.estado_inventario!='reservar'">
                            <h6 class="text-success text-uppercase" v-if="producto.precio_rebajado!=null">{{producto.porcentaje}} % descuento</h6>
                            <h5 class="mb-4" v-if="producto.precio_rebajado!=null">
                                Precio Web :
                                <span class="text-muted mr-2">
                                    <del>${{ producto.precio_normal?producto.precio_normal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."):'' }} </del>
                                </span>
                                <b>${{ producto.precio_rebajado?producto.precio_rebajado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."):'' }} </b>
                            </h5>
                            <h5 class="mb-4" v-else>
                                Precio Web :
                                <b>${{ producto.precio_normal?producto.precio_normal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."):'' }} </b>
                            </h5>
                        </div>
                        <div class="row" v-else-if="producto.tipo=='Variable' && producto.estado_inventario!='reservar'">
                            <div class="col-md-12" v-if="precio_variable">
                                <h6 class="text-success text-uppercase" v-if="seleccion.precio_rebajado!=''">{{seleccion.porcentaje}} % descuento</h6>
                                <h5 class="mb-4" v-if="seleccion.precio_rebajado!=''">
                                    Precio Web :
                                    <span class="text-muted mr-2">
                                        <del>${{ seleccion.precio_normal?seleccion.precio_normal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."):'' }} </del>
                                    </span>
                                    <b>${{ seleccion.precio_rebajado?seleccion.precio_rebajado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."):'' }} </b>
                                </h5>
                                <h5 class="mb-4" v-else>
                                    Precio Web :
                                    <b>${{ seleccion.precio_normal?seleccion.precio_normal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."):'' }}</b>
                                </h5>
                            </div>
                            <div class="col-md-10 mb-3" v-for="(atributo,index0) in producto.atributos" :key="index0">
                                <b-form-group id="example text" label-cols-sm="12" label-cols-lg="5" :label="atributo.nombre+' :'" label-size="lg" label-for="text" >
                                    <select class="form-control" v-model="orden[index0]" @change="variacion">
                                        <option value="">Seleccionar</option>
                                        <option :value="valor" v-for="(valor,index) in JSON.parse(atributo.valores_array)" :key="'valor'+atributo.id+index">{{ valor}}</option>
                                    </select>
                                </b-form-group>                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p class="p-0 m-0 pb-3" v-if="leyenda!=''"><b>Lleva {{producto.promo_prod.cant_regalar}} {{leyenda}} de regalo</b></p>
                            </div>
                            <div class="col-md-5" v-if="producto.estado_inventario=='hay'">
                                <b-input-group>
                                    <b-input-group-prepend>
                                        <b-button variant="outline-primary" @click="restar()">-</b-button>
                                    </b-input-group-prepend>
                                    <b-form-input class="text-center" v-model="item"></b-form-input>
                                    <b-input-group-append>
                                        <b-button variant="outline-primary" @click="sumar()">+</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                                <p class="p-0 m-0 pt-3 pb-3">{{producto.stock}} disponibles</p>
                                <div class="">
                                    <button type="button" class="btn btn-primary mr-1" @click="agregar_carro()" :disabled="item<=0">
                                        <i class="bx bx-cart mr-2"></i> Agregar al carro
                                    </button>
                                    
                                </div>
                                <p class="pt-3 pb-0 mb-0"><b class="text-primary">Nota:</b> Stock desfasado 20 minutos.</p>
                            </div>
                            <div class="col-md-4" v-else-if="producto.estado_inventario=='no hay'">
                                <p>Producto No disponible</p>
                            </div>
                            <div class="col-md-12 mt-3" v-else-if="producto.estado_inventario=='reservar'">
                                <h5 class="text-primary">Haz click en el siguiente botón para solicitar más información</h5>
                                <b-button variant="outline-primary" @click="comunicate()"><!--<i class="bx bx-envelope font-size-20"></i> -->Solicitar aquí</b-button>
                                <b-modal ref="modal-comunicate" title="Información de este producto" ok-title="Solicitar" cancel-title="Cancelar" @ok="enviar_mensaje(producto)">
                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Correo Electrónico:" label-for="text">
                                        <b-form-input for="text" value="" v-model="email"></b-form-input>
                                    </b-form-group>
                                    <b-form-textarea id="textarea" v-model="text" rows="3" max-rows="6" ></b-form-textarea>
                                </b-modal>
                                <!-- <a class="mt-3" href="mailto:elcorreoquequieres@correo.com?Subject=Aquí%20el%20asunto%20del%20mail"><i class="bx bx-envelope font-size-20"></i> Comunicate con nosotros para reservar este producto</a> -->
                            </div>
                            <div class="col-md-12 mt-3" v-if="producto.pdf !=null">
                                <div class="mt-4 p-2 border" >
                                    <h4 class="text-primary">Instrucciones de producto</h4>
                                    <a class="" :href="producto.pdf" target="_blank"><i class="far fa-file-pdf font-size-24"></i> Ver Instructivo</a>
                                    <!-- <pdf :src="producto.pdf" v-if="producto.pdf!=null">Ver Instructivo</pdf> -->
                                </div>
                            </div>
                        </div>
                        <div class="texto-con-saltos">
                            {{ producto.desccorta }}
                        </div>
                        <div class="texto-con-saltos">
                            {{ producto.desclarga }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div id="especific_container">
                <h5 class="mb-3">Especificaciones :</h5>
                <div class="table-responsive">
                    <table class="table mb-0 table-bordered">
                        <tbody>
                        <tr>
                            <th scope="row" style="width: 400px;">Presentación</th>
                            <td>125mg</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->
            <!-- <div id="product_container" class="row mt-3 mb-5" v-if="productos_dest.length>0">
                <div class="col-lg-12">
                    <ProductosDestacados :productos="productos_dest" :titulo="'Productos destacados :'"/>
                </div>
            </div> -->
        </div>
    </Layout>
</template>
<style scoped>
#cover_container {

position:relative;
margin: 3vw;
padding-top: 0;
margin-top: 0;

}
#cover_sticky{
position:sticky;
top:10vh;
width:50%;

}
#cover_text{
/*should be the same as the image height*/
    margin-top:-50vh;
    width:50%;
    height:fit-content;
    margin-left:50%;
}
#product_container {

margin: 3vw;

}
#especific_container {

margin: 3vw;

}
.cont{
    width: 550px;
    height: 550px;
}
.cont_img{
    object-fit: cover;
    width:100%;
    height:100%;
}
@media (max-width: 1520px) { 
    .cont{
        width: 400px;
        height:400px;
    }
    .cont_img{
        object-fit: cover;
        width:100%;
        height:100%;
    }
}
@media (max-width: 768px) {
    .cont{
        width: 200px;
        height:200px;
    }
    .cont_img{
        object-fit: cover;
        width:100%;
        height:100%;
    }
    #cover_text{
    /*should be the same as the image height*/
        margin-top:-20vh;
    }
}
@media (max-width: 576px){

}
.texto-con-saltos {
    white-space: pre-line;
}


</style>