<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";

/**
 * Starter component
 */
export default {
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                text: "Utility",
                href: "/"
                },
                {
                text: "Starter Page",
                active: true
                }
            ],
            categorias:[],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [
                { key: "nombre",label:"Nombre", sortable: true },
                { key: "acciones",label:"Acciones", sortable: true },
            ],
            categoria_data:{}
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            
            return this.categorias.length;
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        agregar_categoria(){
            axios.post('/api/guardar_categoria',this.categoria_data).then(res=>{
                this.categoria_data={};
                this.actualizar();
            })
        },
        eliminar(item){
            axios.post('/api/eliminar_categoria',item).then(res=>{
                this.actualizar();
            })
        },
        actualizar(){
            axios.get('/api/get_categorias').then(res=>{
                this.categorias = res.data.categorias;
            })
        }
    },
    created(){
        this.actualizar();
    }
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Categorías</h4>
                        <div class="row mt-4">
                            <div class="col-md-12 text-right mb-3">
                                <b-button variant="primary" size="sm" v-b-modal.modal-1>Agregar Categoría</b-button>
                                <b-modal id="modal-1" title="Agregar Categoría" ok-title="Agregar" cancel-title="Cancelar" @ok="agregar_categoria">
                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Nombre" label-for="text">
                                        <b-form-input for="text" placeholder="Escribe el título" v-model="categoria_data.nombre"></b-form-input>
                                    </b-form-group>
                                    <!-- <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Categoria padre" label-for="text" v-if="categorias.length>=1">
                                        <select class="form-control" v-model="categoria_data.parent_id">
                                            <option value="">Selecciona una categoria</option>
                                            <option :value="categoria.id" v-for="categoria in categorias" :key="categoria.id">{{categoria.nombre}}</option>
                                        </select>
                                    </b-form-group> -->
                                </b-modal>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Mostrar&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;categorias
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Buscar:
                                        <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Buscar..."
                                        class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                :items="categorias"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                            >
                                <template v-slot:cell(acciones)="data">
                                    <b-button size="sm" variant="danger" @click="eliminar(data.item)"><i class="bx bx-trash"></i></b-button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>