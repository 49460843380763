<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import moment from "moment";
import Swal from "sweetalert2";
/**
 * Starter component
 */
export default {
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                text: "Utility",
                href: "/"
                },
                {
                text: "Starter Page",
                active: true
                }
            ],
            slides:[],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [
                { key: "publicado",label:"Publicar", sortable: true },
                { key: "image",label:"Imagen", sortable: true },
                { key: "nombre",label:"Título", sortable: true },
                { key: "fecha",label:"Fecha", sortable: true },
                { key: "autor",label:"Autor", sortable: true },
                { key: "accion",label:"Accion", sortable: true }
            ],
            slide:{
                nombre:'',
                link:'',
                imagen:null,
                publicado:0
            },
            imagenminiatura:'',
            slidesizq:[],
            totalRowsizq: 1,
            currentPageizq: 1,
            perPageizq: 10,
            pageOptionsizq: [10, 25, 50, 100],
            filterizq: null,
            filterOnizq: [],
            sortByizq: "age",
            sortDescizq: false,
            fieldsizq: [
                { key: "publicado",label:"Publicar", sortable: true },
                { key: "image",label:"Imagen", sortable: true },
                { key: "fecha",label:"Fecha", sortable: true },
                { key: "autor",label:"Autor", sortable: true },
                { key: "accion",label:"Accion", sortable: true }
            ],
            slideizq:{
                nombre:'',
                link:'',
                imagen:null,
                publicado:0
            },
            imagenminiaturaizq:'',
            slidesder:[],
            totalRowsder: 1,
            currentPageder: 1,
            perPageder: 10,
            pageOptionsder: [10, 25, 50, 100],
            filterder: null,
            filterOnder: [],
            sortByder: "age",
            sortDescder: false,
            fieldsder: [
                { key: "publicado",label:"Publicar", sortable: true },
                { key: "image",label:"Imagen", sortable: true },
                { key: "fecha",label:"Fecha", sortable: true },
                { key: "autor",label:"Autor", sortable: true },
                { key: "accion",label:"Accion", sortable: true }
            ],
            slideder:{
                nombre:'',
                link:'',
                imagen:null,
                publicado:0
            },
            imagenminiaturader:'',
            error: null,
            errorizq: null,
            errorder: null,
            maxSizeInBytes: 2 * 1024 * 1024 // 2MB
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.slides.length;
        },
        imagen(){
            return this.imagenminiatura;
        },
        rowsizq() {
            return this.slidesizq.length;
        },
        imagenizq(){
            return this.imagenminiaturaizq;
        },
        rowsder() {
            return this.slidesder.length;
        },
        imagender(){
            return this.imagenminiaturader;
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
        this.totalRowsizq = this.items.length;
        this.totalRowsder = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        onFilteredizq(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsizq = filteredItems.length;
            this.currentPageizq = 1;
        },
        onFilteredder(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsder = filteredItems.length;
            this.currentPageder = 1;
        },
        guardar_slide(){
            let formData1 = new FormData();
            formData1.append('archivo', this.slide.archivo);
            formData1.append('nombre', this.slide.nombre);
            formData1.append('link', this.slide.link);
            formData1.append('publicado', this.slide.publicado);
            console.log(formData1);
            axios.post('/api/guardar_slide',formData1).then(res=>{
                if(res.data.estado){ 
                    //console.log(res.data.mensaje);
                    axios.get('/api/get_slide',this.slide).then(res=>{
                        this.slides=res.data.slides;
                    });
                }
                else{
                    console.log(res.data.mensaje);
                }
            });
        },
        guardar_slideizq(){
            let formData2 = new FormData();
            formData2.append('archivo', this.slideizq.archivo);
            formData2.append('nombre', this.slideizq.nombre);
            formData2.append('link', this.slideizq.link);
            formData2.append('publicado', this.slideizq.publicado);
            axios.post('/api/guardar_slideizq',formData2).then(res=>{
                if(res.data.estado){ 
                    console.log(res.data.mensaje);
                    axios.get('/api/get_slideizq',this.slideizq).then(res=>{
                        this.slidesizq=res.data.slides;
                    });
                }
                else{
                    console.log(res.data.mensaje);
                }
            });
        },
        guardar_slideder(){
            let formData3 = new FormData();
            formData3.append('archivo', this.slideder.archivo);
            formData3.append('nombre', this.slideder.nombre);
            formData3.append('link', this.slideder.link);
            formData3.append('publicado', this.slideder.publicado);
            axios.post('/api/guardar_slideder',formData3).then(res=>{
                if(res.data.estado){ 
                    console.log(res.data.mensaje);
                    axios.get('/api/get_slideder',this.slideder).then(res=>{
                        this.slidesder=res.data.slides;
                    });
                }
                else{
                    console.log(res.data.mensaje);
                }
            });
        },
        obtenerImagen(e){
            let file = e.target.files[0];
            if (file && file.size > this.maxSizeInBytes) {
                this.error = "El tamaño del archivo excede el límite permitido (2MB)";
                // Limpiar el campo de entrada de archivo
                //this.slide.imagen = null;
            } else {
                this.error = null;
            }
            this.slide.archivo = file;
            this.cargarImagen(file);
        },
        cargarImagen(file){
            let reader = new FileReader(); 
            reader.onload = (e) =>{
                this.imagenminiatura = e.target.result;
            }
            reader.readAsDataURL(file);
        },
        obtenerImagenizq(e){
            let file = e.target.files[0];
            if (file && file.size > this.maxSizeInBytes) {
                this.errorizq = "El tamaño del archivo excede el límite permitido (2MB)";
                // Limpiar el campo de entrada de archivo
                //this.slide.imagen = null;
            } else {
                this.errorizq = null;
            }
            this.slideizq.archivo = file;
            this.cargarImagenizq(file);
        },
        cargarImagenizq(file){
            let reader = new FileReader(); 
            reader.onload = (e) =>{
                this.imagenminiaturaizq = e.target.result;
            }
            reader.readAsDataURL(file);
        },
        obtenerImagender(e){
            let file = e.target.files[0];
            if (file && file.size > this.maxSizeInBytes) {
                this.errorder = "El tamaño del archivo excede el límite permitido (2MB)";
                // Limpiar el campo de entrada de archivo
                //this.slide.imagen = null;
            } else {
                this.errorder = null;
            }
            this.slideder.archivo = file;
            this.cargarImagender(file);
        },
        cargarImagender(file){
            let reader = new FileReader(); 
            reader.onload = (e) =>{
                this.imagenminiaturader = e.target.result;
            }
            reader.readAsDataURL(file);
        },
        fecha(fechahora){
            var orden = moment(fechahora).format('DD-MM-YYYY');
            return orden
        },
        publicar(id,valor){
            let formData4 = new FormData();
            formData4.append('slide_id', id);
            formData4.append('valor', valor);
            axios.post('/api/publicar',formData4).then(res=>{
                if(res.data.estado){ 
                    console.log(res.data.mensaje);
                }
            });
        },
        publicarizq(id,valor){
            let formData5 = new FormData();
            formData5.append('slide_id', id);
            formData5.append('valor', valor);
            axios.post('/api/publicarizq',formData5).then(res=>{
                if(res.data.estado){ 
                    console.log(res.data.mensaje);
                }
            });
            axios.get('/api/get_slideizq').then(res=>{
                this.slidesizq=res.data.slides;
            });
        },
        publicarder(id,valor){
            let formData6 = new FormData();
            formData6.append('slide_id', id);
            formData6.append('valor', valor);
            axios.post('/api/publicarder',formData6).then(res=>{
                if(res.data.estado){ 
                    console.log(res.data.mensaje);
                }
            });
            axios.get('/api/get_slideder').then(res=>{
                this.slidesder=res.data.slides;
            });
        },
        eliminar(data){
            axios.get('/api/eliminar_slider/'+data.id).then(res=>{
                this.slidesder=res.data.slidesder;
                this.slidesizq=res.data.slidesizq;
                this.slides=res.data.slides;

            });
        },
        msg(data){
            Swal.fire({
                //position: "top-end",
                icon: "warning",
                title: "Falta campo por completar",
                text: data,
                showConfirmButton: false,
                timer: 1500
            });
        },
        validarppal(bvModalEvent){
            if (this.slide.nombre=='' || this.slide.nombre==null) {
                bvModalEvent.preventDefault()
                this.msg('Titulo')
                return
            }
            if(this.slide.link.length>0){
                if(!this.slide.link.toLowerCase().startsWith('www.')){
                    Swal.fire({
                        //position: "top-end",
                        icon: "warning",
                        title: "Link debe comenzar con www.",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    bvModalEvent.preventDefault()
                    return
                }
            }
            if(this.slide.imagen=='' || this.slide.imagen==null){
                bvModalEvent.preventDefault()
                this.msg('Imagen')
                return
            }
            this.guardar_slide();
        },
        validarizq(bvModalEvent){
            if (this.slideizq.nombre=='' || this.slideizq.nombre==null) {
                bvModalEvent.preventDefault()
                this.msg('Titulo')
                return
            }
            if(this.slideizq.link.length>0){
                if(!this.slideizq.link.toLowerCase().startsWith('www.')){
                    Swal.fire({
                        //position: "top-end",
                        icon: "warning",
                        title: "Link debe comenzar con www.",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    bvModalEvent.preventDefault()
                    return
                }
            }
            if(this.slideizq.imagen=='' || this.slideizq.imagen==null){
                bvModalEvent.preventDefault()
                this.msg('Imagen')
                return
            }
            this.guardar_slideizq();
        },
        validarder(bvModalEvent){
            if (this.slideder.nombre=='' || this.slideder.nombre==null) {
                bvModalEvent.preventDefault()
                this.msg('Titulo')
                return
            }
            if(this.slideder.link.length>0){
                if(!this.slideder.link.toLowerCase().startsWith('www.')){
                    Swal.fire({
                        //position: "top-end",
                        icon: "warning",
                        title: "Link debe comenzar con www.",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    bvModalEvent.preventDefault()
                    return
                }
            }
            if(this.slideder.imagen=='' || this.slideder.imagen==null){
                bvModalEvent.preventDefault()
                this.msg('Imagen')
                return
            }
            this.guardar_slideder();
        },
        validacionlink(link){
            if(link.length>0){
                if(!link.toLowerCase().startsWith('www.')){
                    Swal.fire({
                        //position: "top-end",
                        icon: "warning",
                        title: "Link debe comenzar con www.",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            }
        }
    },
    created(){
        axios.get('/api/get_slide').then(res=>{
            this.slides=res.data.slides;
        });
        axios.get('/api/get_slideizq').then(res=>{
            this.slidesizq=res.data.slides;
        });
        axios.get('/api/get_slideder').then(res=>{
            this.slidesder=res.data.slides;
        });
    }
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Slides</h4>
                        <div class="row mt-4">
                            <h5 class="col-md-6 card-title">Banner principal</h5>
                            <div class="col-md-6 text-right mb-3">
                                <b-button variant="primary" size="sm" v-b-modal.modal-1>Cargar slide</b-button>
                                <b-modal id="modal-1" title="Cargar Slide" ok-title="Agregar" cancel-title="Cancelar" @ok="validarppal">
                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Título" label-for="text">
                                        <b-form-input for="text" placeholder="Escribe el título" v-model="slide.nombre"></b-form-input>
                                    </b-form-group>
                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Link" label-for="text">
                                        <b-form-input for="text" placeholder="Agregar link" v-model="slide.link" @blur="validacionlink(slide.link)"></b-form-input>
                                    </b-form-group>
                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Imagen" label-for="text">
                                        <b-form-file
                                        v-model="slide.imagen"
                                        accept="image/*"
                                        placeholder="Elegir Archivo"
                                        drop-placeholder="Drop file here..."
                                        @change="obtenerImagen"
                                        ></b-form-file>
                                        <p>Máximo 2mb</p>
                                        <p class="text-danger">{{error}}</p>
                                    </b-form-group>
                                    
                                    <b-form-group id="example text" label-for="text">
                                        <b-form-checkbox id="checkbox-1" v-model="slide.publicado" name="checkbox-1" :value="1" :unchecked-value="0"> Publicado </b-form-checkbox>
                                    </b-form-group>
                                    <div v-if="slide.imagen!=null">
                                        <p>Previsualización de imagen</p>
                                        <div class="text-center" >
                                            <figure>
                                                <img :src="imagen" height="200" alt="Imagen del Taller">
                                            </figure>
                                        </div>
                                    </div>
                                </b-modal>
                            </div>
                            
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Mostrar&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;slides
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Buscar:
                                        <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Buscar..."
                                        class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                :items="slides"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                            >
                                <template v-slot:cell(publicado)="data">
                                    <b-form-group id="example text" label-for="text">
                                        <b-form-checkbox :id="'publicado'+data.item.id" v-model="data.item.publicado" name="checkbox-1" :value="1" :unchecked-value="0" @change="publicar(data.item.id, data.item.publicado)">  </b-form-checkbox>
                                    </b-form-group>
                                </template>
                                <template v-slot:cell(image)="data">
                                    <img
                                        :src="data.item.image"
                                        alt
                                        class="avatar-lg"
                                    />
                                </template>
                                <template v-slot:cell(fecha)="data">
                                    <div>
                                        <p> <input type="text" :value="fecha(data.item.fecha_publicacion)" style="border: 0;outline: none; background-color:#ffffff;" disabled></p>
                                    </div>
                                </template>
                                <template v-slot:cell(autor)="data">
                                    <p>{{ data.item.name }}</p>
                                </template>
                                <template v-slot:cell(accion)="data">
                                    <b-button variant="danger" size="sm" @click="eliminar(data.item)"><i class="bx bx-trash"></i></b-button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="row mt-4">
                    <h5 class="col-md-6 card-title">Imagen Izquierda</h5>
                    <div class="col-md-6 text-right mb-3">
                        <b-button variant="primary" size="sm" v-b-modal.modal-2>Cargar slide</b-button>
                        <b-modal id="modal-2" title="Cargar Slide" ok-title="Agregar" cancel-title="Cancelar" @ok="validarizq">
                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Título" label-for="text">
                                <b-form-input for="text" placeholder="Escribe el título" v-model="slideizq.nombre"></b-form-input>
                            </b-form-group>
                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Link" label-for="text">
                                <b-form-input for="text" placeholder="Agregar link" v-model="slideizq.link" @blur="validacionlink(slideizq.link)"></b-form-input>
                            </b-form-group>
                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Imagen" label-for="text">
                                <b-form-file
                                v-model="slideizq.imagen"
                                accept="image/*"
                                placeholder="Elegir Archivo"
                                drop-placeholder="Drop file here..."
                                @change="obtenerImagenizq"
                                ></b-form-file>
                                <p>Máximo 2mb</p>
                                <p class="text-danger">{{error}}</p>
                            </b-form-group>
                            <b-form-group id="example text" label-for="text">
                                <b-form-checkbox id="checkboxizq-1" v-model="slideizq.publicado" name="checkboxizq-1" :value="1" :unchecked-value="0" > Publicado </b-form-checkbox>
                            </b-form-group>
                            <div v-if="slideizq.imagen!=null">
                                <p>Previsualización de imagen</p>
                                <div class="text-center" >
                                <figure>
                                    <img :src="imagenizq" height="200" alt="Imagen del Taller">
                                </figure>
                            </div>
                            </div>
                        </b-modal>
                    </div>
                    
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                            <label class="d-inline-flex align-items-center">
                                Mostrar&nbsp;
                                <b-form-select v-model="perPageizq" size="sm" :options="pageOptionsizq"></b-form-select>&nbsp;slides
                            </label>
                        </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                            <label class="d-inline-flex align-items-center">
                                Buscar:
                                <b-form-input
                                v-model="filterizq"
                                type="search"
                                placeholder="Buscar..."
                                class="form-control form-control-sm ml-2"
                                ></b-form-input>
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                    <b-table
                        :items="slidesizq"
                        :fields="fieldsizq"
                        responsive="sm"
                        :per-page="perPageizq"
                        :current-page="currentPageizq"
                        :sort-by.sync="sortByizq"
                        :sort-desc.sync="sortDescizq"
                        :filter="filterizq"
                        :filter-included-fields="filterOnizq"
                        @filtered="onFilteredizq"
                    >
                        <template v-slot:cell(publicado)="data">
                            <b-form-group id="example text" label-for="text">
                                <b-form-checkbox :id="'publicadoizq'+data.item.id" v-model="data.item.publicado" :name="'publicadoizq'+data.item.id" :value="1" :unchecked-value="0" @change="publicarizq(data.item.id, data.item.publicado)"> </b-form-checkbox>
                            </b-form-group>
                        </template>
                        <template v-slot:cell(image)="data">
                            <img
                                :src="data.item.image"
                                alt
                                class="avatar-lg"
                            />
                        </template>
                        <template v-slot:cell(fecha)="data">
                            <div>
                            <p> <input type="text" :value="fecha(data.item.fecha_publicacion)" style="border: 0;outline: none; background-color:#ffffff;" disabled></p>
                        </div>
                        </template>
                        <template v-slot:cell(autor)="data">
                            <p>{{ data.item.name }}</p>
                        </template>
                        <template v-slot:cell(accion)="data">
                            <b-button variant="danger" size="sm" @click="eliminar(data.item)"><i class="bx bx-trash"></i></b-button>
                        </template>
                    </b-table>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                            <ul class="pagination pagination-rounded mb-0">
                                <!-- pagination -->
                                <b-pagination v-model="currentPageizq" :total-rows="rowsizq" :per-page="perPageizq"></b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row mt-4">
                    <h5 class="col-md-6 card-title">Imagen derecha</h5>
                    <div class="col-md-6 text-right mb-3">
                        <b-button variant="primary" size="sm" v-b-modal.modal-3>Cargar slide</b-button>
                        <b-modal id="modal-3" title="Cargar Slide" ok-title="Agregar" cancel-title="Cancelar" @ok="validarder">
                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Título" label-for="text">
                                <b-form-input for="text" placeholder="Escribe el título" v-model="slideder.nombre"></b-form-input>
                            </b-form-group>
                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Link" label-for="text">
                                <b-form-input for="text" placeholder="Agregar link" v-model="slideder.link" @blur="validacionlink(slideder.link)"></b-form-input>
                            </b-form-group>
                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Imagen" label-for="text">
                                <b-form-file
                                v-model="slideder.imagen"
                                accept="image/*"
                                placeholder="Elegir Archivo"
                                drop-placeholder="Drop file here..."
                                @change="obtenerImagender"
                                ></b-form-file>
                                <p>Máximo 2mb</p>
                                <p class="text-danger">{{error}}</p>
                            </b-form-group>
                            
                            <b-form-group id="example text" label-for="text">
                                <b-form-checkbox id="checkboxder-1" v-model="slideder.publicado" name="checkboxder-1" :value="1" :unchecked-value="0" > Publicado </b-form-checkbox>
                            </b-form-group>
                            <div v-if="slideder.imagen!=null">
                                <p>Previsualización de imagen</p>
                                <div class="text-center" >
                                <figure>
                                    <img :src="imagender" height="200" alt="Imagen del Taller">
                                </figure>
                            </div>
                            </div>
                        </b-modal>
                    </div>
                    
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                            <label class="d-inline-flex align-items-center">
                                Mostrar&nbsp;
                                <b-form-select v-model="perPageder" size="sm" :options="pageOptionsder"></b-form-select>&nbsp;slides
                            </label>
                        </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                            <label class="d-inline-flex align-items-center">
                                Buscar:
                                <b-form-input
                                v-model="filterder"
                                type="search"
                                placeholder="Buscar..."
                                class="form-control form-control-sm ml-2"
                                ></b-form-input>
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                    <b-table
                        :items="slidesder"
                        :fields="fieldsder"
                        responsive="sm"
                        :per-page="perPageder"
                        :current-page="currentPageder"
                        :sort-by.sync="sortByder"
                        :sort-desc.sync="sortDescder"
                        :filter="filterder"
                        :filter-included-fields="filterOnder"
                        @filtered="onFilteredder"
                    >
                        <template v-slot:cell(publicado)="data">
                            <b-form-group id="example text" label-for="text">
                                <b-form-checkbox :id="'publicadoder'+data.item.id" v-model="data.item.publicado" name="checkbox-1" :value="1" :unchecked-value="0" @change="publicarder(data.item.id, data.item.publicado)"> </b-form-checkbox>
                            </b-form-group>
                        </template>
                        <template v-slot:cell(image)="data">
                            <img
                                :src="data.item.image"
                                alt
                                class="avatar-lg"
                            />
                        </template>
                        <template v-slot:cell(fecha)="data">
                            <div>
                            <p> <input type="text" :value="fecha(data.item.fecha_publicacion)" style="border: 0;outline: none; background-color:#ffffff;" disabled></p>
                        </div>
                        </template>
                        <template v-slot:cell(autor)="data">
                            <p>{{ data.item.name }}</p>
                        </template>
                        <template v-slot:cell(accion)="data">
                            <b-button variant="danger" size="sm" @click="eliminar(data.item)"><i class="bx bx-trash"></i></b-button>
                        </template>
                    </b-table>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                            <ul class="pagination pagination-rounded mb-0">
                                <!-- pagination -->
                                <b-pagination v-model="currentPageder" :total-rows="rowsder" :per-page="perPageder"></b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>