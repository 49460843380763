<script>
import Layout from "./base";
import Carousel from "../componentes/carousel";
import Productos from "../componentes/productos";
import { sampleSize } from 'lodash';
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
/**
 * Starter component
 */
export default {
    components: { 
        Layout, 
        Carousel, 
        Productos, 
        Hooper,
        Slide
    },
    props:{
        submiturl: {
        type: String,
        required: true
        },
        autherror: {
        type: String,
        required: false,
        default: () => null
        },
        autenticado:''
    },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                text: "Utility",
                href: "/"
                },
                {
                text: "Starter Page",
                active: true
                }
            ],
            promociones:[],
            slides:[],
            slidesizq:[],
            slidesder:[],
            ofertas:[],
            windowWidth2: window.innerWidth,
            windowHeight2: window.innerHeight
        };
    },
    computed:{
        style_slide(){
            if (this.slides.lenght>1) {
                return 'width:50%;height:auto;'
            }
            else{
                return 'width:100%;height:auto;'
            }
        }
    },
    created(){
        // axios.get('/api/get_slide_publicadas').then(res=>{
        //     this.slides=res.data.slides;
        //     console.log(this.slides);
        // });
        // axios.get('/api/get_slide_publicadasizq').then(res=>{
        //     this.slidesizq=res.data.slides;
        // });
        // axios.get('/api/get_slide_publicadasder').then(res=>{
        //     this.slidesder=res.data.slides;
        // });
        // axios.get('/api/get_productos_web_destacados').then(res=>{
        //     // this.promociones=res.data.productos;
        //     this.promociones=sampleSize(res.data.productos, 12);
            
        // });
        // axios.get('/api/get_productos_web_oferta').then(res=>{
        //     this.ofertas=res.data.productos;
        //     //console.log(this.promociones);
        // });
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    }, 
    async mounted() {
    try {
        const [slidesResponse, slidesIzqResponse, slidesDerResponse, productosResponse] = await Promise.all([
            axios.get('/api/get_slide_publicadas'),
            axios.get('/api/get_slide_publicadasizq'),
            axios.get('/api/get_slide_publicadasder'),
            axios.get('/api/get_productos_web_destacados')
        ]);

        this.slides = slidesResponse.data.slides;
        this.slidesizq = slidesIzqResponse.data.slides;
        this.slidesder = slidesDerResponse.data.slides;
        this.promociones = sampleSize(productosResponse.data.productos, 12);
    } catch (error) {
        console.error('Error al obtener datos:', error);
    }
    
},
    methods:{
        handleResize() {
            this.windowWidth2 = window.innerWidth;
            this.windowHeight2 = window.innerHeight;
            console.log(this.windowWidth2);
            console.log(this.windowHeight);
        },
    }
};
</script>
<template>
    <Layout :submiturl='submiturl' :autherror="autherror" :autenticado="autenticado">
        <div :class="windowWidth2<576 ? 'row mt-10':'row'" >
            <Carousel class="pb-5" :slides="slides" v-if="slides.length>0"/>
        </div>
        <div class="row">
            <div class="col-md-6 cont mb-3" v-if="slidesizq!=null">
                <a :href="'https://'+slidesizq.link" target="_blank" rel="noopener noreferrer" v-if="slidesizq.link!=null">
                    <img :src="slidesizq.image" alt class="cont_img" />
                </a>
                <img :src="slidesizq.image" alt class="cont_img" v-else/>
            </div>
            <div class="col-md-6 cont mb-3" v-if="slidesder!=null">
                <a :href="'https://'+slidesder.link" target="_blank" rel="noopener noreferrer" v-if="slidesder.link!=null">
                    <img :src="slidesder.image" alt class="cont_img"  />
                </a>
                <img :src="slidesder.image" alt class="cont_img" v-else/>
            </div>
        </div>
        <div class="row" v-if="promociones.length>0">
            <Productos :titulo="'PRODUCTOS RECOMENDADOS'" :productos="promociones"/>
        </div>
        <!-- <div class="row" v-if="ofertas.length>0">
            <Productos :titulo="'OFERTAS'" :productos="ofertas"/>
        </div> -->
    </Layout>
</template>

<style>
.cont{
    width: 300px;
    height: auto;
}
.cont_img{
    object-fit: cover;
    width:100%;
    height:100%;
}
@media (max-width: 992px) {
    .cont{
        width: 100%;
        height: 100%;
    }
    .cont_img{
        object-fit: cover;
        width:100%;
        height:100%;
    }
}
</style>