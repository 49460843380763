<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/es';
import Swal from "sweetalert2";
/**
 * Starter component
 */
export default {
    components: { Layout, PageHeader, DatePicker },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                    text: "Utility",
                    href: "/"
                },
                {
                    text: "Starter Page",
                    active: true
                }
            ],
            promociones:[],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [
                { key: "vigencia", label:'Vigencia',sortable: true },
                { key: "tipo", label:'Tipo', sortable: true },
                { key: "ambito", label:'Ámbito', sortable: true },
                { key: "detalle", label:'Detalle', sortable: true },
                { key: "accion", label:'Acción', sortable: true }
            ],
            promocion:{
                vigencia:'',
                tipo:'',
                ambito:'',
                detalle_ambito:'',
                porc_descuento:'',
                cantidad:'',
                unidades:'',
                cant_regalar:'',
                unidad_regalar:'',
                producto_regalar:'',
                producto:''
            },
            productos:[],
            marcas:[],
            categorias:[],
            ver:{}
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
        return this.promociones.length;
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
        },
        notBeforeToday(date) {
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        labelselect_cat ({ nombre }) {
            return `${nombre}`
        },
        msg(data){
            Swal.fire({
                //position: "top-end",
                icon: "error",
                title: "Falta campo por completar",
                text: data,
                showConfirmButton: false,
                timer: 1500
            });
        },
        limpiar(){
            this.promocion.ambito='';
            this.promocion.detalle_ambito='';
            this.promocion.porc_descuento='';
            this.promocion.cantidad='';
            this.promocion.unidades='';
            this.promocion.cant_regalar='';
            this.promocion.unidad_regalar='';
            this.promocion.producto_regalar='';
            this.promocion.producto=''; 
        },
        guardar(){
            if (this.promocion.vigencia=='') {
                this.msg('Vigencia')
                return
            }
            if (this.promocion.tipo=='') {
                this.msg('Tipo de promoción')
                return
            }
            if (this.promocion.ambito=='') {
                this.msg('Ámbito de promoción')
                return
            }
            if (this.promocion.tipo=='volumenventa') {
                if(this.promocion.porc_descuento==''){
                    this.msg('Descuento')
                    return
                }
                if(this.promocion.cantidad==''){
                    this.msg('Cantidad')
                    return
                }
            }
            if(this.promocion.tipo=='volumenventa' && this.promocion.porc_descuento>100){
                Swal.fire({
                    //position: "top-end",
                    icon: "error",
                    title: "Descuento no puede ser mayor que 100",
                    //text: data,
                    showConfirmButton: false,
                    timer: 1500
                });
                return
            }
            if(this.promocion.tipo=='volumenventa' && this.promocion.porc_descuento<0){
                Swal.fire({
                    //position: "top-end",
                    icon: "error",
                    title: "Descuento no puede ser menor que 0",
                    //text: data,
                    showConfirmButton: false,
                    timer: 1500
                });
                return
            }
            if (this.promocion.tipo=='comprexllevey') {
                if(this.promocion.cantidad==''){
                    this.msg('Cantidad')
                    return
                }
                if(this.promocion.cant_regalar==''){
                    this.msg('Cantidad Regalar')
                    return
                }
                if(this.promocion.producto_regalar==''){
                    this.msg('Producto Regalar')
                    return
                }
            }
            let formData = new FormData();
            formData.append('vigencia', this.promocion.vigencia);
            formData.append('tipo', this.promocion.tipo);
            formData.append('ambito', this.promocion.ambito);
            formData.append('detalle_ambito', this.promocion.detalle_ambito.id);
            formData.append('porc_descuento', this.promocion.porc_descuento ? this.promocion.porc_descuento:null);
            formData.append('cantidad', this.promocion.cantidad);
            formData.append('unidades', this.promocion.unidades);
            formData.append('cant_regalar', this.promocion.cant_regalar);
            formData.append('unidad_regalar', this.promocion.unidad_regalar);
            formData.append('producto_regalar', this.promocion.producto_regalar? this.promocion.producto_regalar.id:null );
            axios.post('/api/guardar_promocion',formData).then(res=>{
                if (res.data.estado) {
                    Swal.fire({
                        //position: "top-end",
                        icon: "success",
                        title: "Datos guardados",
                        //text: data,
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                this.promocion.vigencia='';
                this.promocion.tipo='';
                this.limpiar();    
                this.actualizar();
            })
        },
        eliminar(item){
            axios.post('/api/eliminar_promocion',item).then(res=>{
                if (res.data.estado) {
                    Swal.fire({
                        //position: "top-end",
                        icon: "success",
                        title: "Eliminado",
                        //text: data,
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                this.actualizar();
            })
        },
        detalles(item){
            this.ver = item;
            if (this.ver.ambito=='producto') {
                this.ver.ver_producto = this.productos.filter(producto=>
                    producto.id==this.ver.detalle_ambito
                );
                this.ver.producto_nombre = this.ver.ver_producto[0].nombre
            }
            if (this.ver.ambito=='marca') {
                this.ver.ver_producto = this.marcas.filter(producto=>
                    producto.id==this.ver.detalle_ambito
                );
                this.ver.producto_nombre = this.ver.ver_producto[0].nombre
            }
            if (this.ver.ambito=='categorias') {
                this.ver.ver_producto = this.categorias.filter(producto=>
                    producto.id==this.ver.detalle_ambito
                );
                this.ver.producto_nombre = this.ver.ver_producto[0].nombre
            }
            if (this.ver.tipo=='comprexllevey') {
                this.ver.ver_producto_regalar = this.productos.filter(producto=>
                producto.id==this.ver.producto_regalar
            );
            this.ver.producto_regalar_nombre = this.ver.ver_producto_regalar[0].nombre
            }
            //console.log(this.ver.ver_producto[0].nombre);
            this.$refs['my-modal'].show()
        },
        actualizar(){
            axios.get('/api/get_promociones').then(res=>{
                this.promociones = res.data.promociones;
            })
        },
        prod(){
            this.promocion.producto = this.promocion.detalleambito.name
        },
        nombre(ambito){
            if (ambito) {
                let no = ambito.charAt(0).toUpperCase()
                let n = ambito.slice(1)
                let nombre = no + n
                return nombre
            }
        }
    },
    created(){
        this.actualizar();
        axios.get('/api/get_marcas_producpubl').then(res=>{
            this.marcas = res.data.marcas;
            //console.table(this.productos);
        })
        axios.get('/api/get_categorias_productos').then(res=>{
            this.categorias = res.data.categorias;
            //console.table(this.productos);
        })
        axios.get('/api/get_productos_publicados').then(res=>{
            this.productos = res.data.productos;
            //console.table(this.productos);
        })
    }
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Promociones</h4>
                        <div class="row mt-4">
                            <div class="col-md-12 mb-3">
                                <b-button class="float-right" variant="primary" v-b-modal.modal-1>Agregar Promoción</b-button>
                                <b-modal id="modal-1" title="Agregar Promoción" size="lg" ok-title="Agregar" cancel-title="Cancelar" @ok="guardar">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group id="example text" label="Vigencia:" label-for="text">
                                                <date-picker v-model="promocion.vigencia" type="date" range placeholder="Seleccionar rango de fecha" :disabled-date="notBeforeToday" value-type="format" format="DD-MM-YYYY" :first-day-of-week="2" lang="es"></date-picker>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group id="example text" label="Tipo de promoción:" label-for="text">
                                                <b-form-radio-group id="radio-group-2" v-model="promocion.tipo" name="some-radios" @change="limpiar">
                                                    <b-form-radio  value="volumenventa">Por Volumen de venta <i class="bx bx-help-circle" v-b-popover.hover.right="'Por ejemplo por 10 camisas obtenga un % de descuento'" title="Por volumen de venta"></i> </b-form-radio>
                                                    <b-form-radio  value="comprexllevey">Compre X y lleve Y <i class="bx bx-help-circle" v-b-popover.hover.right="'Por ejemplo por 10 camisas lleve una corbata gratis'" title="Compre X y lleve Y"></i></b-form-radio>
                                                </b-form-radio-group>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12" v-if="promocion.tipo!=''">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <b-form-group id="example text" label="Ambito de la promoción:" label-for="text">
                                                        <b-form-radio v-model="promocion.ambito" name="some-radios1" value="producto">Producto</b-form-radio>
                                                        <b-form-radio v-model="promocion.ambito" name="some-radios1" value="marca" v-if="promocion.tipo =='volumenventa'">Marca</b-form-radio>
                                                        <b-form-radio v-model="promocion.ambito" name="some-radios1" value="categoria" v-if="promocion.tipo =='volumenventa'">Categoría</b-form-radio>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-6 pt-4">
                                                    <multiselect
                                                        v-model="promocion.detalle_ambito"
                                                        :options="productos"
                                                        :custom-label="labelselect_cat" 
                                                        placeholder="Seleccionar producto" 
                                                        selectLabel="Presiona para seleccionar"
                                                        selectedLabel="Seleccionado"
                                                        deselectLabel="Presiona para remover"
                                                        track-by="nombre"
                                                        v-if="promocion.ambito =='producto' "
                                                        @change="prod"
                                                        >
                                                        <template v-slot:noOptions>
                                                            <p>No hay opciones</p>
                                                        </template>
                                                        <template v-slot:noResult>
                                                            <p>No se encuentra coincidencia</p>
                                                        </template>
                                                    </multiselect>
                                                    <multiselect
                                                        v-model="promocion.detalle_ambito"
                                                        :options="marcas"
                                                        :custom-label="labelselect_cat" 
                                                        placeholder="Seleccionar marca" 
                                                        selectLabel="Presiona para seleccionar"
                                                        selectedLabel="Seleccionado"
                                                        deselectLabel="Presiona para remover"
                                                        track-by="nombre"
                                                        v-if="promocion.ambito =='marca' "
                                                        @change="prod"
                                                        >
                                                        <template v-slot:noOptions>
                                                            <p>No hay opciones</p>
                                                        </template>
                                                        <template v-slot:noResult>
                                                            <p>No se encuentra coincidencia</p>
                                                        </template>
                                                    </multiselect>
                                                    <multiselect
                                                        v-model="promocion.detalle_ambito"
                                                        :options="categorias"
                                                        :custom-label="labelselect_cat" 
                                                        placeholder="Seleccionar categorias" 
                                                        selectLabel="Presiona para seleccionar"
                                                        selectedLabel="Seleccionado"
                                                        deselectLabel="Presiona para remover"
                                                        track-by="nombre"
                                                        v-if="promocion.ambito =='categoria' "
                                                        @change="prod"
                                                        >
                                                        <template v-slot:noOptions>
                                                            <p>No hay opciones</p>
                                                        </template>
                                                        <template v-slot:noResult>
                                                            <p>No se encuentra coincidencia</p>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="col-md-12" v-if="promocion.tipo=='volumenventa'">
                                            <h5>Promoción: Por volumen de venta</h5>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <b-form-group id="example text" label="% descuento :" label-for="text">
                                                        <b-form-input for="text" type="number" max="100" min="1" placeholder="Indique porcentaje" v-model="promocion.porc_descuento"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-6">
                                                    <b-form-group id="example text" label="Por la compra de :" label-for="text">
                                                        <b-form-input for="text" type="number" placeholder="Indique cantidad" min="1" v-model="promocion.cantidad"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                                <!-- <div class="col-md-4">
                                                    <b-form-group id="example text" label="Unidades :" label-for="text">
                                                        <b-form-input for="text" type="number" placeholder="Indique unidad" v-model="promocion.unidades"></b-form-input>
                                                    </b-form-group>
                                                </div> -->
                                                <div class="col-md-12">
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="De :" label-for="text">
                                                        <p class="p-2">{{ promocion.detalle_ambito.nombre }}</p><!-- <b-form-input for="text" placeholder="Indique producto" v-model="promocion.producto"></b-form-input> -->
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12" v-else-if="promocion.tipo=='comprexllevey'">
                                            <h5>Promoción: Compre X y lleve Y</h5>
                                            <label for="text">Por la compra de :</label>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <b-form-input for="text" type="number" placeholder="Indique cantidad" v-model="promocion.cantidad"></b-form-input>
                                                </div>
                                                <!-- <div class="col-md-4">
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="4" label="Unidades :" label-for="text">
                                                        <b-form-input for="text" type="number" placeholder="Indique unidad" v-model="promocion.unidades"></b-form-input>
                                                    </b-form-group>
                                                </div> -->
                                                <div class="col-md-8">
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="4" label="Del producto :" label-for="text">
                                                        <p class="p-2">{{ promocion.detalle_ambito.nombre }}</p><!-- <b-form-input for="text" placeholder="Indique producto" v-model="promocion.producto"></b-form-input> -->
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <label for="text">Regalar :</label>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <b-form-input for="text" type="number" placeholder="Indique cantidad" v-model="promocion.cant_regalar"></b-form-input>
                                                </div>
                                                <!-- <div class="col-md-4">
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="4" label="Unidades :" label-for="text">
                                                        <b-form-input for="text" type="number" placeholder="Indique unidad" v-model="promocion.unidad_regalar"></b-form-input>
                                                    </b-form-group>
                                                </div> -->
                                                <div class="col-md-8">
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="4" label="Del producto :" label-for="text">
                                                        <multiselect
                                                        v-model="promocion.producto_regalar"
                                                        :options="productos"
                                                        :custom-label="labelselect_cat" 
                                                        placeholder="Seleccionar producto" 
                                                        selectLabel="Presiona para seleccionar"
                                                        selectedLabel="Seleccionado"
                                                        deselectLabel="Presiona para remover"
                                                        track-by="nombre">
                                                        <template v-slot:noOptions>
                                                            <p>No hay opciones</p>
                                                        </template>
                                                        <template v-slot:noResult>
                                                            <p>No se encuentra coincidencia</p>
                                                        </template>
                                                    </multiselect>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-modal>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Mostrar&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;promociones
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Buscar:
                                        <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Buscar..."
                                        class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                :items="promociones"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                            >
                                <template v-slot:cell(vigencia)="data">
                                    {{data.item.vigencia}}
                                </template>
                                <template v-slot:cell(tipo)="data">
                                    <p v-if="data.item.tipo=='volumenventa'">Por volumen de venta</p>
                                    <p v-if="data.item.tipo=='comprexllevey'">Compre X y lleve Y</p>
                                </template>
                                <template v-slot:cell(ambito)="data">
                                    {{nombre(data.item.ambito)}}
                                </template>
                                <template v-slot:cell(detalle)="data">
                                    <b-button size="sm" variant="primary" @click="detalles(data.item)">Ver Detalles</b-button>
                                </template>
                                <template v-slot:cell(accion)="data">
                                    <b-button size="sm" variant="danger" @click="eliminar(data.item)"><i class="bx bx-trash"></i></b-button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <b-modal ref="my-modal" hide-footer title="Detalles Promoción">
                            <div class="row">
                                <div class="col-md-12">
                                    <p><b>Vigencia :</b> {{ this.ver.vigencia }}</p>
                                    <p v-if="ver.tipo=='volumenventa'"><b>Tipo de promoción: </b>Por volumen de venta</p>
                                    <p v-if="ver.tipo=='comprexllevey'"><b>Tipo de promoción: </b>Compre X y lleve Y</p>
                                    <p><b>Ámbito :</b>{{ nombre(this.ver.ambito) }}</p>
                                    <p><b>{{ nombre(this.ver.ambito) }} :</b>{{ this.ver.producto_nombre }}</p>
                                    <div v-if="ver.tipo=='volumenventa'">
                                        <p>Descuento del <b>{{ this.ver.porc_descuento }}%</b> Por la compra de <b>{{ this.ver.cantidad }}</b> de <b>{{ this.ver.producto_nombre }}</b></p>
                                    </div>
                                    <div v-if="ver.tipo=='comprexllevey'">
                                        <p>Por la compra de <b>{{ this.ver.cantidad }}</b> <b>{{ this.ver.producto_nombre }}</b></p>
                                        <p>Se regala <b>{{ this.ver.cant_regalar }}</b> de <b>{{this.ver.producto_regalar_nombre}}</b></p>
                                    </div>
                                </div>
                            </div>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>