<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import VueHtml2pdf from 'vue-html2pdf';
import Swal from "sweetalert2";
import VueResponsiveImage from 'vue-responsive-image'
import { WidthType, BorderStyle, Document, Paragraph, Packer, TextRun,Header,HeadingLevel,Table, TableCell, TableRow,ImageRun  } 
from "docx";
const FileSaver = require("file-saver");
var fs = require('fs');
import { readFileSync } from 'fs';
import {jsPDF} from 'jspdf'; 


export default {
  
    components: { Layout, PageHeader ,VueHtml2pdf,Swal,VueResponsiveImage,Document, Paragraph, Packer, TextRun,Header,ImageRun, saveAs, BorderStyle, WidthType,HeadingLevel,Table, TableCell, TableRow, jsPDF,fs,readFileSync},
    data() {
        return {
            title: "Licitaciones",
            items: [
            {
                text: "Tables",
                href: "/"
            },
            {
                text: "Advanced",
                active: true
            }
        ],
            isLoading:false,
            fileLicitacion: null,
            buttonDisabled: true,
            totalRows: 1,
            currentPage: 1,
            totalRows2: 1,
            currentPage2: 1,
            totalRows3: 1,
            currentPage3: 1,
            perPage: 10,
            perPage2: 10,
            perPage3: 10,
            pageOptions: [10, 25, 50, 100],
            pageOptions2: [10, 25, 50, 100],
            pageOptions3: [10, 25, 50, 100],
            filter: null,
            filter2: null,
            filter3: null,
            filterOn: [],
            filterOn2: [],
            filterOn3: [],
            sortBy: "created_at",
            sortDesc: true,
            fields: [ 
                { key: 'created_at', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
                { key: "num",label:'ID', sortable: true },
                { key: "nombre",label:'Nombre', sortable: true },
                { key: "cliente", label:'Cliente',sortable: true },
                //{ key: "descripcion",label:'Descripción', sortable: true },
                { key: "accion", label:"Acciones"},
            ],
            campos_productos: [
                { key: "phone",label:'Nombre', sortable: true },
                { key: "cantidad", label:'Cantidad',sortable: true },
                { key: "precio", label:'Precio',sortable: true },
                { key: "accion", label:"Acciones"},
            ],
            licitaciones:[],
            Componentes_Licitacion:{
                id:'',
                numero:null,
                nombre:null,
                cliente:null,
                productos:null,
                monto:null,
                observaciones:null,
                condicion:null,
            },
            clientes:[],
            productos:[],
            phoneData: {
                phone:'',
                cantidad:'',
                precio:'',
            },
            codigo:'',
            codigos:[],
            Datoslicitacion:'',
            idlicitacion:'',
            Propiedades: {width: 600, height: 300,  },
            productos_licitacion:[],
            a:[],
            formato_imagen:'',
            clean_data:[],
            color_button:'',
            url_prueba:'',
            data:'',
            ancho_pdf:0,
            Productos_Seleccionados:[],
            Select_All:false,
            Disabled_Button_Adjudicar:true,
            Boton_Adjudicar:true,
            Boton_No_Adjudicar:false,
            disabled_button:false,
            disabled_button_eliminar:false,
            val_export:false,
            Verificador_cliente:"",
            Verificador_cliente_modificado:"",
            Mostrar_cliente:false,
            Mostrar_cliente_modificado:true,
            Total_monto:0,
            lectura:true,
            diferenciador_vistas:false, // utilizado para diferenciar el boton volver al crear licitacion, usado desde el boton principal o plantilla ( por reseteo de variables)
            showbutton_crearlicitacion:false,
            showbutton_modificarlicitacion:false,
            productos_tabla:[],
            SelectedProducto:'',
            CantidadModificar:'',
            PrecioModificar:'',
            EstadoProducto:false,
            AlertaCantidad:false,
            AlertaPrecio:false,
        };
    },
    computed: {
        rows() {
        return this.licitaciones.length;
        },
        rows2() {
        return this.productos_tabla.length;
        },
        rows3() {
        return this.productos_tabla.length;
        },
        Totalprecios (){
            let valorparcial = 0
            this.Productos_Seleccionados.forEach(element => {
                valorparcial = parseInt(valorparcial) + (parseInt(element.precio)* parseInt(element.cantidadadjudicacion))
            });

            return valorparcial
        },
        Total_licitacion () {
            let valorparcial = 0
            this.productos_tabla.forEach(element => {
                valorparcial = parseInt(valorparcial) + (parseInt(element.precio)* parseInt(element.cantidad))
            });
            if(Number.isNaN(valorparcial)){
                return 'Ingresar valor númerico'
            }else{
                return Intl.NumberFormat("de-DE").format(valorparcial)
            }   
        },
        startIndex() {
            return (this.currentPage3 - 1) * this.perPage3 + 1
            }, 

    },
    mounted() {
        this.totalRows = this.items.length;
        this.totalRows2 = this.items.length;
    },
    watch: {
        Select_All: function(value,Oldvalue){
            //console.log(value);
            if(value==true){
                //console.log('entramos al true');  
                this.Componentes_Licitacion.productos.forEach(element => {
                    if(!this.Productos_Seleccionados.includes(element)){
                        this.Productos_Seleccionados.push(element)
                    }
                });
                //console.log(this.Componentes_Licitacion.productos);
            }else{
                //console.log('entramos al false');
                this.Productos_Seleccionados=[]
            }
            
            
        },
        Productos_Seleccionados: function (value,Oldvalue){
            //console.log(value);
            //console.log(Oldvalue);
            if(value==''){
                //console.log('entramos'+value);
                this.Boton_Adjudicar=true
                this.Boton_No_Adjudicar=false
                this.lectura=true

            }else{
                //console.log('entramos al else'+value)
                this.Boton_Adjudicar=false
                this.Boton_No_Adjudicar=true
                this.lectura=false
            }

            
            
        },
        Verificador_cliente: function(value,Oldvalue){
            //verifique que hay guion
            if(value.includes('-')){
                //console.log('tiene guion');
                if(value.indexOf('-')!=0){
                    //console.log('el guion no es la primera letra');
                    if((value.indexOf('-')+1)==value.length){
                        //console.log('el guion al final');
                    }else{
                        //console.log('el guion no al final');
                        let vista_toasted=0
                        for (let i = 0; i < this.clientes.length; i++) {
                            const element = this.clientes[i];
                            if(value==element.rut){
                                //console.log('rut correcto');
                                this.Mostrar_cliente=true
                                vista_toasted=0
                                this.Componentes_Licitacion.cliente=element.nombre
                                //console.log(this.Componentes_Licitacion.cliente);
                                break;
                            }else{
                                
                                vista_toasted=1
                                this.Mostrar_cliente=false
                                this.Componentes_Licitacion.cliente=[]
                            }  
                        }
                        if(vista_toasted!=0){
                            this.$toasted.error('rut incorrecto');
                        }else{
                            this.$toasted.success('rut correcto');
                        } 
                    }
                }else{
                    //console.log('el guion es la primera letra');
                }
            }else{
                //console.log('no tiene guion');
            }
        },
        Verificador_cliente_modificado: function(value,Oldvalue){
            //verifique que hay guion
            if(value.includes('-')){
                //console.log('tiene guion');
                if(value.indexOf('-')!=0){
                    //console.log('el guion no es la primera letra');
                    if((value.indexOf('-')+1)==value.length){
                        //console.log('el guion al final');
                    }else{
                        //console.log('el guion no al final');
                        let vista_toasted=0
                        this.Mostrar_cliente_modificado=true
                        for (let i = 0; i < this.clientes.length; i++) {
                            const element = this.clientes[i];
                            if(value==element.rut){
                                //console.log('rut correcto');
                                this.Mostrar_cliente=true
                                vista_toasted=0
                                this.Componentes_Licitacion.cliente=element.nombre
                                //console.log(this.Componentes_Licitacion.cliente);
                                break;
                            }else{
                                this.Mostrar_cliente=false
                                vista_toasted=1
                                this.Componentes_Licitacion.cliente=null
                                //console.log(this.Componentes_Licitacion.cliente);
                            }  
                        } 
                        if(vista_toasted!=0){
                            this.$toasted.error('rut incorrecto');
                        }else{
                            this.$toasted.success('rut correcto');
                        } 
                    }
                }else{
                    //console.log('el guion es la primera letra');
                }
            }else{
                //console.log('no tiene guion');
            }       
        },
        
    },
    methods: {
        formatVencimiento(date) {
            if (date) {
            const parts = date.split('-');
            if (parts.length === 3) {
                return `${parts[2]}-${parts[1]}-${parts[0]}`; // Reordena a DD-MM-YYYY
            }
            }
            return date;
        },
        camposmsg(text,icon) {
            Swal.fire({
                icon: icon,
                title: text,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f",
                timer:3500,
                imageWidth: 1200,
                imageHeight: 1200,

            }).then(()=>{
                window.location.href = `/licitaciones`;
            });
        },
        validacionmsg(text,icon,mensaje) {
            if(mensaje!=undefined){
                Swal.fire({
                icon: icon,
                title: text,
                html: mensaje.map(item => '<li>'+item+'</li>').join(''),
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f",
            });
            }else{
                Swal.fire({
                icon: icon,
                title: text,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f"
            });
            }
            
        },
        Adjudicacionmsg(){
            //console.log(this.Productos_Seleccionados);
            Swal.fire({
                title: '¿Estás seguro/a?',
                text: "Este cambio no puede ser revertido",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                        //console.log(this.Productos_Seleccionados);
                        this.Componentes_Licitacion.productos_licitados=this.Productos_Seleccionados
                        /* console.log(this.Componentes_Licitacion);
                        console.log(this.Totalprecios) */
                        //console.log(this.Componentes_Licitacion.monto);
                        axios.post('/api/adjudicar',this.Componentes_Licitacion).then(res=>{
                            if(res.data.estado){
                            this.color_button='success'
                            //console.log(this.color_button);
                            this.camposmsg('Licitacion adjudicada correctamente','warning')
                            }
                        })                      
                }
            })
        },
        No_Adjudicacionmsg(){
            Swal.fire({
                title: '¿Estás seguro/a?',
                text: "Este cambio no puede ser revertido",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.Componentes_Licitacion.productos_licitados=this.Productos_Seleccionados
                    axios.post('/api/no_adjudicar',this.Componentes_Licitacion).then(res=>{
                        if(res.data.estado){
                        this.color_button='warning'
                        //console.log(this.color_button);
                        this.camposmsg('Licitacion no adjudicada correctamente','warning')
                        }
                    })
                }
            })
        },
        generateReport () {
            //console.log(this.Componentes_Licitacion);
            this.showbutton_crearlicitacion=true
            axios.post('/api/crear_licitacion',this.Componentes_Licitacion).then(res=>{
                if(res.data.estado){
                    this.camposmsg('Licitacion creada correctamente','warning')
                }
                /**
                 * ocultar /se deja la exportacion en pdf oculta if = false.
                 */
                if(false){
                    //this.$refs.html2Pdf.generatePdf();
                    var doc = new jsPDF({ putOnlyUsedFonts: false, orientation: "portrait" });
                    doc.setFontSize(10);
                    doc.setFont("helvetica", "bold");
                    doc.addImage("/archivos_base/image001.png", "PNG", 85, 3, 40, 30);
                    doc.text("Número : "+this.Componentes_Licitacion.numero, 20, 35);
                    doc.text("Nombre : "+this.Componentes_Licitacion.nombre, 20, 40);
                    doc.text("Cliente : "+this.Componentes_Licitacion.cliente, 20, 45);
                    doc.text("Productos : ", 20, 50);
                    var a = 60
                    doc.setFont("helvetica", "normal");
                    this.Componentes_Licitacion.productos.forEach(element => {
                        //console.log(element);
                        //console.log('inicio'+this.formato_imagen);
                        if(element.foto_dir.includes('png')){
                            this.formato_imagen='PNG'  
                        }
                        if(element.foto_dir.includes('jpg')){
                            this.formato_imagen='JPG'
                        }
                        if(element.foto_dir.includes('jpeg')){
                            this.formato_imagen='JPEG'
                        }
                        //console.log('despues'+this.formato_imagen);
                        doc.addImage(element.foto_dir, this.formato_imagen, 20, a, 170, 60);
                        var b=a+60

                        for (const property in element){
                            //console.log('property'+property);
                            //console.log('value:'+element[property]);
                            if(element[property] != 'null' &&  property != 'id' &&  property != 'foto' &&  property != 'foto_dir' &&  property != 'activo' &&  property != 'user' &&  property != 'created_at' &&  property != 'updated_at' &&  property != 'descripcion' &&  property != 'duracion' &&  property != 'envio' &&  property != 'plazoentrega'){
                                b=b+10
                                if(property =='codigo'){
                                    doc.text("Código: "+element[property], 20, b)
                                }
                                if(property =='nombre'){
                                    doc.text("Nombre: "+element[property], 20, b)
                                }
                                /*
                                if(property =='descripcion'){
                                    doc.text("Descripción: "+element[property], 20, b)
                                }
                                */
                                if(property =='marca'){
                                    doc.text("Marca: "+element[property], 20, b)
                                }
                                if(property =='fabricacion'){
                                    doc.text("Fabricación: "+element[property], 20, b)
                                }
                                if(property =='vencimiento'){
                                    doc.text("Vencimiento: "+element[property], 20, b)
                                }
                                /*
                                if(property =='duracion'){
                                    doc.text("Duración: "+element[property], 20, b)
                                }
                                */
                                if(property =='certificacion'){
                                    doc.text("Certificación: "+element[property], 20, b)
                                }
                                if(property =='presentacion'){
                                    doc.text("Presentación: "+element[property], 20, b)
                                }
                                /*
                                if(property =='envio'){
                                    doc.text("Envío: "+element[property], 20, b)
                                }
                                if(property =='plazoentrega'){
                                    doc.text("Plazo de entrega: "+element[property], 20, b)
                                }
                                */
                                if(property =='caracteristicas'){
                                    doc.text("Caracteristicas: "+element[property], 20, b)
                                }
                            }
                        }
                        doc.addPage();    
                    });
                        doc.setFontSize(10);
                        doc.addImage("/archivos_base/logo.png", "PNG", 85, a+165, 40, 20);
                        doc.text("Dirección: Errázuriz 2066 – Valdivia", 105, a+190, null, null, "center");
                        doc.text("Teléfono: +56 63 2 204385", 105, a+200, null, null, "center");
                        doc.text("Email: contacto@vamproden.cl", 105, a+210, null, null, "center");
                        doc.text("www.vamproden.cl", 105, a+220, null, null, "center");
                    
                    doc.save(this.Componentes_Licitacion.numero+'_'+this.Componentes_Licitacion.nombre);
                    this.camposmsg('Licitacion creada correctamente','warning')
                }
            })
        },
        Exportarpdf(data){
            this.isLoading = true;
            axios.get('/api/ver_producto/'+data.id).then(res=>{
                if(res.data.estado){
                    this.productos = res.data.productos
                    var doc = new jsPDF({ putOnlyUsedFonts: false, orientation: "portrait" ,format:'legal'});
                    doc.setFontSize(10);
                    doc.setFont("helvetica", "bold");
                    doc.addImage("/archivos_base/logo03.png", "PNG", 85, 3, 40, 30,'FAST');
                    doc.text("ID : "+data.num, 20, 35);
                    doc.text("Nombre : "+data.nombre, 20, 40);
                    doc.text("Cliente : "+data.cliente, 20, 45);
                    if(data.condicion!=null){
                        doc.text("Condición : "+data.condicion, 20, 50);
                    }        
                    doc.text("Productos : ", 20, 55);
                    var a = 60
                    doc.setFont("helvetica", "normal");
                    //console.log('despues'+this.productos);
                    this.productos.forEach((element,index) => {
                        var b = 0
                        if(index==0){
                            b=a
                        }else{
                            b=10   
                        }
                        let final_caracteristicas=0
                        let altura=0
                        for (const property in element){
                            if(element[property] != null && element[property] != 'null'){
                                if(property =='codigo'){
                                    b=b+5
                                    doc.setFont("helvetica", "bold");
                                    doc.text("Código:", 20, b)
                                    doc.setFont("helvetica", "normal");
                                    doc.text(element[property], 45, b)
                                }
                                if(property =='nombre'){
                                    b=b+10
                                    doc.setFont("helvetica", "bold");
                                    doc.text("Nombre:", 20, b)
                                    doc.setFont("helvetica", "normal");
                                    doc.text(element[property], 45, b)
                                }
                                /*
                                if(property =='descripcion'){
                                    doc.text("Descripción: "+element[property], 20, b)
                                }
                                */
                                if(property =='marca'){
                                    b=b+10
                                    doc.setFont("helvetica", "bold");
                                    doc.text("Marca:", 20, b)
                                    doc.setFont("helvetica", "normal");
                                    doc.text(element[property], 45, b)
                                }
                                if(property =='fabricacion'){
                                    b=b+10
                                    doc.setFont("helvetica", "bold");
                                    doc.text("Fabricación:", 20, b)
                                    doc.setFont("helvetica", "normal");
                                    doc.text(element[property], 45, b)
                                }
                                if(property =='vencimiento'){
                                    b=b+10
                                    doc.setFont("helvetica", "bold");
                                    doc.text("Vencimiento:", 20, b)
                                    doc.setFont("helvetica", "normal");
                                    doc.text(this.formatVencimiento(element[property]), 45, b)
                                }
                                /*
                                if(property =='duracion'){
                                    doc.text("Duración: "+element[property], 20, b)
                                }
                                */
                                if(property =='certificacion'){
                                    b=b+10
                                    doc.setFont("helvetica", "bold");
                                    doc.text("Certificación: ", 20, b)
                                    doc.setFont("helvetica", "normal");
                                    doc.text(element[property], 45, b)
                                }
                                if(property =='presentacion'){
                                    b=b+10
                                    doc.setFont("helvetica", "bold");
                                    doc.text("Presentación:", 20, b)
                                    doc.setFont("helvetica", "normal");
                                    doc.text(element[property], 45, b)
                                }
                                /*
                                if(property =='envio'){
                                    doc.text("Envío: "+element[property], 20, b)
                                }
                                if(property =='plazoentrega'){
                                    doc.text("Plazo de entrega: "+element[property], 20, b)
                                }
                                */
                                if(property =='caracteristicas'){
                                    b=b+10
                                    
                                    let texto_lineas = element[property]
                                    let valor_amostrar = element[property]
                                    //contar saltos de linea
                                    let regEx = new RegExp('\\n','g')
                                    let valor_propiedad = element[property].replace(regEx,'@')
                                    let a = 0
                                    for(var i=0; i<valor_propiedad.length; i++) {
                                    if(valor_propiedad.charAt(i) === '@'){
                                        a = a+ 1
                                    }
                                    }
                                    // contar lineas
                                    let z = 0
                                    let regEx2 = new RegExp('\\s+','g')
                                    let valor_propiedad_2 = texto_lineas.replace(regEx2,' ')
                                    final_caracteristicas = valor_propiedad_2.length
                                    z = Math.trunc(parseInt(final_caracteristicas)/104)
                                    let y=a+z

                                    if(y == 14 || y == 13 | y == 12){
                                        altura = 80
                                    }else if(y == 11 || y == 10 || y == 9){
                                        altura = 60
                                    }
                                    else if(y == 8 || y == 7 || y == 6){
                                        altura = 50
                                    }
                                    else if(y == 5 || y == 4 || y == 3){
                                        altura = 40
                                    }else if(y == 2 || y == 1 || y == 0){
                                        altura = 30
                                    }
                                    
                                    doc.setFont("helvetica", "bold");
                                    doc.text("Caracteristicas:", 20, b,{ align: "justify", lineHeightFactor: 1.5, maxWidth: 170 })
                                    doc.setFont("helvetica", "normal");                                  
                                    doc.text(valor_amostrar, 20, b+5,{ lineHeightFactor: 1.5, maxWidth: 170 })
                                    
                                }
                                
                            }
                        }

                        if(element.foto_dir_resize.includes('png')){
                            this.formato_imagen='PNG'  
                        }
                        if(element.foto_dir_resize.includes('jpg')){
                            this.formato_imagen='JPG'
                        }
                        if(element.foto_dir_resize.includes('jpeg')){
                            this.formato_imagen='JPEG'
                        }
                        doc.addImage(element.foto_dir_resize, this.formato_imagen,50, b+altura,0, 80); //b-50  104
                        doc.addImage("/archivos_base/vampro_tira.png", this.formato_imagen,0, 320,217, 12); //320 pie de pagina
                        doc.addPage();    
                    });
                    doc.setFontSize(10);
                    doc.addImage("/archivos_base/logo.png", "PNG", 85, a+55, 40, 20,'FAST');
                    doc.text("Dirección: Errázuriz 2066 – Valdivia", 105, a+80, null, null, "center");
                    doc.text("Teléfono: +56 63 2 204385", 105, a+90, null, null, "center");
                    doc.text("Email: contacto@vamproden.cl", 105, a+100, null, null, "center");
                    doc.text("www.vamproden.cl", 105, a+110, null, null, "center");
                
                    doc.save(data.num+'_'+data.nombre);
                    //this.camposmsg('Exportación correcta','warning')
                    //console.log(this.productos);
                }
                this.isLoading = false;
            });
        },
        Exportarcsv(data){
            this.isLoading = true;
            axios({
                url: '/api/csv_licitacion/'+data.id, // URL de la solicitud GET
                method: 'GET', // Método de la solicitud
                responseType: 'blob', // Tipo de respuesta: archivo blob
            }).then(response => { // Función a ejecutar cuando la solicitud se complete con éxito
                // Crear una URL de objeto para el archivo blob
                const url = window.URL.createObjectURL(new Blob([response.data]));

                // Obtener el encabezado Content-Disposition que contiene el nombre de archivo
                const disposition = response.headers['content-disposition'];

                // Extraer el nombre de archivo del encabezado Content-Disposition
                const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
                const filename = matches[1].replace(/['"]/g, '');

                // Crear un enlace y configurar la URL y el nombre de archivo para la descarga
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);

                // Agregar el enlace al cuerpo del documento, simular un clic en el enlace y eliminarlo después
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.isLoading = false;
            }).catch(error => { // Función a ejecutar si hay un error en la solicitud
                console.error('Error al descargar el archivo CSV', error);
                this.isLoading = false;
            });

        },
        AddPhoneNo() {
            //console.log(this.phoneData);
            var contador = 0
            if(this.phoneData.cantidad==''){
                contador = contador +1
                return Swal.fire({
                icon: "warning",
                title: "Cantidad vacía",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f"
                })
            }
            if(this.phoneData.precio==''){
                contador = contador +1
                return Swal.fire({
                icon: "warning",
                title: "Precio vacío",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f"
                })
            }
            this.productos_tabla.forEach(item => {
                //console.log(item);
                if(item.phone == this.phoneData.phone){
                    contador = contador +1
                    return Swal.fire({
                    icon: "warning",
                    title: "Producto repetido",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "Volver",
                    iconColor:"#34c38f"
                    })
                }
            });
            if(contador ==0){
                this.productos_tabla.push(this.phoneData)
                //console.log(this.productos_tabla);
                this.phoneData={
                    phone:'',
                    cantidad:'',
                    precio:''
                }
            }
            //console.log(this.productos_tabla);
        },
        validarproducto(data){
            //console.log('prueba_phone'+data);
        },
        deletePhone(data) {
            //console.log(data.phone);
            this.productos_tabla.forEach(function(item ,index,object)  {
                if(item.phone == data.phone){
                    /* console.log('eliminar');
                    console.log(index); */
                    object.splice(index, 1) 
                }
            });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        onFiltered2(filteredItems) {
        this.totalRows2 = filteredItems.length;
        this.currentPage = 1;
        },
        onFiltered3(filteredItems) {
        this.totalRows3 = filteredItems.length;
        this.currentPage = 1;
        },
        CrearLicitacion(){
            this.Componentes_Licitacion={
                numero:null,
                nombre:null,
                cliente:null,
                productos:null,
                observaciones:null,
                condicion:null,
            },
            this.phoneData=[]
            this.productos_tabla=[]
            this.$refs['Crear_Licitacion'].show();
        },
        Plantillalicitacion(){
            //this.phoneData=this.Componentes_Licitacion.productos
            this.diferenciador_vistas = true
            this.phoneData = []
            
            this.Componentes_Licitacion.productos.forEach(element => {

                
                this.productos_tabla.push({ 
                phone: element.codigo+' - '+element.nombre+' - '+element.marca ,
                cantidad:element.cantidad,
                precio:element.precio,
            });
            });
            this.$refs['Crear_Licitacion'].show();
        },
        Crear(){
                /* let validacion = 0
                let norepetidos = []
                let repetidos = []
                this.phoneData.forEach(seleccion => {
                    if(!norepetidos.includes(seleccion.phone)){
                        norepetidos.push(seleccion.phone)
                    }else{
                        repetidos.push(seleccion.phone)
                    }
                });
                //eliminar duplicados en los repetidos 
                const dataArr = new Set(repetidos);
                //transformar repetidos a arreglo para mostrarlo en swal.alert
                let result = [...dataArr];
                if(repetidos.length!=0){
                    //console.log(repetidos);
                    validacion = validacion + 1
                    return this.validacionmsg('Productos repetidos:','warning',result)
                }  */
                //si no hay productos repetidos sigue con el resto de validaciones
                //console.log('no hay repetidos');
                if(this.Componentes_Licitacion.numero == null || this.Componentes_Licitacion.nombre == null || this.Componentes_Licitacion.cliente == null || this.Componentes_Licitacion.cliente == '' || this.Componentes_Licitacion.cliente == 'null' || this.productos_tabla.length < 1 || this.Componentes_Licitacion.numero == ''){
                    return this.validacionmsg('No puede haber campos vacios','warning');
                }
                this.codigos=[]
                this.productos_tabla.forEach(element_vista => {
                    this.codigo = element_vista.phone.split(' -')
                    this.productos.forEach(element => {
                        if(element.codigo == this.codigo[0]){
                            element.cantidad=element_vista.cantidad
                            element.precio=element_vista.precio
                            this.codigos.push(element)  
                        }
                    });
                });
                this.Componentes_Licitacion.productos=this.codigos
                this.$refs['Vista_Licitacion'].show();                       
        },
        Volver(data){
            //console.log(this.diferenciador_vistas);
            if(this.diferenciador_vistas){
                
                this.$refs['Crear_Licitacion'].hide()
                this.diferenciador_vistas=false
                
            }else{
                if (data==1) {
                    this.Componentes_Licitacion={
                        numero:null,
                        nombre:null,
                        cliente:null,
                        observaciones:null,
                        condicion:null,
                    },
                    this.phoneData={
                        phone:'',
                        precio:'',
                        cantidad:''
                    }
                    this.productos_tabla=[],
                    this.Verificador_cliente=''
                    //console.log('volver'+' '+this.phoneData)
                    this.$refs['Crear_Licitacion'].hide() 

                }else if(data==2){
                    this.Componentes_Licitacion={
                            numero:null,
                            nombre:null,
                            cliente:null,
                            observaciones:null,
                            condicion:null,
                        },
                        this.phoneData={
                            phone:'',
                            precio:'',
                            cantidad:''
                        }
                    this.productos_tabla=[],
                    this.Verificador_cliente_modificado=''
                    this.filter2=null
                    this.filterOn2=[]
                    this.$refs['Modificar_Licitacion'].hide() 
                }else{
                    this.Productos_Seleccionados=[]
                    this.productos_tabla=[]
                    this.Select_All=false,
                    this.$refs['Adjudicar_Licitacion'].hide() 
                    
                }
            }
 
        },
        Volver_vistaprevia(vista){
            //console.log(this.diferenciador_vistas);
            if(this.diferenciador_vistas){
                //this.Componentes_Licitacion.productos=[]
                this.$refs['Vista_Licitacion'].hide()
            }else{
                if(vista == 1){
                    this.codigos=[]
                    this.Componentes_Licitacion.productos=[]
                    this.$refs['Vista_Licitacion'].hide()  
                }else if(vista == 2){
                    this.diferenciador_vistas=false
                    this.$refs['Ver_Licitacion'].hide()
                }
            }
            
 
            
        },
        showModalver(data){
            //console.log(data.id)
            this.idlicitacion=data.id;
            axios.get('/api/datos_licitacion/'+this.idlicitacion).then(res=>{
                let valorparcial=0
                res.data.productos.forEach(element => {
                    valorparcial = parseInt(valorparcial) + (parseInt(element.precio)* parseInt(element.cantidad))
                });
                //console.log(data);
                this.Componentes_Licitacion={
                    numero:data.num,
                    nombre:data.nombre,
                    cliente:data.cliente,
                    //estado:data.estado,
                    productos:res.data.productos,
                    monto:Intl.NumberFormat("de-DE").format(valorparcial),
                    montoadjudicado:data.monto,
                    observaciones:data.observaciones,
                    condicion:data.condicion,
                    //nombre:res.data.licitacion.nombre,
                    //cliente:res.data.licitacion.cliente,   
                }
                //console.log(this.Componentes_Licitacion)
            })
            
            this.$refs['Ver_Licitacion'].show();
        },
        showModaleditar(data){
            this.productos_tabla=[]
            this.idlicitacion=data.id;
            //console.log(this.idlicitacion);
            axios.get('/api/datos_licitacion/'+this.idlicitacion).then(res=>{
                //console.log(res.data);
                this.Componentes_Licitacion={
                    id:data.id,
                    numero:data.num,
                    nombre:data.nombre,
                    cliente:data.cliente,
                    productos:res.data.productos,
                    observaciones:data.observaciones,
                    condicion:data.condicion
                    //nombre:res.data.licitacion.nombre,
                    //cliente:res.data.licitacion.cliente,   
                }
                //console.log(this.Componentes_Licitacion.productos);
                this.Componentes_Licitacion.productos.forEach(element => {
                    this.productos_tabla.push({ 
                    phone: element.codigo+' - '+element.nombre+' - '+element.marca ,
                    cantidad:element.cantidad,
                    precio:element.precio,
                    });
                });
                //console.log(this.productos_tabla);
            this.$refs['Modificar_Licitacion'].show();
            })
            

        },
        Modificar(){
            this.showbutton_modificarlicitacion=true
            this.codigos=[];

            if(this.Componentes_Licitacion.numero == '' || this.Componentes_Licitacion.numero == null  || this.Componentes_Licitacion.numero == 'null' || this.Componentes_Licitacion.nombre == '' || this.Componentes_Licitacion.cliente == '' || this.Componentes_Licitacion.cliente == null || this.Componentes_Licitacion.cliente == 'null'  || this.productos_tabla.length < 1){
                this.showbutton_modificarlicitacion=false
                return this.validacionmsg('No puede haber campos vacios','warning');
            }
            this.productos_tabla.forEach(element_vista => {
                this.codigo = element_vista.phone.split(' -')
                this.productos.forEach(element => {
                    if(element.codigo == this.codigo[0]){
                        element.cantidad=element_vista.cantidad
                        element.precio=element_vista.precio
                        this.codigos.push(element)  
                    }
                });
            });
            this.Componentes_Licitacion.productos=this.codigos
            //console.log(this.Componentes_Licitacion.productos)
            //console.log(this.Componentes_Licitacion);
            axios.post('/api/modificar_licitacion',this.Componentes_Licitacion).then(res=>{
                if(res.data.estado){
                    this.camposmsg('Licitacion modificada correctamente','warning')
                }
            })          
        },
        exportDocx(data) {  
            
             this.idlicitacion=data.id;
            this.val_export=true
            
            axios.post('/api/descargarword',data).then(res=>{
                //console.log(res.data.url);
                this.url_prueba=res.data.url
                this.data=data
                this.val_export=false
            })
            this.$refs['Ver_Formato'].show(); 
        },
        Adjudicacion(data){
            //console.log('id licitacion: '+data.id)
            this.idlicitacion=data.id;
            axios.get('/api/datos_licitacion/'+this.idlicitacion).then(res=>{
                //console.log('res data: '+res.data.productos);
                this.Componentes_Licitacion={
                    id:data.id,
                    numero:data.num,
                    nombre:data.nombre,
                    cliente:data.cliente,
                    productos:res.data.productos,
                    observaciones:data.observaciones,
                    //nombre:res.data.licitacion.nombre,
                    //cliente:res.data.licitacion.cliente,   
                    
                }
                let totalparcial = 0
                this.Componentes_Licitacion.productos.forEach(element => {
                    totalparcial = parseInt(totalparcial)  + parseInt(element.precio)
                });
                this.Total_monto = totalparcial
                //console.log(this.Componentes_Licitacion)
            })

            //console.log(this.Componentes_Licitacion)
            
            this.$refs['Adjudicar_Licitacion'].show();
        },
        Adjudicar(){
            //console.log("adjudicada");
            
            
            this.Componentes_Licitacion.monto = this.Totalprecios
            //console.log(this.Componentes_Licitacion.monto);
            if(this.Componentes_Licitacion.monto == null || this.Componentes_Licitacion.monto==''){
                this.validacionmsg('Monto vacío','warning'); 
            }else if(this.Productos_Seleccionados.length===0){
                this.validacionmsg('Debe seleccionar al menos un producto','warning'); 
            }else{
                let validador = 0
                this.Componentes_Licitacion.productos.forEach(element => {
                if(element.cantidadadjudicacion<0){
                    validador = validador + 1
                    this.validacionmsg('Cantidad negativa, no permitida','warning');
                }else if (element.cantidadadjudicacion==0){
                    validador = validador + 1
                    this.validacionmsg('Cantidad igual a cero, no permitida','warning')
                }
            });
            if (validador == 0 ){
                //console.log(this.Productos_Seleccionados);
                this.Adjudicacionmsg()
            }       
            }
        },
        editarPhone(index){
            console.log(index);
            this.SelectedProducto = (this.currentPage3-1)*this.perPage3+index
            this.CantidadModificar = this.productos_tabla[this.SelectedProducto].cantidad
            console.log(this.CantidadModificar);
            this.CantidadPrecio = this.productos_tabla[this.SelectedProducto].precio
            console.log(this.CantidadPrecio);
            if(this.CantidadModificar && this.CantidadPrecio){
                this.EstadoProducto=true
            }
            //console.log(this.EstadoProducto);
            this.$refs['Editar_Productos_Licitacion'].show();
        },
        editarPhone_create(index){
            console.log(index);
            this.SelectedProducto = (this.currentPage2-1)*this.perPage2+index
            this.CantidadModificar = this.productos_tabla[this.SelectedProducto].cantidad
            console.log(this.CantidadModificar);
            this.CantidadPrecio = this.productos_tabla[this.SelectedProducto].precio
            console.log(this.CantidadPrecio);
            if(this.CantidadModificar && this.CantidadPrecio){
                this.EstadoProducto=true
            }
            //console.log(this.EstadoProducto);
            this.$refs['Editar_Productos_Licitacion'].show();
        },
        actualizar_producto(){
            if(this.CantidadModificar && this.CantidadPrecio){
                this.productos_tabla[this.SelectedProducto].cantidad = this.CantidadModificar
                this.productos_tabla[this.SelectedProducto].precio= this.CantidadPrecio 
                this.AlertaCantidad = false
                this.AlertaPrecio = false
            }else{
                if(!this.CantidadModificar){
                    this.AlertaCantidad = true
                }
                if(!this.CantidadPrecio){
                    this.AlertaPrecio = true
                }
            }
        },
        Hidemodal(data){
            //console.log('aki');
            if(data == 1){
                //console.log('aki2');
                this.SelectedProducto = ''
                this.CantidadModificar = ''
                this.CantidadPrecio = ''
                this.EstadoProducto = false
                this.AlertaCantidad = false
                this.AlertaPrecio = false
                this.$refs['Editar_Productos_Licitacion'].hide();
            }
        },
        onFileLicitacionChange(e) {
            this.fileLicitacion = e.target.files[0];
            this.buttonDisabled =false;
        },
        uploadFileLicitacion() {
            this.isLoading = true;
            if (this.buttonDisabled) {
                return; // Si está deshabilitado, salir sin hacer nada
            }
            this.buttonDisabled = true;

            let formData = new FormData();
            formData.append('file', this.fileLicitacion);

            axios.post('/api/upload_csv_licitacion', formData).then(response => {
                if(response.data.estado){
                    console.log(response.data.mensaje);
                    console.log(response.data.productosCargados);
                    console.log(response.data.productosError);
                    if(response.data.productosError.length==0){
                        window.location.reload();
                    }else{
                        let mensaje = 'Estos productos no fueron cargados:<br>';
                        response.data.productosError.forEach(producto => {
                            mensaje += `• ${producto}<br>`;
                        });
                        Swal.fire({
                            title: 'Hubo un error',
                            html: mensaje,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Revisar',
                            //cancelButtonText: 'Cancelar',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload();                   
                            }
                        })
                    }
                }else if(response.data.error==1){
                    console.log(response.data.mensaje);
                }else if(response.data.error==2){

                }
                this.isLoading = false;
            }).catch(error => {
                console.error(response.data.mensaje, error);
                this.isLoading = false;
            });

            setTimeout(() => {
                // Volver a habilitar el botón después de algún tiempo (por ejemplo, 3 segundos)
                this.buttonDisabled = false;
            }, 5000);
        },
        eliminar(item){
            this.disabled_button_eliminar=true
            Swal.fire({
                title: '¿Estás seguro/a de eliminar '+item.num+' ?',
                text: "Este cambio no puede ser revertido",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let payload={
                        licitacion_id:item.id
                    }
                    axios.post('/api/eliminar_licitacion',payload).then(res=>{
                        if(res.data.estado){
                        this.color_button='success'
                        //console.log(this.color_button);
                        this.camposmsg('Licitacion eliminada correctamente','warning')
                        }else{
                            this.camposmsg('Error al eliminar!!','warning')
                        }
                    })                      
                }
                this.disabled_button_eliminar=false
            })
        }
    },
    created(){
        axios.get('/api/get_licitaciones').then(res=>{
            this.licitaciones = res.data.licitaciones;
            //console.table(this.licitaciones);

            this.licitaciones.forEach(element => {
                if(element.estado!=1 || element.estado!=2){
                    element.color='info'
                    element.vista=false
                }
                if(element.estado==1){
                    element.color='success'
                    element.vista=true
                }
                if(element.estado==2){
                    element.color='danger'
                    element.vista=true
                }
                

            });
            //console.log(this.licitaciones)
        })
        axios.get('/api/get_clientes').then(res=>{
            this.clientes = res.data.clientes;
        })
          axios.get('/api/get_productos_filtrados').then(res=>{
            this.productos = res.data.productos;
            //console.table(this.productos);
        })
/*
        axios.get('/api/get_productos').then(res=>{
            this.productos = res.data.productos;
            //console.table(this.productos);
        }) */
        this.Componentes_Licitacion={
            numero:null,
            nombre:null,
            cliente:null,
            productos:null,
            observaciones:null,
            condicion:null,
        }
    }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col-12">
            <div class="card shadow-lg">
                <div class="card-body">
                    <div class="loading-overlay" v-if="isLoading">
                        <loading-message  />
                    </div>
                    <div class="row pr-1">
                        <div class="col-10">
                            <h4 class="card-title">Licitaciones</h4>
                        </div>
                        <div class="col-2 ">
                            <b-button class="float-right mr-0" variant="info" @click="CrearLicitacion()">Agregar Licitación</b-button>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                    Mostrar&nbsp;
                                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                <label class="d-inline-flex align-items-center">
                                    Buscar:
                                    <b-form-input
                                    v-model="filter"
                                    type="search"
                                    placeholder="Buscar..."
                                    class="form-control form-control-sm ml-2"
                                    ></b-form-input>
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                        <b-table class="table table-striped" hover
                            :items="licitaciones"
                            :fields="fields"
                            responsive="sm"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            @filtered="onFiltered"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                        >
                        <template v-slot:cell(accion)="data">
                            <b-button size="sm" variant="info" @click="showModalver(data.item)">Ver</b-button>
                            <b-button size="sm" variant="info" @click="showModaleditar(data.item)" v-if="!data.item.vista">Editar</b-button>
                            <b-button v-if="data.item.estado==1 || data.item.estado==2" :disabled="!data.item.despacho"  size="sm" :variant="data.item.color" @click="Adjudicacion(data.item)">Editar</b-button>
                            <b-button size="sm" variant="info" @click="exportDocx(data.item)">Exportar</b-button>
                            <b-button :disabled="data.item.estado==1 || data.item.estado==2"  size="sm" :variant="data.item.color" @click="Adjudicacion(data.item)">Adjudicar </b-button>
                            <b-button v-if="data.item.tipo=='CSV'" :disabled="disabled_button_eliminar"  size="sm" variant="danger" @click="eliminar(data.item)">Eliminar </b-button>


                        </template>
                        </b-table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded mb-0">
                                    <!-- pagination -->
                                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <b-modal ref="Crear_Licitacion" title="Crear Licitación" hide-footer size="xl" :no-close-on-backdrop="true" @hidden="Volver(1)">
                        <div class="row">
                            <div class="col-12">
                                <form class="form-horizontal" role="form">
                                    <b-form-group id="codigo_id" label-cols-sm="2" label-cols-lg="2" label="ID" label-for="text">
                                        <b-form-input for="text" v-model="Componentes_Licitacion.numero" placeholder="Código..."></b-form-input>
                                    </b-form-group>

                                    <b-form-group id="Nombre_id" label-cols-sm="2" label-cols-lg="2" label="Nombre" label-for="text">
                                        <b-form-input for="text" v-model="Componentes_Licitacion.nombre" placeholder="Nombre..."></b-form-input>
                                    </b-form-group>

                                    <b-form-group id="Rut" label-cols-sm="2" label-cols-lg="2" label="Buscar Cliente" label-for="text">
                                        <b-form-input for="text" v-model="Verificador_cliente" placeholder="Ingrese R.U.T..."></b-form-input>
                                    </b-form-group>

                                    <b-form-group id="Rut" label-cols-sm="2" label-cols-lg="2" label="Cliente" label-for="text" v-if="Mostrar_cliente">
                                        <b-form-input for="text" v-model="Componentes_Licitacion.cliente" placeholder="..." readonly></b-form-input>
                                    </b-form-group>

                                    <b-form-group id="condicion" label-cols-sm="2" label-cols-lg="2" label="Condición" label-for="text">
                                        <b-form-input for="text" v-model="Componentes_Licitacion.condicion" placeholder="Ingrese condición de despacho..." maxlength="40"></b-form-input>
                                    </b-form-group>

                                    <b-form-group id="Observaciones_id" label-cols-sm="2" label-cols-lg="2" label="Observaciones" label-for="text">
                                        <b-form-input for="text" v-model="Componentes_Licitacion.observaciones" placeholder="Esta información es solo para control interno, no se visualizará en la exportación..."></b-form-input>
                                    </b-form-group>

                                    <b-form-group id="numero_id" label-cols-sm="5" label-cols-lg="2" label="Monto licitación:" label-for="number">
                                        <p class="pt-2">{{"$"+Total_licitacion}}</p>
                                    </b-form-group>

                                    <div class="inner-repeater mb-4">
                                        <div class="inner form-group">
                                            <label>Agregar productos :</label>
                                            <div class="inner mb-3 row">
                                                <div class="col-md-6 col-8">
                                                    <input v-model.lazy="phoneData.phone" type="text" class="inner form-control" placeholder="Producto" list="datalistOptions2"/>
                                                    <datalist id="datalistOptions2" >
                                                        <option v-for="item in productos" :key="item.id"> {{item.codigo}} - {{item.nombre}} - {{item.marca}}</option>
                                                    </datalist>
                                                </div>
                                                <div class="col-md-2 col-4">
                                                    <b-form-input id="numero_imput_id" v-model.lazy="phoneData.cantidad"  type="number" name="number" placeholder="Cantidad" min="0"></b-form-input>
                                                </div>
                                                <div class="col-md-2 col-4">
                                                    <b-form-input id="numero_imput_id" v-model.lazy="phoneData.precio"  type="number" name="number" placeholder="Precio" min="0"></b-form-input>
                                                </div>                                               
                                                <div class="col-md-2 col-4">
                                                    <b-button variant="success" block @click="AddPhoneNo()"><i class="bx bx-add-to-queue font-size-16"></i> Agregar</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card">
                                                    <div class="card-body">
                                                        <h4 class="card-title">Productos</h4>
                                                        <div class="row mt-4">
                                                        <div class="col-sm-12 col-md-6">
                                                            <div id="tickets-table_length" class="dataTables_length">
                                                            <label class="d-inline-flex align-items-center">
                                                                Mostrar&nbsp;
                                                                <b-form-select v-model="perPage2" size="sm" :options="pageOptions2"></b-form-select>&nbsp;filas
                                                            </label>
                                                            </div>
                                                        </div>
                                                        <!-- Search -->
                                                        <div class="col-sm-12 col-md-6">
                                                            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                                            <label class="d-inline-flex align-items-center">
                                                                Buscar:
                                                                <b-form-input
                                                                v-model="filter2"
                                                                type="search"
                                                                placeholder="Buscar..."
                                                                class="form-control form-control-sm ml-2"
                                                                ></b-form-input>
                                                            </label>
                                                            </div>
                                                        </div>
                                                        <!-- End search -->
                                                        </div>
                                                        <!-- Table -->
                                                        <div class="table-responsive mb-0">
                                                        <b-table
                                                            :items="productos_tabla"
                                                            :fields="campos_productos"
                                                            responsive="sm"
                                                            :per-page="perPage2"
                                                            :current-page="currentPage2"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :filter="filter2"
                                                            :filter-included-fields="filterOn2"
                                                            @filtered="onFiltered2"
                                                        >
                                                        <template v-slot:cell(precio)="data">
                                                            <p> {{"$"+Intl.NumberFormat("de-DE").format(data.item.precio)}}</p>
                                                        </template>
                                                        <template v-slot:cell(accion)="data">
                                                            <b-button size="sm" variant="danger" @click="editarPhone_create(data.index)">Editar</b-button>
                                                            <b-button size="sm" variant="danger" @click="deletePhone(data.item)">Eliminar</b-button>
                                                        </template>
                                                        </b-table>
                                                        </div>
                                                        <div class="row">
                                                        <div class="col">
                                                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                                            <ul class="pagination pagination-rounded mb-0">
                                                                <!-- pagination -->
                                                                <b-pagination v-model="currentPage2" :total-rows="rows2" :per-page="perPage2"></b-pagination>
                                                            </ul>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>

                                            <!-- <div v-for="(data , index) in phoneData" :key="data.id" class="inner mb-3 row">
                                                <div class="col-md-6 col-8">
                                                    <input v-model.lazy="data.phone" type="text" class="inner form-control" placeholder="Agregar producto" list="datalistOptions2"/>
                                                    <datalist id="datalistOptions2" >
                                                        <option v-for="item in productos" :key="item.id"> {{item.codigo}} - {{item.nombre}} - {{item.marca}}</option>
                                                    </datalist>
                                                </div>
                                                <div class="col-md-2 col-4">
                                                    <b-form-input id="numero_imput_id" v-model.lazy="data.cantidad"  type="number" name="number" placeholder="Cantidad" min="0"></b-form-input>
                                                </div>
                                                <div class="col-md-2 col-4">
                                                    <b-form-input id="numero_imput_id" v-model.lazy="data.precio"  type="number" name="number" placeholder="Precio" min="0"></b-form-input>
                                                </div>                                               
                                                <div class="col-md-2 col-4">
                                                    <b-button variant="danger" block @click="deletePhone(index)"><i class="bx bx-trash font-size-16"></i> Eliminar</b-button>
                                                </div>
                                            </div> -->
                                        </div>
                                        <!-- <input type="button" class="btn btn-info inner" value="Agregar" @click="AddPhoneNo"/> -->
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="text-right">
                            <b-button variant="info" @click="Volver(1)">Volver</b-button>
                            <b-button variant="info" @click="Crear()">Ver vista previa</b-button>
                        </div>
                        <!---------- Modal ---------->
                        <b-modal
                        ref='Editar_Productos_Licitacion'
                        title='Editar producto'
                        hide-footer
                        size='md'
                        @hidden='Hidemodal(1)'
                        :no-close-on-backdrop='false'>
                            <template v-if="EstadoProducto">
                                <b-form-group id="input-group-1" label="Cantidad:" label-for="input-1">
                                    <b-form-input id="input-1" v-model="CantidadModificar" type="number" min="0" required></b-form-input>
                                    <div class="text-left text-danger mt-1">
                                        <p v-if="AlertaCantidad" class="badge position-absolute">
                                            Debe ingresar Cantidad
                                        </p>
                                    </div>
                                </b-form-group>
                                
                                <b-form-group class="mt-1" id="input-group-2" label="Precio:" label-for="input-2">
                                    <b-form-input id="input-2" v-model="CantidadPrecio" type="number" min="0" required></b-form-input>
                                    <div class="text-left text-danger mt-1">
                                        <p v-if="AlertaPrecio" class="badge position-absolute">
                                            Debe ingresar Cantidad
                                        </p>
                                    </div>
                                </b-form-group>
                                <!---------- Botón ---------->
                                <b-button class="mt-2" size='sm' variant='info' @click='actualizar_producto()'>Actualizar</b-button>
                                <b-button class="mt-2" size='sm' variant='info' @click='Hidemodal(1)'>Volver</b-button>
                            </template>
                        </b-modal>
                    </b-modal>
                    <b-modal ref="Modificar_Licitacion" title="Modificar Licitación" hide-footer size="xl" :no-close-on-backdrop="true" @hidden="Volver(2)">
                        <div class="row">
                            <div class="col-12">
                                <form class="form-horizontal" role="form">
                                    <b-form-group id="codigo_id" label-cols-sm="2" label-cols-lg="2" label="ID" label-for="text">
                                        <b-form-input for="text" v-model="Componentes_Licitacion.numero" placeholder="Código..."></b-form-input>
                                    </b-form-group>

                                    <b-form-group id="Nombre_id" label-cols-sm="2" label-cols-lg="2" label="Nombre" label-for="text">
                                        <b-form-input for="text" v-model="Componentes_Licitacion.nombre" placeholder="Nombre..."></b-form-input>
                                    </b-form-group>

                                    <b-form-group id="Rut" label-cols-sm="2" label-cols-lg="2" label="Buscar Cliente" label-for="text">
                                        <b-form-input for="text" v-model="Verificador_cliente_modificado" placeholder="Ingrese R.U.T..."></b-form-input>
                                    </b-form-group>

                                    <b-form-group id="Rut" label-cols-sm="2" label-cols-lg="2" label="Cliente" label-for="text" v-if="Mostrar_cliente_modificado">
                                        <b-form-input for="text" v-model="Componentes_Licitacion.cliente" placeholder="..." readonly></b-form-input>
                                    </b-form-group>

                                    <b-form-group id="condificon" label-cols-sm="2" label-cols-lg="2" label="Condición" label-for="text">
                                        <b-form-input for="text" v-model="Componentes_Licitacion.condicion" placeholder="Ingrese condición de despacho..." maxlength="40"></b-form-input>
                                    </b-form-group>

                                    <b-form-group id="Observaciones_id" label-cols-sm="2" label-cols-lg="2" label="Observaciones" label-for="text">
                                        <b-form-input for="text" v-model="Componentes_Licitacion.observaciones" placeholder="Esta información es solo para control interno, no se visualizará en la exportación..."></b-form-input>
                                    </b-form-group>

                                    <b-form-group id="numero_id" label-cols-sm="5" label-cols-lg="2" label="Monto licitación:" label-for="number">
                                        <p class="pt-2">{{"$"+Total_licitacion}}</p>
                                    </b-form-group>

                                    <div class="inner-repeater mb-4">
                                        <div class="inner form-group">
                                            <label>Agregar productos :</label>
                                            <div class="inner mb-3 row">
                                                <div class="col-md-6 col-8">
                                                    <input v-model.lazy="phoneData.phone" type="text" class="inner form-control" placeholder="Producto" list="datalistOptions2"/>
                                                    <datalist id="datalistOptions2" >
                                                        <option v-for="item in productos" :key="item.id"> {{item.codigo}} - {{item.nombre}} - {{item.marca}}</option>
                                                    </datalist>
                                                </div>
                                                <div class="col-md-2 col-4">
                                                    <b-form-input id="numero_imput_id" v-model.lazy="phoneData.cantidad"  type="number" name="number" placeholder="Cantidad" min="0"></b-form-input>
                                                </div>
                                                <div class="col-md-2 col-4">
                                                    <b-form-input id="numero_imput_id" v-model.lazy="phoneData.precio"  type="number" name="number" placeholder="Precio" min="0"></b-form-input>
                                                </div>                                               
                                                <div class="col-md-2 col-4">
                                                    <b-button variant="success" block @click="AddPhoneNo()"><i class="bx bx-add-to-queue font-size-16"></i> Agregar</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card">
                                                    <div class="card-body">
                                                        <h4 class="card-title">Productos</h4>
                                                        <div class="row mt-4">
                                                        <div class="col-sm-12 col-md-6">
                                                            <div id="tickets-table_length" class="dataTables_length">
                                                            <label class="d-inline-flex align-items-center">
                                                                Mostrar&nbsp;
                                                                <b-form-select v-model="perPage3" size="sm" :options="pageOptions3"></b-form-select>&nbsp;filas
                                                            </label>
                                                            </div>
                                                        </div>
                                                        <!-- Search -->
                                                        <div class="col-sm-12 col-md-6">
                                                            <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                                            <label class="d-inline-flex align-items-center">
                                                                Buscar:
                                                                <b-form-input
                                                                v-model="filter3"
                                                                type="search"
                                                                placeholder="Buscar..."
                                                                class="form-control form-control-sm ml-2"
                                                                ></b-form-input>
                                                            </label>
                                                            </div>
                                                        </div>
                                                        <!-- End search -->
                                                        </div>
                                                        <!-- Table -->
                                                        <div class="table-responsive mb-0">
                                                        <b-table
                                                            :items="productos_tabla"
                                                            :fields="campos_productos"
                                                            responsive="sm"
                                                            :per-page="perPage3"
                                                            :current-page="currentPage3"
                                                            :sort-by.sync="sortBy"
                                                            :sort-desc.sync="sortDesc"
                                                            :filter="filter3"
                                                            :filter-included-fields="filterOn3"
                                                            @filtered="onFiltered3"
                                                        >
                                                        <template v-slot:cell(precio)="data">
                                                            <p> {{"$"+Intl.NumberFormat("de-DE").format(data.item.precio)}}</p>
                                                        </template>
                                                        <template v-slot:cell(accion)="data">
                                                            <b-button size="sm" variant="danger" @click="editarPhone(data.index)">Editar</b-button>
                                                            <b-button size="sm" variant="danger" @click="deletePhone(data.item)">Eliminar</b-button>
                                                        </template>
                                                        </b-table>
                                                        </div>
                                                        <div class="row">
                                                        <div class="col">
                                                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                                            <ul class="pagination pagination-rounded mb-0">
                                                                <!-- pagination -->
                                                                <b-pagination v-model="currentPage3" :total-rows="rows3" :per-page="perPage3" :index="startIndex"></b-pagination>
                                                            </ul>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>

                                            <!-- <div v-for="(data , index) in phoneData" :key="data.id" class="inner mb-3 row">
                                                <div class="col-md-6 col-8">
                                                    <input v-model.lazy="data.phone" type="text" class="inner form-control" placeholder="Agregar producto" list="datalistOptions2"/>
                                                    <datalist id="datalistOptions2" >
                                                        <option v-for="item in productos" :key="item.id"> {{item.codigo}} - {{item.nombre}} - {{item.marca}}</option>
                                                    </datalist>
                                                </div>
                                                <div class="col-md-2 col-4">
                                                    <b-form-input id="numero_imput_id" v-model.lazy="data.cantidad"  type="number" name="number" placeholder="Cantidad" min="0"></b-form-input>
                                                </div>
                                                <div class="col-md-2 col-4">
                                                    <b-form-input id="numero_imput_id" v-model.lazy="data.precio"  type="number" name="number" placeholder="Precio" min="0"></b-form-input>
                                                </div>                                               
                                                <div class="col-md-2 col-4">
                                                    <b-button variant="danger" block @click="deletePhone(index)"><i class="bx bx-trash font-size-16"></i> Eliminar</b-button>
                                                </div>
                                            </div> -->
                                        </div>
                                        <!-- <input type="button" class="btn btn-info inner" value="Agregar" @click="AddPhoneNo"/> -->
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="text-right">
                            <b-button variant="info" @click="Volver(2)">Volver</b-button>
                            <b-button variant="info" @click="Modificar()" :disabled="showbutton_modificarlicitacion">Modificar</b-button>
                        </div>
                        <!---------- Modal ---------->
                        <b-modal
                        ref='Editar_Productos_Licitacion'
                        title='Editar producto'
                        hide-footer
                        size='md'
                        @hidden='Hidemodal(1)'
                        :no-close-on-backdrop='false'>
                            <template v-if="EstadoProducto">
                                <b-form-group id="input-group-1" label="Cantidad:" label-for="input-1">
                                    <b-form-input id="input-1" v-model="CantidadModificar" type="number" min="0" required></b-form-input>
                                    <div class="text-left text-danger mt-1">
                                        <p v-if="AlertaCantidad" class="badge position-absolute">
                                            Debe ingresar Cantidad
                                        </p>
                                    </div>
                                </b-form-group>
                                
                                <b-form-group class="mt-1" id="input-group-2" label="Precio:" label-for="input-2">
                                    <b-form-input id="input-2" v-model="CantidadPrecio" type="number" min="0" required></b-form-input>
                                    <div class="text-left text-danger mt-1">
                                        <p v-if="AlertaPrecio" class="badge position-absolute">
                                            Debe ingresar Cantidad
                                        </p>
                                    </div>
                                </b-form-group>
                                <!---------- Botón ---------->
                                <b-button class="mt-2" size='sm' variant='info' @click='actualizar_producto()'>Actualizar</b-button>
                                <b-button class="mt-2" size='sm' variant='info' @click='Hidemodal(1)'>Volver</b-button>
                            </template>
                        </b-modal>
                    </b-modal>
                    <b-modal ref="Vista_Licitacion" hide-footer size="xl" @hidden="Volver_vistaprevia(1)">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="row">
                                    <div class="col-10 text-center">
                                        <h2>Vista previa licitación </h2>
                                    </div>
                                    <div class="col-2 text-right">
                                        <b-button  variant="info" @click="Volver_vistaprevia(1)">Volver</b-button>
                                        <b-button  variant="info" @click="generateReport()" :disabled="showbutton_crearlicitacion">Crear</b-button>  
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <vue-html2pdf class="d-flex justify-content-center "
                                    :show-layout="true"
                                    :float-layout="false"
                                    :enable-download="true"
                                    :preview-modal="false"
                                    :paginate-elements-by-height="1400"
                                    :filename="'Licitación_'+ Componentes_Licitacion.numero+'_'+ Componentes_Licitacion.cliente"
                                    :pdf-quality="1.5"
                                    :manual-pagination="false"
                                    pdf-format="legal"
                                    pdf-orientation="portrait"
                                    pdf-content-width="800px"
                                    @hasStartedGeneration="hasStartedGeneration()"
                                    @hasGenerated="hasGenerated($event)"
                                    ref="html2Pdf"
                                >
                                    <section slot="pdf-content" class="col-12">
                                        <!-- PDF Content Here -->
                                        <section class="pdf-item pt-5 pl-5 pr-5" id="word">
                                            <div class="row pb-3 ">
                                                <div class="col-md-12 text-center" style="color:#000000">
                                                    <img src="/archivos_base/logo03.png" height="80" alt="logo">     
                                                </div>
                                                <div class="col-md-12">
                                                    <p class="p-0 m-0 font-size-16">ID: {{Componentes_Licitacion.numero}}</p> 
                                                    <p class="p-0 m-0 font-size-16">Nombre: {{Componentes_Licitacion.nombre}}</p> 
                                                    <p class="p-0 m-0 font-size-16">Cliente: {{Componentes_Licitacion.cliente}}</p> 
                                                    <p class="p-0 m-0 font-size-16">Condición: {{Componentes_Licitacion.condicion}}</p>  
                                                </div>
                                            </div>
                                            <div class="pt-3" style="color:#000000" v-for="(item,index) in Componentes_Licitacion.productos" :key="index">
                                                
                                                <p class="font-size-16">Código interno: {{item.codigo}}</p>
                                                <p class="font-size-16">Nombre del producto: {{item.nombre}}</p>
                                                <p v-if="item.marca!='null'" class="font-size-16">Marca: {{item.marca}}</p>
                                                <p v-if="item.fabricacion!='null'" class="font-size-16">Fabricación: {{item.fabricacion}}</p>
                                                <p v-if="item.vencimiento != 'NO APLICA'" class="font-size-16">
                                                            Fecha de vencimiento: {{ formatVencimiento(item.vencimiento)
                                                            }}
                                                        </p>
                                                        <p v-else> Fecha de vencimiento: NO APLICA</p>
                                                <p v-if="item.presentacion!='null'" class="font-size-16">Presentación: {{item.presentacion}}</p>
                                                <p v-if="item.presentacion!='null'" class="font-size-16">Presentación: {{item.presentacion}}</p>
                                                <!--
                                                <p v-if="item.envio!='null'" class="font-size-16">Envío: {{item.envio}}</p>
                                                <p v-if="item.plazoentrega!='null'" class="font-size-16">Plazo de entrega: {{item.plazoentrega}}</p>
                                                -->
                                                <p style="word-break:break-all" v-if="item.caracteristicas!='null'" class="font-size-16">Caracteristicas: {{item.caracteristicas}}</p>
                                                <div class="text-center">
                                                    <img :src="item.foto_dir_resize" alt="imgproducto" class="mb-3"/>
                                                </div> 
                                                <div v-for="i in 30" :key="i">
                                                    <br>
                                                </div>
                                                <div class="html2pdf__page-break"/>
                                            </div>      
                                            <div class="row pb-3 ">
                                                <div class="col-md-12 text-center" style="color:#000000">
                                                    <img src="/archivos_base/logo03.png" height="50" alt="logo"/>
                                                    <p class="p-0 m-0">Dirección: Errázuriz 2066 – Valdivia</p>
                                                    <p class="p-0 m-0">Teléfono: +56 63 2 204385</p>
                                                    <p class="p-0 m-0">Email: contacto@vamproden.cl</p>
                                                    <p class="p-0 m-0">www.vamproden.cl</p>
                                                </div>
                                            </div>
                                        </section> 
                                    </section>
                                </vue-html2pdf>
                                </div>   
                            </div>
                        </div>
                    </b-modal>
                    <b-modal ref="Ver_Licitacion" hide-footer size="xl"  @hidden="Volver_vistaprevia(2)">
                        <div class="row">
                            <div class="col-12">
                                <div class="col-12 text-center">
                                    <h3>Vista licitación </h3>
                                </div>    
                                <div class="col-12 text-center" >
                                    <div class="col-md-6">
                                    </div>
                                    <p>Nombre: {{Componentes_Licitacion.nombre}}</p>
                                    <p>Cliente: {{Componentes_Licitacion.cliente}}</p>
                                    <p>ID licitación: {{Componentes_Licitacion.numero}}</p>
                                    <p v-if="Componentes_Licitacion.monto!=null && Componentes_Licitacion.monto!=''">Monto Licitación: {{Componentes_Licitacion.monto}}</p>
                                    <p v-if="Componentes_Licitacion.monto!=null && Componentes_Licitacion.montoa!=''">Monto adjudicado: {{Componentes_Licitacion.montoadjudicado}}</p>
                                    <p v-if="Componentes_Licitacion.observaciones==null || Componentes_Licitacion.observaciones==''">Observaciones: No registra observaciones.</p>
                                    <p v-if="Componentes_Licitacion.observaciones!=null && Componentes_Licitacion.observaciones!=''">Observaciones: {{Componentes_Licitacion.observaciones}}</p>
                                    <p v-if="Componentes_Licitacion.condicion==null || Componentes_Licitacion.condicion==''">Condición: No registra condición.</p>
                                    <p v-if="Componentes_Licitacion.condicion!=null && Componentes_Licitacion.condicion!=''">Condición: {{Componentes_Licitacion.condicion}}</p>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title">Productos</h4>
                                        <div class="table-responsive">
                                        <table class="table mb-0 table-striped">
                                            <thead>
                                            <tr>
                                                <th>Código</th>
                                                <th>Nombre</th>
                                                <th>Marca</th>
                                                <th>Fabricación</th>
                                                <th>Precio</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="item in Componentes_Licitacion.productos" :key="item.id" >
                                                <td>{{item.codigo}}</td>
                                                <td>{{item.nombre}}</td>
                                                <td v-if="item.marca=='null'">No registra marca</td>
                                                <td v-if="item.marca!='null'">{{item.marca}}</td>
                                                <td v-if="item.fabricacion=='null'">No registra fabricante</td>
                                                <td v-if="item.fabricacion!='null'">{{item.fabricacion}}</td>
                                                <td v-if="item.adjudicado!=1">No adjudicado</td>
                                                <td v-if="item.adjudicado==1">{{item.precio}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-right">
                                <b-button  variant="info" @click="Volver_vistaprevia(2)">Volver</b-button>
                                <b-button  variant="info" @click="Plantillalicitacion(Componentes_Licitacion.numero='',Componentes_Licitacion.productos)">Usar de plantilla</b-button>  
                            </div>
                        </div>
                    </b-modal>
                    <b-modal ref="Adjudicar_Licitacion" title="Ingreso de Adjudicación" hide-footer size="lg" @hidden="Volver(3)">
                        <div class="row">
                            <div class="col-12">  
                                
                                    <p><strong>Nombre: </strong> {{Componentes_Licitacion.nombre}}</p>
                                    <p><strong>Cliente:</strong> {{Componentes_Licitacion.cliente}}</p>
                                    <p><strong>ID licitación:</strong> {{Componentes_Licitacion.numero}}</p>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="card">
                                            <div class="card-body">
                                                <div class="table-responsive">
                                                    <b-form-checkbox   v-model="Select_All"> {{Select_All?'Deseleccionar':'Seleccionar todos'}}</b-form-checkbox>
                                                <table class="table mb-0 striped mt-2" >
                                                    <thead>
                                                    <tr class="table-info">
                                                        <th>Seleccionar</th>
                                                        <th>Nombre</th>
                                                        <th>Cantidad</th>
                                                        <th>Precio</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="item in Componentes_Licitacion.productos" :key="item.id" class="table-active">
                                                        <th scope="row" class="text-center">
                                                        <b-form-checkbox  :value="item" v-model="Productos_Seleccionados"></b-form-checkbox>
                                                        </th>
                                                        <td>{{item.nombre}}</td>
                                                        <td class="text-center"><input type="number" id="typeNumber" class="form-control" min="0" v-model="item.cantidadadjudicacion" :readonly="lectura"/></td>
                                                        <td class="text-center">{{item.precio}}</td>
                                                    </tr>

                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        </div>

                                    <b-form-group id="numero_id" label-cols-sm="5" label-cols-lg="3" label="Monto adjudicado:" label-for="number">
                                        <p class="pt-2">{{Totalprecios}}</p>
                                    </b-form-group>

                                
                            </div>
                            <div class="col-12 text-right">
                                <b-button  :disabled="Boton_Adjudicar" variant="success" @click="Adjudicar(Componentes_Licitacion)">Adjudicar</b-button>  
                                <b-button  :disabled="Boton_No_Adjudicar" variant="danger" @click="No_Adjudicacionmsg()">No Adjudicar</b-button> 
                            </div>
                        </div>
                    </b-modal>
                    <b-modal ref="Ver_Formato" title="Elegir Formato de exportación" hide-footer size="l">
                        <div class="row">
                            <div class="col-12 text-center">  
                                
                                <b-button size="lg" variant="danger" @click="Exportarpdf(data)" :disabled="val_export">PDF</b-button>   
                                <b-button size="lg" variant="danger" @click="Exportarcsv(data)">CSV</b-button>   

                            </div>
                        </div>
                    </b-modal>
                </div>
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <loading-message v-if="isLoading" />
                            <h4 class="card-title">Cargar Licitacion desde CSV </h4>
                            <div>
                                <b-form-file
                                    v-model="fileLicitacion"
                                    :state="Boolean(fileLicitacion)"
                                    placeholder="Selecciona un archivo o arrástralo aquí..."
                                    drop-placeholder="Suelta el archivo aquí..."
                                    @change="onFileLicitacionChange"
                                ></b-form-file>

                                <a @click="uploadFileLicitacion" :disabled="buttonDisabled" class="btn btn-primary">Importar CSV</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  </Layout>
</template>
<style>
.myImg {
      width: 600px;
    }

.modal-header .close {
  display:none;
}


</style>