<script>
import Layout from "./base";
import Footer from "../../components/footer2";
import Swal from "sweetalert2";

export default {
    components: { Layout, Footer, Swal },
    props:{
        regiones:{},
        comunas:{},
    },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                    text: "Utility",
                    href: "/"
                },
                {
                    text: "Starter Page",
                    active: true
                }
            ],
            desc:0,
            envio:null,
            termsAccepted:false,
            datos_envio:{
                email:'',
                tipo:'',
                nombre:'',
                giro:'',
                rut:'',
                direccion:'',
                direccion2:'',
                region:'',
                comuna:'',
                oregion:'',
                ocomuna:'',
                telefono:'',
                email:'',
                otra_nombre:'',
                otra_rut:'',
                otra_direccion:'',
                otra_direccion2:'',
                otra_dir:'',
                otra_region:'',
                otra_comuna:'',
                notas:''
            },
            bool_entregar:false,
            bool_recoger:false,
            selected:'',//modificable la agregue para que no tirara el error
            boton_webpay:false,
            coll1:true,
            coll2:false,
            coll3:false,
            a1:true,
            a2:false,
            a3:false,
            regiones1:[],
            comunas1:[],
            error1:'',
            error2:'',
            completo:false,
            completo1:false,
        };
    },
    computed:{
        comunasFiltradas() {
            if (!this.datos_envio.region) return [];
                return this.comunas1.filter(comuna => comuna.region_id === this.datos_envio.region);
        },
        comunasFiltradas_otras() {
            if (!this.datos_envio.oregion) return [];
                return this.comunas1.filter(comuna => comuna.region_id === this.datos_envio.oregion);
        },
        cartItems(){
            return this.$store.getters.cartItems;
        },
        cartTotal() {
            return this.$store.getters.cartTotal;
        },
        total(){
            let total=0;
            let env=0;
            if(this.envio==null || this.envio=='Por pagar' || this.envio=='A convenir'){
                env = '0';
            }else{
                env = this.envio;
            }
            total= this.cartTotal + parseInt(env) - parseInt(this.desc)
            return total
        },
        bot_entrega(){
            if(this.datos_envio.email==null || this.datos_envio.email==''){
                return true
            }else if(this.datos_envio.comprobante==null || this.datos_envio.comprobante==''){
                return true
            }
            if (this.datos_envio.comprobante=='Boleta') {
                if(this.datos_envio.nombre==null || this.datos_envio.nombre==''){
                    return true
                }
            }else if (this.datos_envio.comprobante=='Factura') {
                if(this.datos_envio.nombre==null || this.datos_envio.nombre==''){
                    return true
                }else if(this.datos_envio.direccion==null || this.datos_envio.direccion==''){
                    return true
                }else if(this.datos_envio.region==null || this.datos_envio.region==''){
                    return true
                }else if(this.datos_envio.comuna==null || this.datos_envio.comuna==''){
                    return true
                }
            }
            if(this.datos_envio.telefono==null || this.datos_envio.telefono==''){
                return true
            }
            return false
        },
    },
    methods:{
        entregar(){
            this.datos_envio.tipo="Domicilio";
            this.bool_entregar=true;
            this.bool_recoger=false;
            this.datos_envio.otra_nombre=this.datos_envio.nombre
            this.datos_envio.otra_rut=this.datos_envio.rut
            this.datos_envio.otra_direccion=this.datos_envio.direccion
            this.datos_envio.otra_direccion2=this.datos_envio.direccion2
            this.datos_envio.otelefono=this.datos_envio.telefono
            this.envio=0;
            //this.envio=this.regiones.filter(region => region.id === this.datos_envio.region)[0].valor;
        },
        recoger(){
            this.datos_envio.tipo="Retiro";
            this.bool_entregar=false;
            this.bool_recoger=true;
            this.envio=0;
        },
        pagar_webpay(){
            this.boton_webpay=true
            let payload={
                cartItems:this.$store.getters.cartItems,
                cartTotal:this.$store.getters.cartTotal,
                envio:this.envio,
                desc:this.desc,
                tipo:'Webpay',
                rut:this.datos_envio.rut,
                telefono:this.datos_envio.telefono,
                email:this.datos_envio.email,
                datos_envio:this.datos_envio,
            }
            console.log(payload)
            axios.post('/api/nueva_orden',payload).then(res=>{
                if (res.data.estado) {
                    window.location.href = `/orden/${res.data.orden.uid}`;
                }else{
                    console.log(res.data.mensaje);
                    this.boton_webpay=false
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    const errors = error.response.data.errors;
                    Object.keys(errors).forEach(key => {
                        errors[key].forEach(message => {
                            // Aquí puedes mostrar cada mensaje de error en tu interfaz de usuario
                            console.error(message);
                        });
                    });
                    this.boton_webpay=false
                } else {
                    console.error(error);
                }
            })
        },
        msg(data){
            Swal.fire({
                //position: "top-end",
                icon: "warning",
                title: "Falta campo por completar",
                text: data,
                showConfirmButton: false,
                timer: 1500
            });
        },
        validar(){
            this.pagar_webpay()
        },
        obtenerCamposFaltantes() {
            let camposFaltantes = [];
            let objeto = Object.keys(this.datos_envio)
            Object.keys(this.datos_envio).forEach(campo => {
                if (this.datos_envio[campo].toString().trim() === '' && campo !== 'otra_dir' && campo !== 'direccion2' && campo !== 'otra_nombre' && campo !== 'otra_rut' && campo !== 'otra_direccion' && campo !== 'otra_direccion2' && campo !== 'otra_dir' && campo !== 'otra_region' && campo !== 'otra_comuna') {
                    camposFaltantes.push(campo.charAt(0).toUpperCase() + campo.slice(1));
                }
            });
            if(this.datos_envio.otra_dir){
                objeto.forEach(campo => {
                    if (this.datos_envio[campo].toString().trim() === '' && campo !== 'otra_dir' && campo !== 'otra_direccion2' && campo !== 'nombre' && campo !== 'rut' && campo !== 'direccion' && campo !== 'direccion2' && campo !== 'region' && campo !== 'comuna') {
                        camposFaltantes.push(campo.charAt(0).toUpperCase() + campo.slice(1));
                    }
                });
            }
            
            return camposFaltantes;
        },
        validarCampos() {
            return (
                this.datos_envio.nombre.trim() !== '' &&
                this.datos_envio.rut.trim() !== '' &&
                this.datos_envio.direccion.trim() !== '' &&
                this.datos_envio.region !== '' &&
                this.datos_envio.comuna !== '' &&
                this.datos_envio.telefono.trim() !== '' &&
                this.datos_envio.email.trim() !== ''
                // Agrega más validaciones aquí para otros campos si es necesario
            );
        },
        change_Region(){
            
            this.envio=this.regiones1.filter(region => region.id === this.datos_envio.region)[0].valor;
            
        },
        change_Comuna(){
            
            this.envio=this.comunas1.filter(comuna => comuna.id === this.datos_envio.comuna)[0].despacho;
            
        },
        ocomuna(){
            console.log('ocomuna()')
            this.envio=this.comunas1.filter(comuna => comuna.id === this.datos_envio.ocomuna)[0].despacho;
            
        },
        oregion(){
            console.log('oregion()')
            this.envio=this.regiones1.filter(region => region.id === this.datos_envio.oregion)[0].valor;
        },
        otra_region1(){
            if(!this.datos_envio.otra_dir){
                this.datos_envio.otra_region='';
            }
            if(this.datos_envio.otra_region!=''){
                this.envio=this.regiones.filter(region => region.id === this.datos_envio.otra_region)[0].valor;
            }/*else{
                this.envio=this.regiones.filter(region => region.id === this.datos_envio.region)[0].valor;
            }*/
        },
        boleta(){
            this.datos_envio.nombre='';
            this.datos_envio.rut='';
            this.datos_envio.giro='';
            this.datos_envio.direccion='';
            this.datos_envio.direccion2='';
            this.datos_envio.region='';
            this.datos_envio.comuna='';
            this.datos_envio.telefono='';
            this.error1='';
        },
        validateKeyPress(event) {
            const charCode = event.charCode;
            // Verifica si la tecla presionada es un número
            if (charCode < 48 || charCode > 57) {
                event.preventDefault(); // Evita que se ingrese el carácter
            }
        },
        validarp1(){
            if(!this.validEmail(this.datos_envio.email)){
                this.msg('Correo electrónico debe ser válido')
                return 
            }
            if(this.datos_envio.comprobante==null || this.datos_envio.comprobante==''){
                this.msg('Boleta o Factura')
                return
            }
            if (this.datos_envio.comprobante=='Boleta') {
                if(this.datos_envio.nombre==null || this.datos_envio.nombre==''){
                    this.msg('Nombre')
                    return
                }else if((this.datos_envio.rut==null || this.datos_envio.rut=='')&& this.error1==''){
                    this.msg('Rut')
                    return 
                }
            }else if (this.datos_envio.comprobante=='Factura') {
                if(this.datos_envio.nombre==null || this.datos_envio.nombre==''){
                    this.msg('Razón social')
                    return
                }else if(this.datos_envio.direccion==null || this.datos_envio.direccion==''){
                    this.msg('Dirección')
                    return
                }else if(this.datos_envio.region==null || this.datos_envio.region==''){
                    this.msg('Región')
                    return
                }else if(this.datos_envio.comuna==null || this.datos_envio.comuna==''){
                    this.msg('Comuna')
                    return
                }
            }
            if(this.datos_envio.telefono==null || this.datos_envio.telefono==''){
                this.msg('Teléfono')
                return
            }
            if(this.datos_envio.telefono.length!=11 ){
                this.msg('Teléfono mal ingresado, deben ser 11 numeros Ej:56911111111')
                return
            }
            this.coll1=false;
            this.a1=false;
            this.coll2=true;
            this.a2=true;
            this.completo1=true;
        },
        validarp2(){
            if (this.datos_envio.tipo =="Domicilio") {
                if(this.datos_envio.otra_nombre==null || this.datos_envio.otra_nombre==''){
                    this.msg('Nombre de quien recibe')
                    return
                }else if(this.datos_envio.oregion==null || this.datos_envio.oregion==''){
                    this.msg('Región de envío')
                    return
                }else if(this.datos_envio.ocomuna==null || this.datos_envio.ocomuna==''){
                    this.msg('Comuna de envío')
                    return
                }else if(this.datos_envio.otra_rut==null || this.datos_envio.otra_rut==''){
                    this.msg('Rut de quien recibe')
                    return
                }else if(this.datos_envio.otra_direccion==null || this.datos_envio.otra_direccion==''){
                    this.msg('Dirección de envío')
                    return
                }
                if(this.cartTotal>=200000){
                    this.envio=0;
                }
                this.coll2=false;
                this.a2=false;
                this.coll3=true;
                this.a3=true;
                this.completo=true;
            }else if(this.datos_envio.tipo =="Retiro"){
                this.coll2=false;
                this.a2=false;
                this.coll3=true;
                this.a3=true;
                this.completo=true;
            }
        },
        accordion1(){
            if(this.completo==true && this.completo1==false){
                if (this.a1 == true) {
                    this.a1 = false
                }else{
                    this.a1 = true
                }
            }else if(this.completo1==true && this.completo== true){
                if (this.a1 == true) {
                    this.a1 = false
                }else{
                    this.a1 = true
                }
            }else{
                if(!this.validEmail(this.datos_envio.email)){
                    this.msg('Correo electrónico debe ser válido')
                    return this.a1 = true;
                }
                if(this.datos_envio.comprobante==null || this.datos_envio.comprobante==''){
                    this.msg('Boleta o Factura')
                    return this.a1 = true;
                }
                if (this.datos_envio.comprobante=='Boleta') {
                    if(this.datos_envio.nombre==null || this.datos_envio.nombre==''){
                        this.msg('Nombre')
                        return this.a1 = true;
                    }/*else if(this.datos_envio.apellidos==null || this.datos_envio.apellidos==''){
                        this.msg('Apellidos')
                        return this.a1 = true;
                    }*/else if(this.datos_envio.rut==null || this.datos_envio.rut==''){
                        this.msg('Rut')
                        return this.a1 = true;
                    }
                }else if (this.datos_envio.comprobante=='Factura') {
                    if(this.datos_envio.nombre==null || this.datos_envio.nombre==''){
                        this.msg('Razón social')
                        return this.a1 = true;
                    }else if(this.datos_envio.rut==null || this.datos_envio.rut==''){
                        this.msg('Rut empresa')
                        return this.a1 = true;
                    }else if(this.datos_envio.direccion==null || this.datos_envio.direccion==''){
                        this.msg('Dirección')
                        return this.a1 = true;
                    }else if(this.datos_envio.region==null || this.datos_envio.region==''){
                        this.msg('Región')
                        return this.a1 = true;
                    }else if(this.datos_envio.comuna==null || this.datos_envio.comuna==''){
                        this.msg('Comuna')
                        return this.a1 = true;
                    }
                }
                if(this.datos_envio.telefono==null || this.datos_envio.telefono==''){
                    this.msg('Teléfono')
                    return this.a1 = true;
                }
                this.completo1 = true;
            }
            //this.a1 = true;
        },
        accordion2(){
            if(this.completo==true){
                if (this.a2 == true) {
                    this.a2 = false
                }else{
                    this.a2 = true
                }
            }else{
                if (this.a1==true) {
                    this.a2 = false;
                    this.coll2=false;
                }else{
                    if (this.datos_envio.tipo =="Domicilio") {
                        if(this.datos_envio.otra_nombre==null || this.datos_envio.otra_nombre==''){
                            this.msg('Nombre de quien recibe')
                            return this.a2 = true;
                        }else if(this.datos_envio.oregion==null || this.datos_envio.oregion==''){
                            this.msg('Región de envío')
                            return this.a2 = true;
                        }else if(this.datos_envio.ocomuna==null || this.datos_envio.ocomuna==''){
                            this.msg('Comuna de envío')
                            return this.a2 = true;
                        }else if(this.datos_envio.otra_rut==null || this.datos_envio.otra_rut==''){
                            this.msg('Rut de quien recibe')
                            return this.a2 = true;
                        }else if(this.datos_envio.otra_direccion==null || this.datos_envio.otra_direccion==''){
                            this.msg('Dirección de envío')
                            return this.a2 = true;
                        }
                        this.completo=true;
                    }else if(this.datos_envio.tipo =="Retiro"){
                        this.coll2=false;
                        this.a2=false;
                        this.coll3=true;
                        this.a3=true;
                        this.completo=true;
                    }
                }
            }
        },
        accordion3(){
            if(this.completo==true){
                if (this.a3 == true) {
                    this.a3 = false
                }else{
                    this.a3 = true
                }
            }else{
                if (this.a1==false && this.a2==false) {
                    this.a3 = !this.a3;
                }else{
                    this.a3=false;
                }
            }
        },
        cleanRut(value) {
            return value.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();
        },
        validateRut1() {
            let rut = this.cleanRut(this.datos_envio.rut);
            let digito_verificador = this.datos_envio.rut.substr(-1);
            rut = rut.substr(0, rut.length - 1);
            let serie = 0;
            let producto = 0;
            do {
                producto += (rut % 10) * ((serie % 6) + 2);
                serie++;
            } while (rut = Math.floor(rut / 10));
            let resto = producto % 11;
            let resultado = 11 - resto;
            if (resultado == 11) {
                resultado = 0;
            } else if (resultado == 10) {
                resultado = 'K';
            }
            if (digito_verificador != resultado || digito_verificador=='') {
                this.errorInRut1();
            } else {
                this.error1 = '';
            }
        },
        errorInRut1() {
            this.error1 = 'RUT Inválido';
            this.$refs.rut1.focus();
        },
        validateRut2() {
            let rut = this.cleanRut(this.datos_envio.otra_rut);
            let digito_verificador = this.datos_envio.otra_rut.substr(-1);
            rut = rut.substr(0, rut.length - 1);
            let serie = 0;
            let producto = 0;
            do {
                producto += (rut % 10) * ((serie % 6) + 2);
                serie++;
            } while (rut = Math.floor(rut / 10));
            let resto = producto % 11;
            let resultado = 11 - resto;
            if (resultado == 11) {
                resultado = 0;
            } else if (resultado == 10) {
                resultado = 'K';
            }
            if (digito_verificador != resultado || digito_verificador=='') {
                this.errorInRut2();
            } else {
                this.error2 = '';
            }
        },
        errorInRut2() {
            this.error2 = 'RUT Inválido';
            this.$refs.rut2.focus();
        },
        validEmail(email) {
            var re = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,4}$/i;
            var test = re.test(email);
            return test;
        },
    },
    created() {
        //this.selected='Estándar';
        this.regiones1 = JSON.parse(this.regiones);
        this.comunas1 = JSON.parse(this.comunas);
        // if(this.direccion!==''){
        //     this.direccion=JSON.parse(this.direccion)
        //     this.datos_envio.direccion=this.direccion.direccion
        //     this.datos_envio.direccion2=this.direccion.direccion2
        //     this.datos_envio.region=this.direccion.region
        //     this.datos_envio.comuna=this.direccion.comuna
        // }
    }
};
</script>
<template>
    <div>
    <!-- Begin page -->
        <div id="layout-wrapper">
            <header id="page-topbar">
                <div class="navbar-header bg-primary">
                    <div class="d-flex">
                        <div class="navbar-brand-box">
                            <a href="/" class="logo logo-dark">
                                <span class="logo-sm">
                                <img src="/archivos_base/logoblanco.png" alt height="40" />
                                </span>
                                <span class="logo-lg">
                                <img src="/archivos_base/logoblanco.png" alt height="40" />
                                </span>
                            </a>
                            <a href="/" class="logo logo-light">
                                <span class="logo-sm">
                                <img src="/archivos_base/logoblanco.png" alt height="22" />
                                </span>
                                <span class="logo-lg">
                                <img src="/archivos_base/logoblanco.png" alt height="40" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                
                <div class="bg-light p-2 mt-4 text-center">
                    <h4 class="p-0 m-0">Envío gratis desde $200.000</h4>
                </div>
            </header>
            
            <!--<HorizontalNavInicio />-->
            <!-- ============================================================== -->
            <!-- Start right Content here -->
            <!-- ============================================================== -->
            <div class="main-content2">
                <div class="page-content2">
                    <div class="container-fluid mt-5">
                        <div class="row">
                            <div class="col-xl-8">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="accordion" role="tablist">
                                            <b-card no-body class="mb-1">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button block variant="primary" @click="accordion1">1 - Datos Personales</b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-1" :visible="coll1" v-model="a1" accordion="my-accordion1" role="tabpanel1">
                                                    <b-card-body>
                                                        <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Email:" label-for="text">
                                                            <b-form-input for="text" value="" v-model="datos_envio.email"></b-form-input>
                                                        </b-form-group>
                                                        <b-form-group v-slot="{ ariaDescribedby }">
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <b-form-radio v-model="datos_envio.comprobante" :aria-describedby="ariaDescribedby" name="some-radios" value="Boleta" @change="boleta">Boleta</b-form-radio>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <b-form-radio v-model="datos_envio.comprobante" :aria-describedby="ariaDescribedby" name="some-radios" value="Factura" @change="boleta">Factura</b-form-radio>
                                                                </div>
                                                            </div>
                                                        </b-form-group>
                                                        <div v-if="datos_envio.comprobante=='Boleta'">
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Nombre Completo" label-for="text">
                                                                <b-form-input for="text" value="" v-model="datos_envio.nombre" required></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Rut " label-for="text">
                                                                <b-form-input ref="rut1" for="text" value="" v-model="datos_envio.rut" placeholder="11111111-1" @blur="validateRut1()"></b-form-input>
                                                                <span class="help-block" v-if="error1 != ''">
                                                                    <strong style="color:red">{{error1}}</strong>
                                                                </span>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Teléfono" label-for="text">
                                                                <b-form-input value="569 " v-model="datos_envio.telefono" placeholder="569 1111 111" @keypress="validateKeyPress"></b-form-input>
                                                            </b-form-group>
                                                            <b-button class="offset-md-3 col-md-6" variant="primary" @click="validarp1" pill block>Continuar</b-button>
                                                        </div>
                                                        <div v-else-if="datos_envio.comprobante=='Factura'">
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Razón Social:" label-for="text">
                                                                <b-form-input for="text" value="" v-model="datos_envio.nombre" required></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Rut de Facturación" label-for="text">
                                                                <b-form-input for="text" value="" v-model="datos_envio.rut" placeholder="11111111-1" @blur="validateRut1()"></b-form-input>
                                                                <span class="help-block" v-if="error1 != ''">
                                                                    <strong style="color:red">{{error1}}</strong>
                                                                </span>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Giro:" label-for="text">
                                                                <b-form-input for="text" value="" v-model="datos_envio.giro"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Dirección" label-for="text">
                                                                <b-form-input for="text" value="" v-model="datos_envio.direccion" placeholder="Número de la casa y nombre de la calle"></b-form-input>
                                                                <b-form-input for="text" value="" v-model="datos_envio.direccion2" placeholder="Apartamento, habitación, etc. (opcional)"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Región" label-for="text">
                                                                <select id="region" class="custom-select" v-model="datos_envio.region" >
                                                                    <option value="" disabled selected>Selecciona una región</option>
                                                                    <option v-for="region in regiones1" :key="region.id" :value="region.id">{{ region.nombre }}</option>
                                                                </select>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Comuna" label-for="text">
                                                                <select id="comuna" class="custom-select" v-model="datos_envio.comuna" v-if="datos_envio.region" @change="change_Comuna()">
                                                                    <option value="" disabled selected>Selecciona una comuna</option>
                                                                    <option v-for="comuna in comunasFiltradas" :key="comuna.id" :value="comuna.id">{{ comuna.nombre }}</option>
                                                                </select>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Teléfono" label-for="text">
                                                                <b-form-input for="text" value="569 " v-model="datos_envio.telefono" placeholder="569 1111 1111"></b-form-input>
                                                            </b-form-group>
                                                            <b-button class="offset-md-3 col-md-6" variant="primary" @click="validarp1" pill block>Continuar</b-button>
                                                        </div>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                            <b-card no-body class="mb-1">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button block variant="primary" @click="accordion2">2 - Despacho - Retiro en tienda</b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-2" v-model="a2" accordion="my-accordion2" role="tabpanel2" >
                                                    <b-card-body>
                                                        <div class="row text-center">
                                                            <div class="offset-md-2 col-md-4">
                                                                <b-button :variant="bool_entregar ? 'primary':'outline-primary'" pill block @click="entregar()" :disabled="bot_entrega">Despacho</b-button>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <b-button :variant="bool_recoger ? 'primary':'outline-primary'" pill block @click="recoger()" :disabled="bot_entrega">Retiro</b-button>
                                                            </div>
                                                            
                                                        </div>
                                                        <div v-show="bool_entregar">                                                            
                                                            <div>
                                                                <h4 class="mt-3">Datos de envío</h4>
                                                                <div>
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Nombre de quien recibe:" label-for="text">
                                                                        <b-form-input for="text" value="" v-model="datos_envio.otra_nombre"></b-form-input>
                                                                    </b-form-group>
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Rut de quien recibe" label-for="text">
                                                                        <b-form-input for="text" value="" v-model="datos_envio.otra_rut" placeholder="11111111-1" @blur="validateRut2()"></b-form-input>
                                                                        <span class="help-block" v-if="error2 != ''">
                                                                            <strong style="color:red">{{error2}}</strong>
                                                                        </span>
                                                                    </b-form-group>
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Región" label-for="text">
                                                                        <select id="region" class="custom-select" v-model="datos_envio.oregion">
                                                                            <option value="" disabled selected>Selecciona una región</option>
                                                                            <option v-for="region in regiones1" :key="region.id" :value="region.id">{{ region.nombre }}</option>
                                                                        </select>
                                                                    </b-form-group>
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Comuna" label-for="text">
                                                                        <select id="comuna" class="custom-select" v-model="datos_envio.ocomuna" v-if="datos_envio.oregion" @change="ocomuna()">
                                                                            <option value="" disabled selected>Selecciona una comuna</option>
                                                                            <option v-for="comuna in comunasFiltradas_otras" :key="comuna.id" :value="comuna.id">{{ comuna.nombre }}</option>
                                                                        </select>
                                                                    </b-form-group>
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Dirección" label-for="text">
                                                                        <b-form-input for="text" value="" v-model="datos_envio.otra_direccion" placeholder="Número de la casa y nombre de la calle"></b-form-input>
                                                                        <b-form-input for="text" value="" v-model="datos_envio.otra_direccion2" placeholder="Apartamento, habitación, etc. (opcional)"></b-form-input>
                                                                    </b-form-group>
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Teléfono" label-for="text">
                                                                        <b-form-input for="text" value="569 " v-model="datos_envio.otelefono" placeholder="569 1111 1111"></b-form-input>
                                                                    </b-form-group>
                                                                    <b-button class="offset-md-3 col-md-6" variant="primary" @click="validarp2" pill block>Continuar</b-button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="text-center mt-4" v-show="bool_recoger">
                                                            <h4 class="mb-4">Punto de Retiro: </h4>
                                                            <img src="/archivos_base/logo.png" alt height="40" />
                                                            <p>Errázuriz 2066, Valdivia</p>
                                                            <b-button class="offset-md-3 col-md-6" variant="primary" @click="validarp2" pill block>Continuar</b-button>
                                                        </div>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                            <b-card no-body class="mb-1">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button block variant="primary" @click="accordion3">3 - Pago</b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-3" v-model="a3" accordion="my-accordion3" role="tabpanel3">
                                                    <b-card-body>
                                                        <b-tabs content-class="mt-3" align="center">
                                                            <b-tab title="First" active>
                                                                <template #title>
                                                                    <div class="text-center">
                                                                        <img src="/archivos_base/webpay.png" alt  height="30"/>
                                                                        <p class="m-0 p-0 font-size-12 text-secondary">Tarjeta Crédito/Débito</p>
                                                                    </div>
                                                                </template>
                                                                <div class="border-2 text-center">
                                                                    <p class="p-2 m-0 font-size-16"><b>Haz click en "Finalizar compra" para ingresar los datos de tu Tarjeta de Débito/Crédito</b></p>
                                                                </div>
                                                            </b-tab>
                                                        </b-tabs>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title mb-3">Resumen de Compra</h4>
                                        <div class="dropdown-divider"></div>
                                        <div class="row">
                                            <div class="col-md-12" v-for="(item,index) in cartItems" :key="index">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <img :src="item.imagen" alt="product-img" title="product-img" class="avatar-xs" />
                                                    </div>
                                                    <div class="col-md-11">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <p>{{item.nombre}}</p>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <input type="text" class="form-control text-center" disabled :value="item.cantidad">
                                                            </div>
                                                            <div class="col-md-9 text-right">
                                                                <p>$ {{(item.precio*item.cantidad).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dropdown-divider"></div>
                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td>Total :</td>
                                                        <td>$ {{ cartTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
                                                    </tr>
                                                    <tr v-if="desc!=0">
                                                        <td>Descuento :</td>
                                                        <td>- $ 0</td>
                                                    </tr>
                                                    <tr >
                                                        <td>Envio :</td>
                                                        <td v-if="envio==null">Sin datos de envio</td>
                                                        <td v-else-if="cartTotal>=200000">Envío Gratis</td>
                                                        <td v-else>${{envio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Total :</th>
                                                        <th>$ {{total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="terms-conditions">
                                                <br>
                                                <p>
                                                Tus datos personales se utilizarán para procesar tu pedido, apoyar tu experiencia en todo este sitio web, y para otros fines descritos en nuestrapolítica de privacidad.
                                                </p>
                                                <div>
                                                <input type="checkbox" id="terms" v-model="termsAccepted" />
                                                <label for="terms">
                                                    He leído y estoy de acuerdo con los <a href="/info/terminoscondiciones" class="terms-link" target="_blank">términos y condiciones</a>.
                                                </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 text-center">
                                                <b-button class="mb-3" variant="primary" pill block @click="validar()" :disabled="!termsAccepted">Finalizar Compra</b-button>
                                                <a href="/resumen_compra" >
                                                    Volver al Carrito
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- container-fluid -->
                </div>
                <!-- End Page-content -->
                <Footer/>
            </div>
        <!-- end main content-->
        </div>
    </div>
</template>