<script>
/**
 * Carousel component
 */
export default {
    props:{
        slides:[],
    },
    data() {
        return {
            slide: 0,
            slide1: 0,
            sliding: null,
        }
    },
    methods:{
        redirectToPage(page){
            if (page!=null) {
                window.location.href = page
            }
        }
    }
}
</script>

<template>
    <div class="col-lg-12">
        <b-carousel controls indicators img-width="1024" img-height="480"> 

            <b-carousel-slide v-for="slide in slides" :key="slide.id" >
                    <template #img>
                        <a :href="'https://'+slide.link" target="_blank" rel="noopener noreferrer" v-if="slide.link!=null">
                        <img
                            class="d-block img-fluid w-100"
                            width="1024"
                            height="480"
                            :src="slide.image"
                            alt="image slot"
                        >
                        </a>
                        <img
                            class="d-block img-fluid w-100"
                            width="1024"
                            height="480"
                            :src="slide.image"
                            alt="image slot"
                            v-else
                        >
                    </template>
            </b-carousel-slide>
        </b-carousel>
    </div>
    <!-- end col -->
</template>