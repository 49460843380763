<!-- LoadingMessage.vue -->
<template>
    <div class="loading-message">
      <p>Cargando...</p>
      <p>espere unos segundos.</p>
      <i class="fas fa-spinner fa-spin"></i>
      <!-- Puedes agregar un spinner o algún otro indicador visual aquí -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'LoadingMessage',
  };
  </script>
  
  <style scoped>
    /* Estilos para el mensaje de carga, puedes personalizar según tus necesidades */
    .loading-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center; /* Para centrar el texto y el ícono dentro del contenedor */
    z-index: 1000; /* Asegura que el mensaje esté siempre en la parte superior */
}

.loading-message h2 {
    margin: 0;
    font-size: 18px;
    color: #333; /* Color del texto */
}

/* Estilos para el spinner de Font Awesome */
.fa-spinner {
    margin-top: 10px;
    font-size: 24px;
    color: #3498db; /* Cambia el color del spinner según el diseño */
    animation: spin 1s linear infinite; /* Hace que el spinner gire continuamente */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
  </style>