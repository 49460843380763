<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import pdf from 'vue-pdf';
import Swal from "sweetalert2";
import LoadingMessage from '../LoadingMessage.vue';
/**
 * Starter component
 */
export default {
    components: { Layout, PageHeader, pdf, Swal, LoadingMessage },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                text: "Utility",
                href: "/"
                },
                {
                text: "Starter Page",
                active: true
                }
            ],
            isLoading:false,
            productos1:[],
            productos_save:[],
            productos2:[],
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 50, 100],
            filter: null,
            filterOn: ['nombre','sku'],
            sortBy: "nombre",
            sortDesc: false,
            fields: [
                { key: "estado",label:"Publicado", sortable: true },
                { key: "destacado",label:"Destacado", sortable: true },
                { key: "nombre",label:"Nombre", sortable: true },
                { key: "sku",label:"Código", sortable: true },
                { key: "stock",label:"Stock", sortable: true },
                { key: "precio1",label:"Venta", sortable: true },
                { key: "precio_normal",label:"Normal", sortable: true },
                { key: "precio_rebajado",label:"Rebajado", sortable: true },
                { key: "marca",label:"Marca", sortable: true },
                { key: "tipo",label:"Tipo", sortable: true },
                { key: "acciones",label:"Acciones", sortable: true },
            ],
            tipo:'',
            tabIndex: 0,
            tabIndex2: 0,
            tabs: [],
            tabCounter: 0,
            producto_data:{
                tipo:'',
                nombre:'',
                sku:'',
                marca:'',
                desccorta:'',
                desclarga:'',
                categoria:[],
                estado_inventario:'',
                stock:'',
                precio_normal:'',
                precio_rebajado:'',
                archivo:'',
                atributo:[],
                variaciones:[],
                todas_variaciones:[],
                galeria:[],
                imagenesgaleria:[],
                image:null,
                pdf:null
            },
            variacion:[],
            marcas:[],
            categorias:[],
            imagenminiatura:'',
            imagenminiatura2:'',
            atributo:{
                id:'',
                nombre:'',
                valores:''
            },
            contador:0,
            boton_var:false,
            files:[],
            producto_editar:{
                tipo:'',
                nombre:'',
                sku:'',
                marca:'',
                desccorta:'',
                desclarga:'',
                categoria:[],
                estado_inventario:'',
                stock:'',
                precio_normal:'',
                precio_rebajado:'',
                archivo:'',
                imagen:'',
                imagen_nueva:null,
                atributo:[],
                variaciones:[],
                galeria:[],
                imagenesgaleria:[],
                pdf:null,
                pdf2:null,
            },
            tabIndex_editar: 0,
            tabIndex_editar2: 0,
            tabCounter_editar:0,
            count:0,
            tampdf:'',
            tampdf2:'',
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.totalRows;
        },
        imagen(){
            return this.imagenminiatura;
        },
        imagen_nueva(){
            return this.imagenminiatura2;
        },
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.productos1.length;
    },
    watch:{
        precio_rebajado(value,Oldvalue){
            if(value>this.producto_data.precio_normal){
                Swal.fire({
                    //position: "top-end",
                    icon: "warning",
                    title: "Valor rebajado no puede ser mayor que el precio normal",
                    //text: data,
                    showConfirmButton: false,
                    timer: 1500
                });
            }else{
                this.producto_data.precio_rebajado=value;
            }
        },
        precio_rebajado_editar(value,Oldvalue){
            if(value>this.producto_editar.precio_normal){
                Swal.fire({
                    //position: "top-end",
                    icon: "warning",
                    title: "Valor rebajado no puede ser mayor que el precio normal",
                    //text: data,
                    showConfirmButton: false,
                    timer: 1500
                });
            }else{
                this.producto_editar.precio_rebajado=value;
            }
        }
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        closeTab(x) {
            /*this.contador--;
            for (let i = 0; i < this.tabs.length; i++) {
                if (this.tabs[i] === x) {
                    this.tabs.splice(i, 1)
                    this.producto_data.atributo.splice(i, 1)
                }
            }*/
            this.contador--;
            for (let i = 0; i < this.producto_data.atributo.length; i++) {
                if (this.producto_data.atributo[i].id === x) {
                    this.producto_data.atributo.splice(i, 1)
                }
            }
        },
        
        newTab() {
            /*this.tabs.push(this.tabCounter++);
            let atributo = {
                id:this.tabCounter,
                nombre:'',
                valores:''
            }
            this.producto_data.atributo.push(atributo);
            this.contador++;*/
            //this.producto_data.atributo.push(this.tabCounter++);
            let atributo = {
                id:this.tabCounter++,
                nombre:'',
                valores:''
            }
            this.producto_data.atributo.push(atributo);
            this.contador++;            
        },
        formatNames(files) {
            this.files=files
            this.files.forEach((file) => {
                file.preview = URL.createObjectURL(file);
            });
            //console.log(files)
            return files.length === 1 ? files[0].name : `${files.length} imagenes seleccionadas`
        },
        handleFileUpload(event) {
            this.producto_data.pdf = event.target.files[0];
            this.tampdf = event.target.files[0].size
            this.tamañopdf(this.tampdf,'nuevo');
        },
        guardar_productos(){
            let formData = new FormData();
            let cat=[];
            this.producto_data.categoria.forEach(element => {
                cat.push(element.id);
            });
            //campos en comun
            //console.log(this.producto_data.galeria);
            this.producto_data.tipo = this.tipo;
            formData.append('tipo', this.producto_data.tipo);
            formData.append('nombre', this.producto_data.nombre);
            formData.append('sku', this.producto_data.sku);
            formData.append('marca', this.producto_data.marca.id);
            formData.append('desccorta', this.producto_data.desccorta);
            formData.append('desclarga', this.producto_data.desclarga);
            formData.append('categoria', cat.toString());
            formData.append('archivo', this.producto_data.archivo);
            formData.append('pdf', this.producto_data.pdf);
            //formData.append('galeria', this.producto_data.galeria);
            /*formData.append('imagenesgaleria', this.producto_data.imagenesgaleria);
            formData.append('totalimagenes',this.producto_data.galeria.length);
            this.producto_data.galeria.forEach((file, index) => {
                formData.append(`imagen_${index}`, file);
            });*/
            this.files.forEach((file, index) => {
                formData.append('images'+index, file);               
            });
            console.log(formData)

            //campos producto simple
            formData.append('estado_inventario', this.producto_data.estado_inventario);
            formData.append('stock', this.producto_data.stock);
            formData.append('precio_normal', this.producto_data.precio_normal);
            formData.append('precio_rebajado', this.producto_data.precio_rebajado);
            //campos producto variable
            formData.append('atributo', JSON.stringify(this.producto_data.atributo));
            formData.append('variaciones', JSON.stringify(this.producto_data.todas_variaciones));
            axios.post('/api/guardar_productos_web',formData).then(res=>{
                if (res.data.estado) {
                    axios.get('/api/get_productos_web').then(res=>{
                        this.productos1 = res.data.productos;
                    })
                    console.log(res.data.mensaje);
                }else{
                    console.log(res.data.mensaje);
                }
            })
        },
        labelselect_cat ({ nombre }) {
            return `${nombre}`
        },
        obtenerImagen(e){
            let file = e.target.files[0];
            this.producto_data.archivo = file;
            //this.producto_data.galeria = file;
            this.cargarImagen(file);
        },
        cargarImagen(file){
            let reader = new FileReader(); 
            reader.onload = (e) =>{
                this.imagenminiatura = e.target.result;
            }
            reader.readAsDataURL(file);
        },
        publicar(id,valor){
            let formData = new FormData();
            formData.append('producto_id', id);
            formData.append('valor', valor);
            axios.post('/api/estado_producto',formData).then(res=>{
                if(res.data.estado){ 
                    console.log(res.data.mensaje);
                }
            });
        },
        destacado(id,valor){
            let formData = new FormData();
            formData.append('producto_id', id);
            formData.append('valor', valor);
            axios.post('/api/destacar_producto',formData).then(res=>{
                if(res.data.estado){ 
                    console.log(res.data.mensaje);
                }
            });
        },
        eliminar(data){
            axios.post('/api/eliminar_productoweb',data).then(res=>{
                if(res.data.estado){ 
                    console.log(res.data.mensaje);
                    axios.get('/api/get_productos_web').then(res=>{
                        this.productos1 = res.data.productos;
                        //console.table(this.productos);
                    })
                }else{
                    console.log(res.data.mensaje);
                }
            });
        },
        generar_variaciones(){
            if(this.producto_data.atributo.length>=1 && (this.producto_data.atributo[0].nombre!='' && this.producto_data.atributo[0].valores!='')){
                this.boton_var=true;
                //generamos variaciones
                this.producto_data.atributo.forEach(element => {
                    element.valores_array = element.valores.split('|').map(str=>str.trim());
                    element.valores = element.valores_array.join('|');
                });
                let variaciones=[];
                const generarCombinaciones = (index, combinacionActual) => {
                    if (index === this.producto_data.atributo.length) {
                        combinacionActual.combinacionString = Object.values(combinacionActual).join('-');
                        variaciones.push(combinacionActual);
                        return;
                    }
                    let atributo = this.producto_data.atributo[index];
                    let valores = atributo.valores_array;
                    for (let valor of valores) {
                        let nuevaCombinacion = { ...combinacionActual };
                        nuevaCombinacion[atributo.id] = valor;
                        generarCombinaciones(index + 1, nuevaCombinacion);
                    }
                };
                generarCombinaciones(0, {});
                this.producto_data.variaciones = variaciones;
                
                this.generar_arreglo();
            }else{
                this.boton_var=false;
                //no se puede generar porq no hay atributos creados
            }
        },
        generar_arreglo(){
            this.producto_data.todas_variaciones=[];
            for (let index = 0; index < this.producto_data.variaciones.length; index++) {
                let vari = {
                    id:index,
                    precio_normal:'',
                    precio_rebajado:'',
                    estado_inventario:'',
                    stock:'',
                    titulo:this.producto_data.variaciones[index].combinacionString,
                    variacion:this.producto_data.variaciones[index]
                }
                this.producto_data.todas_variaciones.push(vari);
            }
        },
        generar_variaciones_editar(){
            if(this.producto_editar.atributo.length>=1 && (this.producto_editar.atributo[0].nombre!='' && this.producto_editar.atributo[0].valores!='')){
                this.boton_var=true;
                //generamos variaciones
                this.producto_editar.atributo.forEach(element => {
                    element.valores_array = element.valores.replace(/\\|$/, '').replace(/\|$/, '').split('|').map(str=>str.trim());
                    element.valores = element.valores_array.join('|');
                });
                let variaciones=[];
                const generarCombinaciones_editar = (index, combinacionActual) => {
                    if (index === this.producto_editar.atributo.length) {
                        combinacionActual.combinacionString = Object.values(combinacionActual).join('-');
                        variaciones.push(combinacionActual);
                        return;
                    }
                    let atributo = this.producto_editar.atributo[index];
                    let valores = atributo.valores_array;
                    console.log(atributo.valores_array)

                    for (let valor of valores) {
                        let nuevaCombinacion = { ...combinacionActual };
                        nuevaCombinacion[atributo.id] = valor;
                        generarCombinaciones_editar(index + 1, nuevaCombinacion);
                    }
                };
                generarCombinaciones_editar(0, {});
                this.producto_editar.variaciones = variaciones;
                
                this.generar_arreglo_editar();
            }else{
                this.boton_var=false;
                //no se puede generar porq no hay atributos creados
            }
        },
        generar_arreglo_editar(){

            this.producto_editar.todas_variaciones=[];
            for (let index = 0; index < this.producto_editar.variaciones.length; index++) {
                let vari = {
                    id:index,
                    precio_normal:'',
                    precio_rebajado:'',
                    estado_inventario:this.producto_editar.variaciones[index].estado_inventario,
                    stock:'',
                    titulo:this.producto_editar.variaciones[index].combinacionString,
                    variacion:this.producto_editar.variaciones[index]
                }
                this.producto_editar.todas_variaciones.push(vari);
                this.tabIndex+=1
                this.tabIndex2+=1
                this.tabIndex_editar+=1
                this.tabIndex_editar2+=1
                this.tabIndex=0
                this.tabIndex2=0
                this.tabIndex_editar=0
                this.tabIndex_editar2=0
                
            }
            // console.log('generar_arreglo_editar')
            // console.log(this.producto_editar.todas_variaciones)
        },
        // formatNames(files) {
        //     return files.length === 1 ? files[0].name : `${files.length} imagenes seleccionadas`
        // },
        obtenerGaleria(e){
            //console.log(this.files)
            // this.files = [];
            // this.producto_data.imagenesgaleria=[];
            // for (let i = 0; i < this.producto_data.galeria.length; i++) {
            //     let file = this.producto_data.galeria[i];
            //     let file1 = e.target.files[i];
            //     this.producto_data.imagenesgaleria.push(file1);
            //     let reader = new FileReader();
            //     reader.onload = (e) => {
            //     this.files.push({
            //         name: file.name,
            //         url: e.target.result
            //     });
            //     };
            //     reader.readAsDataURL(file);
            // }
            /*this.producto_data.imagenesgaleria=[];
            for (let i = 0; i < this.producto_data.galeria.length; i++) {
                const file = this.producto_data.galeria[i];
                this.cargarGaleria(file);
            }*/
            
        },
        /*cargarGaleria(file){
            let reader = new FileReader(); 
            reader.onload = (e) =>{
                this.producto_data.galeria.push(e.target.result);
            }
            reader.readAsDataURL(file);
        },*/
        obtenerImagen_nueva(e){
            let file = e.target.files[0];
            this.producto_editar.archivo = file;
            //this.producto_data.galeria = file;
            this.cargarImagen_nueva(file);
        },
        cargarImagen_nueva(file){
            let reader = new FileReader(); 
            reader.onload = (e) =>{
                this.imagenminiatura2 = e.target.result;
            }
            reader.readAsDataURL(file);
        },
        handleFileUpload2(event) {
            this.producto_editar.pdf2 = event.target.files[0];
            this.tampdf2 = event.target.files[0].size
            this.tamañopdf(this.tampdf2,'editar');
        },
        msg(data){
            Swal.fire({
                //position: "top-end",
                icon: "warning",
                title: "Falta campo por completar",
                text: data,
                showConfirmButton: false,
                timer: 1500
            });
        },
        validaciones(bvModalEvent){
            if (this.producto_data.nombre=='') {
                bvModalEvent.preventDefault()
                this.msg('Nombre del producto');
                return
            }
            if (this.producto_data.sku=='') {
                bvModalEvent.preventDefault()
                this.msg('Código del producto');
                return
            }
            this.skuexiste(this.producto_data.sku,'');
            if (this.producto_data.marca=='') {
                bvModalEvent.preventDefault()
                this.msg('Marcas');
                return
            }
            if (this.producto_data.desccorta=='') {
                bvModalEvent.preventDefault()
                this.msg('Descripción Corta');
                return
            }
            if (this.producto_data.desclarga=='') {
                bvModalEvent.preventDefault()
                this.msg('Descripción Larga');
                return
            }
            if (this.tipo=='Simple') {
                if (this.producto_data.estado_inventario=='' || this.producto_data.estado_inventario==null) {
                    bvModalEvent.preventDefault()
                    this.msg('Estado del inventario');
                    return
                }
                if (this.producto_data.stock=='' && this.producto_data.estado_inventario=='hay') {
                    bvModalEvent.preventDefault()
                    this.msg('Unidades disponibles');
                    return
                }
                if (this.producto_data.precio_normal=='') {
                    bvModalEvent.preventDefault()
                    this.msg('Precio Normal');
                    return
                }
                if (this.producto_data.precio_rebajado>=this.producto_data.precio_normal) {
                    bvModalEvent.preventDefault()
                    this.msg('Precio Rebajado no puede ser mayor que precio normal');
                    return
                }
            }
            if (this.producto_data.imagen=='' || this.producto_data.imagen==null) {
                bvModalEvent.preventDefault()
                this.msg('Imagen Principal');
                return
            }
            if (this.tipo=='Variable') {
                if (this.producto_data.atributo.length==0) {
                    bvModalEvent.preventDefault()
                    this.msg('Al menos un atributo');
                    return
                }
                if ( this.producto_data.atributo.length>=1) {
                    let elemento = 1;
                    this.producto_data.atributo.forEach(element => {
                        if (element.nombre=='') {
                            bvModalEvent.preventDefault()
                            this.msg('Atributo'+elemento+', Falta nombre');
                            return
                        }
                        if (element.valores=='') {
                            bvModalEvent.preventDefault()
                            this.msg('Atributo'+elemento+', Faltan valores');
                            return
                        }
                        elemento++
                    });
                }
                if(this.producto_data.todas_variaciones.length==0){
                    bvModalEvent.preventDefault()
                    this.msg('Generar variaciones');
                    return
                }
                if(this.producto_data.todas_variaciones.length>=1){
                    this.producto_data.todas_variaciones.forEach(element => {
                        if (element.precio_normal=='') {
                            bvModalEvent.preventDefault()
                            this.msg('Variación '+element.titulo+', Precio Normal');
                            return
                        }
                        if (element.estado_inventario=='') {
                            bvModalEvent.preventDefault()
                            this.msg('Variación '+element.titulo+', Estado Inventario');
                            return
                        }
                        if (element.estado_inventario=='hay' && element.stock=='') {
                            bvModalEvent.preventDefault()
                            this.msg('Variación '+element.titulo+', Cantidad unidades');
                            return
                        }
                    });
                }
            }
            if( this.producto_data.pdf!=null){
                if (this.tampdf>2000000) {
                    bvModalEvent.preventDefault()
                    this.msg('Tamaño del pdf muy grande');
                    return
                }
            }
            this.guardar_productos();
        },
        editar(data){
            this.producto_editar.id=data.id;
            this.producto_editar.tipo=data.tipo;
            this.producto_editar.nombre=data.nombre;
            this.producto_editar.sku=data.codigo;
            this.marcas.forEach(element => {
                if (element.id==data.marca) {
                    this.producto_editar.marca=element;
                }
            });
            this.producto_editar.desccorta=data.desccorta;
            this.producto_editar.desclarga=data.desclarga;
            this.producto_editar.categoria=data.cat;
            this.producto_editar.imagen=data.foto_dir;
            this.producto_editar.pdf=data.pdf;
            this.producto_editar.files=data.galeria;
            this.producto_editar.galeria=[];
            this.producto_editar.imagenesgaleria=[];
            if (data.tipo=='Simple') {
                this.producto_editar.estado_inventario=data.estado_inventario;
                this.producto_editar.stock=data.stock;
                this.producto_editar.precio_normal=data.precio_normal;
                this.producto_editar.precio_rebajado=data.precio_rebajado;
            }else if(data.tipo=='Variable'){
                this.producto_editar.estado_inventario='';
                this.producto_editar.stock='';
                this.producto_editar.precio_normal='';
                this.producto_editar.precio_rebajado='';
                this.producto_editar.atributo=[];
                data.atributos.forEach(element => {
                    let atributo = {
                        id:this.tabCounter_editar,
                        nombre:element.nombre,
                        valores:element.valores,
                        valores_array:JSON.parse(element.valores_array),
                        atributo_id:element.id
                    }
                    
                    this.producto_editar.atributo.push(atributo);
                    this.tabCounter_editar++;
                });
                this.producto_editar.variaciones=data.variaciones;
                this.producto_editar.todas_variaciones=[];
                for (let index = 0; index < data.variaciones.length; index++) {
                    //console.log(data.variaciones[index]);
                    let vari = {
                        id:index,
                        precio_normal:data.variaciones[index].precio_normal,
                        precio_rebajado:data.variaciones[index].precio_rebajado,
                        sku:data.variaciones[index].codigo,
                        estado_inventario:data.variaciones[index].estado_inventario,
                        sku:data.variaciones[index].codigo,
                        stock:data.variaciones[index].stock,
                        titulo:data.variaciones[index].titulo,
                        variacion: JSON.parse(data.variaciones[index].variacion),
                        variacion_id:data.variaciones[index].id
                    }

                    this.producto_editar.todas_variaciones.push(vari);
                    //this.producto_editar=this.producto_editar.filter(p=>p.id!=0)
                }
                // console.log('editar');
                // console.log(this.producto_editar.todas_variaciones[0]);
                //this.producto_editar.variaciones=data.variaciones;
            }
            this.$refs['modal-editar'].show();            
        },
        editar_productos(){
            //console.log('editado');
            let formData = new FormData();
            let cat=[];
            this.producto_editar.categoria.forEach(element => {
                cat.push(element.id);
            });
            //campos en comun
            //console.log(this.producto_data.galeria);
            //this.producto_editar.tipo = this.tipo;
            formData.append('id', this.producto_editar.id);
            formData.append('tipo', this.producto_editar.tipo);
            formData.append('nombre', this.producto_editar.nombre);
            formData.append('sku', this.producto_editar.sku);
            formData.append('marca', this.producto_editar.marca.id);
            formData.append('desccorta', this.producto_editar.desccorta);
            formData.append('desclarga', this.producto_editar.desclarga);
            formData.append('categoria', cat.toString());
            formData.append('archivo', this.producto_editar.archivo);
            formData.append('pdf', this.producto_editar.pdf2);
            //formData.append('galeria', this.producto_data.galeria);
            /*formData.append('imagenesgaleria', this.producto_data.imagenesgaleria);
            formData.append('totalimagenes',this.producto_data.galeria.length);
            this.producto_data.galeria.forEach((file, index) => {
                formData.append(`imagen_${index}`, file);
            });*/
            this.files.forEach((file, index) => {
                formData.append('images'+index, file);               
            });
            //console.log(formData)

            //campos producto simple
            formData.append('estado_inventario', this.producto_editar.estado_inventario);
            formData.append('stock', this.producto_editar.stock);
            formData.append('precio_normal', this.producto_editar.precio_normal);
            formData.append('precio_rebajado', this.producto_editar.precio_rebajado);
            //campos producto variable
            formData.append('atributo', JSON.stringify(this.producto_editar.atributo));
            formData.append('variaciones', JSON.stringify(this.producto_editar.todas_variaciones));
            axios.post('/api/editar_productos_web',formData).then(res=>{
                if (res.data.estado) {
                    axios.get('/api/get_productos_web').then(res=>{
                        this.productos1 = res.data.productos;
                    })
                    //console.log(res.data.mensaje);
                    Swal.fire({
                        //position: "top-end",
                        icon: "success",
                        title: "Producto editado",//"Falta campo por completar",
                        //text: data,
                        showConfirmButton: false,
                        timer: 1500
                    });
                }else{
                    Swal.fire({
                        //position: "top-end",
                        icon: "warning",
                        title: res.data.mensaje,//"Falta campo por completar",
                        //text: data,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    //console.log(res.data.mensaje);
                }
            })
        },
        closeTab_editar(x) {
            /*this.contador--;
            for (let i = 0; i < this.tabs.length; i++) {
                if (this.tabs[i] === x) {
                    this.tabs.splice(i, 1)
                    this.producto_data.atributo.splice(i, 1)
                }
            }*/
            this.contador--;
            for (let i = 0; i < this.producto_editar.atributo.length; i++) {
                if (this.producto_editar.atributo[i].id === x) {
                    this.producto_editar.atributo.splice(i, 1)
                }
            }
        },
        newTab_editar() {
            let atributo = {
                id:this.tabCounter_editar++,
                nombre:'',
                valores:''
            }
            this.producto_editar.atributo.push(atributo);
            this.contador++;            
        },
        validaciones_editar(bvModalEvent){
            console.log(this.producto_editar);
            if (this.producto_editar.nombre=='') {
                bvModalEvent.preventDefault()
                this.msg('Nombre del producto');
                return
            }
            if (this.producto_editar.sku=='') {
                bvModalEvent.preventDefault()
                this.msg('Código del producto');
                return
            }
            this.skuexiste(this.producto_editar.sku);
            if (this.producto_editar.marca=='') {
                bvModalEvent.preventDefault()
                this.msg('Marcas');
                return
            }
            if (this.producto_editar.desccorta=='') {
                bvModalEvent.preventDefault()
                this.msg('Descripción Corta');
                return
            }
            if (this.producto_editar.desclarga=='') {
                bvModalEvent.preventDefault()
                this.msg('Descripción Larga');
                return
            }
            if(this.producto_editar.tipo =='Simple'){
                if (this.producto_editar.estado_inventario=='') {
                    bvModalEvent.preventDefault()
                    this.msg('Estado del inventario');
                    return
                }
                if (this.producto_editar.estado_inventario=='hay') {
                    if (this.producto_editar.stock=='' || this.producto_editar.stock=='null' || this.producto_editar.stock==null) {
                        bvModalEvent.preventDefault()
                        this.msg('Unidades disponibles');
                        return
                    }
                }
                if (this.producto_editar.precio_normal=='') {
                    bvModalEvent.preventDefault()
                    this.msg('Precio Normal');
                    return
                }
            }
            if (this.producto_editar.imagen=='') {
                bvModalEvent.preventDefault()
                this.msg('Imagen Principal');
                return
            }
            if (this.producto_editar.tipo=='Variable'){
                if ( this.producto_editar.atributo.length>=1) {
                    let elemento = 1;
                    this.producto_editar.atributo.forEach(element => {
                        if (element.nombre=='') {
                            bvModalEvent.preventDefault()
                            this.msg('Atributo'+elemento+', Falta nombre');
                            return
                        }else if (element.valores=='') {
                            bvModalEvent.preventDefault()
                            this.msg('Atributo'+elemento+', Faltan valores');
                            return
                        }
                        elemento++
                    });
                }
                if (this.producto_editar.atributo.length==0) {
                    bvModalEvent.preventDefault()
                    this.msg('Al menos un atributo');
                    return
                }
                if( this.producto_editar.todas_variaciones.length==0){
                    bvModalEvent.preventDefault()
                    this.msg('Generar variaciones');
                    return
                }
                if( this.producto_editar.todas_variaciones.length>=1){
                    this.producto_editar.todas_variaciones.forEach(element => {
                        if (element.precio_normal=='') {
                            bvModalEvent.preventDefault()
                            this.msg('Variación '+element.titulo+', Precio Normal');
                            return
                        }
                        if (element.estado_inventario=='') {
                            bvModalEvent.preventDefault()
                            this.msg('Variación '+element.titulo+', Estado Inventario');
                            return
                        }if (element.estado_inventario=='hay' && element.stock=='') {
                            bvModalEvent.preventDefault()
                            this.msg('Variación '+element.titulo+', Cantidad unidades');
                            return
                        }
                    });
                }
            }
            if( this.producto_editar.pdf2!=null){
                if (this.tampdf2>2000000) {
                    bvModalEvent.preventDefault()
                    this.msg('Tamaño del pdf muy grande');
                    return
                }
            }
            this.editar_productos();
        },
        eliminar_imagen(id){
            axios.get('/api/eliminar/'+id).then(res=>{
                this.producto_editar.files = res.data.galeria;
                this.count++;
                console.log('Foto eliminada');
            })
        },
        cambiartab(){
            //this.producto_editar.todas_variaciones[this.tabIndex_editar2].estado_inventario=this.producto_editar.variaciones.estado_inventario;
        }, 
        validacionprecio(pnormal, prebajado){
            if(prebajado>pnormal){
                Swal.fire({
                    //position: "top-end",
                    icon: "warning",
                    title: "Valor rebajado no puede ser mayor que el precio normal",
                    //text: data,
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        },
        skuexiste(sku,id){
            //console.log('sku modificado');
            let productoporsku = this.productos1.filter(producto =>
                producto.codigo==sku && (id=='' || this.producto_editar.id!= producto.id)
            );
            //console.log(productoporsku);
            if(productoporsku.length>=1){
                Swal.fire({
                    //position: "top-end",
                    icon: "warning",
                    title: "SKU ya existe en otro producto",
                    //text: data,
                    showConfirmButton: false,
                    timer: 1500
                });
            }
        },
        tamañopdf(pdf,tipo){
            if (pdf>2000000) {
                Swal.fire({
                    //position: "top-end",
                    icon: "warning",
                    title: "Tamaño del archivo muy grande",
                    //text: data,
                    showConfirmButton: false,
                    timer: 1500
                });
                /*if(tipo=='nuevo'){
                    this.producto_data.pdf = null
                }else if(tipo=='editar'){
                    this.producto_editar.pdf2 =null
                }*/

            }
        },
        editPrice(item,tipo){
            console.log(this.currentPage)
            if(tipo=='normal'){
                item.editing=true
            }else if(tipo=='rebajado'){
                item.editing2=true
            }else if(tipo=='stock'){
                item.editing3=true
            }
            this.productos1=this.productos1.filter(p=>p.id!=0)
        },
        savePrice(item) {
            this.isLoading=true
            console.log('save')
            item.precio_rebajado=item.precio_rebajado==''|| item.precio_rebajado==0?null:item.precio_rebajado
            item.precio_normal=item.precio_normal!=null?item.precio_normal.replace(/\D/g, ''):null;
            item.precio_rebajado=item.precio_rebajado!=null?item.precio_rebajado.replace(/\D/g, ''):null;
            item.stock=item.stock!=null?item.stock.replace(/\D/g, ''):null;
            axios.post('/api/editar_productos_web_precio',item).then(res=>{
                if (res.data.estado) {
                    this.$toasted.success('Modificado!');
                }else{
                    this.$toasted.error('Error al Modificar!');
                }
                this.productos1=res.data.productos
                item.editing=false
                item.editing2=false
                item.editing3=false
                this.isLoading=false
            })
        },
        cancelEdit(item) {
            this.isLoading=true
            axios.get('/api/get_productos_web').then(res=>{
                this.productos1 = res.data.productos;
                this.totalRows = this.productos1.length;
                this.$toasted.error('Edición cancelada!');
                this.isLoading=false
                //console.table(this.productos);
            })
        }
    },
    created(){
        this.isLoading=true
        axios.get('/api/get_productos_web').then(res=>{
            this.productos1 = res.data.productos;
            this.totalRows = this.productos1.length;
            //console.table(this.productos);
            this.isLoading=false
        })
        axios.get('/api/get_marcas').then(res=>{
            this.marcas = res.data.marcas;
            //console.table(this.productos);
        })
        axios.get('/api/get_categorias').then(res=>{
            this.categorias = res.data.categorias;
            //console.table(this.productos);
        })
        let atributo = {
            id:this.tabCounter++,
            nombre:'',
            valores:''
        }
        this.producto_data.atributo.push(atributo);
        this.contador++;
    }
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="text-center">
                <loading-message  v-if="isLoading" />
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Productos</h4>
                        <div class="row mt-4">
                            <div class="col-md-12 text-right mb-3">
                                <b-button variant="primary" size="sm" v-b-modal.modal-1>Cargar producto</b-button>
                                <b-modal id="modal-1" title="Cargar Producto" size="lg" ok-title="Agregar" cancel-title="Cancelar" @ok="validaciones">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Tipo de Producto" label-for="text" >
                                                <select class="form-control" v-model="tipo">
                                                    <option value="">Selecciona una opción</option>
                                                    <option value="Simple">Producto Simple</option>
                                                    <option value="Variable">Producto Variable</option>
                                                </select> 
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <b-tabs content-class="mt-3" v-if="tipo!=''">
                                        <b-tab title="Producto">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Nombre:" label-for="text">
                                                        <b-form-input for="text" placeholder="Nombre del producto" v-model="producto_data.nombre"></b-form-input>
                                                    </b-form-group>
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="SKU:" label-for="text">
                                                        <b-form-input for="text" placeholder="Código del producto" v-model="producto_data.sku" @blur="skuexiste(producto_data.sku, '')"></b-form-input>
                                                    </b-form-group>
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Marca:" label-for="text" >
                                                        <multiselect
                                                            v-model="producto_data.marca"
                                                            :options="marcas"
                                                            :custom-label="labelselect_cat" 
                                                            placeholder="Seleccionar categorias" 
                                                            selectLabel="Presiona para seleccionar"
                                                            selectedLabel="Seleccionado"
                                                            deselectLabel="Presiona para remover"
                                                            track-by="nombre"
                                                            >
                                                            <template v-slot:noOptions>
                                                                <p>No hay opciones</p>
                                                            </template>
                                                            <template v-slot:noResult>
                                                                <p>No se encuentra coincidencia</p>
                                                            </template>
                                                        </multiselect>
                                                        <!-- <select class="form-control" v-model="producto_data.marca_id">
                                                            <option :value="''">Seleccion marca</option>
                                                            <option value="sin marca">Sin marca</option>
                                                            <option v-for="item in marcas" :key="item.id">{{item.nombre}} </option>
                                                        </select>  -->
                                                    </b-form-group>
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Descripción corta:" label-for="text">
                                                        <b-form-input for="text" placeholder="Descripción corta del producto" v-model="producto_data.desccorta"></b-form-input>
                                                    </b-form-group>
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Descripción Larga:" label-for="text">
                                                        <b-form-textarea for="text" placeholder="Descripción larga del producto" v-model="producto_data.desclarga" rows="3" max-rows="6"></b-form-textarea>
                                                    </b-form-group>
                                                    <b-form-group :id="producto_data.nombre +'existencia'" label-cols-sm="2" label-cols-lg="2" label="Estado del inventario:" label-for="text" v-if="tipo=='Simple'">
                                                        <select id="estado-inventario" v-model="producto_data.estado_inventario" class="form-control">
                                                                    <option disabled value="">Seleccione un estado</option>
                                                                    <option value="hay">Hay Existencias</option>
                                                                    <option value="no hay">Sin Existencias</option>
                                                                    <option value="reservar">Se puede reservar</option>
                                                                </select>
                                                    </b-form-group>
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Cantidad disponibles:" label-for="text" v-if="tipo=='Simple' && producto_data.estado_inventario=='hay'">
                                                        <b-form-input for="text" placeholder="Unidades disponibles para comprar" v-model="producto_data.stock"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Precio" v-if="tipo=='Simple'">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Precio Normal:" label-for="text">
                                                        <b-form-input for="text" placeholder="" v-model="producto_data.precio_normal"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-6">
                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Precio Rebajado:" label-for="text">
                                                        <b-form-input for="text" placeholder="" v-model="producto_data.precio_rebajado"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Imagen Principal" >
                                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Cargar Imagen:" label-for="text">
                                                <b-form-file
                                                v-model="producto_data.imagen"
                                                accept="image/*"
                                                placeholder="Elegir Archivo"
                                                drop-placeholder="Drop file here..."
                                                @change="obtenerImagen"
                                                ></b-form-file>
                                                <p class="p-0 m-0">Máximo 2mb</p>
                                            </b-form-group>
                                            <div v-if="producto_data.imagen!=null">
                                                <p>Previsualización de imagen</p>
                                                <div class="text-center" >
                                                    <figure>
                                                        <img :src="imagen" height="200" alt="Imagen del Taller">
                                                    </figure>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Categoria" >
                                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="4" label="Categorias :" label-for="text">
                                                <!-- <multiselect v-model="producto_data.categoria" :options="categorias" :custom-label="labelselect_cat" placeholder="Select one" label="nombre" track-by="nombre"></multiselect> -->
                                                <multiselect
                                                    v-model="producto_data.categoria"
                                                    :options="categorias" 
                                                    :multiple="true"
                                                    :custom-label="labelselect_cat" 
                                                    placeholder="Seleccionar categorias" 
                                                    selectLabel="Presiona para seleccionar"
                                                    selectedLabel="Seleccionado"
                                                    deselectLabel="Presiona para remover"
                                                    track-by="nombre"
                                                    >
                                                    <template v-slot:noOptions>
                                                        <p>No hay opciones</p>
                                                    </template>
                                                    <template v-slot:noResult>
                                                        <p>No se encuentra coincidencia</p>
                                                    </template>
                                                </multiselect>
                                            </b-form-group>
                                        </b-tab>
                                        <b-tab title="Atributos" v-if="tipo=='Variable'" >
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <b-card no-body>
                                                        <b-tabs card v-model="tabIndex">
                                                            <!-- Render Tabs, supply a unique `key` to each tab -->
                                                            <b-tab v-for="(i,index) in producto_data.atributo" :key="'dyn-tab-' + index" :title="'Atributo ' + (index+1)">
                                                                <div class="row">
                                                                    <div class="col-md-12 mb-3">
                                                                        <b-button size="sm" variant="danger" class="float-right" @click="closeTab(index)">Cerrar</b-button>
                                                                    </div>
                                                                    <div class="col-md-5">
                                                                        <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Nombre:" label-for="text">
                                                                            <b-form-input for="text" placeholder="Por ejemplo, la talla o el color" v-model="i.nombre"></b-form-input>
                                                                        </b-form-group>
                                                                    </div>
                                                                    <div class="col-md-7">
                                                                        <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Valores:" label-for="text">
                                                                            <b-form-textarea for="text" placeholder="Introduce las opciones entre las que podrán elegir los clientes, por ejemplo, 'Azul' o 'Talla M'. Utiliza '|' para separar las distintas opciones" v-model="i.valores" rows="3" max-rows="6"></b-form-textarea>
                                                                        </b-form-group>
                                                                    </div>
                                                                </div>
                                                            </b-tab>
                                                            <!-- New Tab Button (Using tabs-end slot) -->
                                                            <template #tabs-end>
                                                                <b-nav-item role="presentation" @click.prevent="newTab" href="#"><b>Agregar Nuevo</b></b-nav-item>
                                                            </template>
                                                            <!-- Render this if no tabs -->
                                                            <template #empty>
                                                                <div class="text-center text-muted">
                                                                No hay atributos agregados<br>
                                                                Agregue uno presionando "Agregar nuevo"
                                                                </div>
                                                            </template>
                                                        </b-tabs>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Variaciones" v-if="tipo=='Variable'">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <b-button variant="primary" size="sm" class="float-right" @click="generar_variaciones">Generar Variaciones</b-button>
                                                </div>
                                                <div class="col-md-12" v-if="boton_var">
                                                    <b-tabs content-class="mt-3" pills card vertical v-model="tabIndex2">
                                                        <b-tab :title="variacion.titulo" v-for="(variacion,index) in producto_data.todas_variaciones" :key="index" >
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Precio Normal:" label-for="text">
                                                                        <b-form-input for="text" placeholder="" v-model="variacion.precio_normal"></b-form-input>
                                                                    </b-form-group>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Precio Rebajado:" label-for="text">
                                                                        <b-form-input for="text" placeholder="" v-model="variacion.precio_rebajado" @change="validacion(variacion.precio_normal,variacion.precio_rebajado)"></b-form-input>
                                                                    </b-form-group>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="SKU:" label-for="text">
                                                                        <b-form-input for="text" placeholder="" v-model="variacion.sku"></b-form-input>
                                                                    </b-form-group>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <b-form-group :id="'estado_inventario'+ variacion.titulo" label-cols-sm="6" label-cols-lg="6" label="Estado del inventario:" label-for="text" class="text-left">
                                                                        <select id="estado-inventario" v-model="variacion.estado_inventario" class="form-control">
                                                                            <option disabled value="">Seleccione un estado</option>
                                                                            <option value="hay">Hay Existencias</option>
                                                                            <option value="no hay">Sin Existencias</option>
                                                                            <option value="reservar">Se puede reservar</option>
                                                                        </select>
                                                                    </b-form-group>
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Cantidad unidades:" label-for="text" v-if="variacion.estado_inventario=='hay'">
                                                                        <b-form-input for="text" placeholder="" v-model="variacion.stock"></b-form-input>
                                                                    </b-form-group>
                                                                </div>
                                                            </div>
                                                        </b-tab>
                                                    </b-tabs>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Galeria" >
                                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Cargar Imagen:" label-for="text">
                                                <b-form-file
                                                multiple
                                                v-model="producto_data.galeria"
                                                accept="image/*"
                                                placeholder="Elegir Archivo"
                                                drop-placeholder="Drop file here..."
                                                @change="obtenerGaleria"
                                                :file-name-formatter="formatNames"
                                                browse-text="Examinar"
                                                ></b-form-file>
                                                <p class="p-0 m-0">Máximo 2mb cada imagen.</p>
                                            </b-form-group>
                                            <div v-if="files.length > 0">
                                                <h2>Imágenes seleccionadas:</h2>
                                                <div v-for="(file, index) in files" :key="index">
                                                    <img :src="file.preview" alt="Imagen seleccionada" style="max-width: 200px; max-height: 200px; margin: 10px;">
                                                </div>
                                            </div>
                                            <!-- <div v-if="producto_data.imagenesgaleria.length > 0">
                                                <p>Previsualización de imagen</p>
                                                <div v-for="(preview, index) in producto_data.imagenesgaleria" :key="index">
                                                    <img :src="preview" alt="Preview" style="max-width: 100%;" />
                                                </div>
                                            </div> -->
                                        </b-tab>
                                        <b-tab title="PDF Informativo">
                                            <p>Puede subir pdf con información sobre el producto.</p>
                                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Archivo:" label-for="text">
                                                <b-form-file
                                                accept=".pdf"
                                                placeholder="Elegir Archivo"
                                                drop-placeholder="Drop file here..."
                                                @change="handleFileUpload"
                                                ></b-form-file>
                                                <p class="p-0 m-0">Máximo 2mb</p>
                                            </b-form-group>
                                            
                                        </b-tab>
                                    </b-tabs>
                                </b-modal>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Mostrar&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;productos
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Buscar:
                                        <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Buscar..."
                                        class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                :items="productos1"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                            >
                                <template v-slot:cell(estado)="data">
                                    <b-form-group id="example text" label-for="text">
                                        <b-form-checkbox :id="'estado'+data.item.id" v-model="data.item.estado" :name="'estado'+data.item.id" :value="1" :unchecked-value="0" @change="publicar(data.item.id, data.item.estado)"> </b-form-checkbox>
                                    </b-form-group>
                                </template>
                                <template v-slot:cell(destacado)="data">
                                    <b-form-group id="example text" label-for="text">
                                        <b-form-checkbox :id="'destacado'+data.item.id" v-model="data.item.destacado" :name="'destacado'+data.item.id" :value="1" :unchecked-value="0" @change="destacado(data.item.id, data.item.destacado)"> </b-form-checkbox>
                                    </b-form-group>
                                </template>
                                <!-- <template v-slot:cell(stock)="data">
                                    <p v-if="data.item.tipo=='Simple'">{{ data.item.stock }}</p>
                                    <p v-if="data.item.tipo=='Variable'">{{ data.item.suma_stock }}</p>
                                </template> -->
                                <template v-slot:cell(marca)="data">
                                    {{ data.item.nombre_marca }}
                                </template>
                                <template v-slot:cell(sku)="data">
                                    {{ data.item.codigo }}
                                </template>
                                <template v-slot:cell(precio1)="data">
                                    {{ data.item.precio_activo ? data.item.precio_activo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 'No disponible' }}
                                </template>
                                <template v-slot:cell(stock)="data">
                                    <div v-if="data.item.tipo=='Simple'">
                                        <div @dblclick="editPrice(data.item,'stock')">
                                            <div v-if="data.item.editing3">
                                            <b-form-input for="text"
                                                v-model="data.item.stock"
                                                @keyup.enter="savePrice(data.item)"
                                                @keyup.esc="cancelEdit(data.item)"
                                            />
                                            <b-button @click="savePrice(data.item)" size="sm" class="btn btn-success"> <i class="bx bx-save"></i></b-button>
                                            <b-button @click="cancelEdit(data.item)" size="sm" class="btn btn-danger"> <i class="bx bx-x"></i></b-button>
                                            </div>
                                            <div v-else>
                                                <p v-if="data.item.tipo=='Simple'">{{ data.item.stock }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p>{{ data.item.suma_stock }}</p>
                                    </div>
                                </template>
                                <template v-slot:cell(precio_normal)="data">
                                    <div v-if="data.item.tipo=='Simple'">
                                        <div @dblclick="editPrice(data.item,'normal')">
                                            <div v-if="data.item.editing">
                                            <b-form-input for="text"
                                                v-model="data.item.precio_normal"
                                                @keyup.enter="savePrice(data.item)"
                                                @keyup.esc="cancelEdit(data.item)"
                                            />
                                            <b-button @click="savePrice(data.item)" size="sm" class="btn btn-success"> <i class="bx bx-save"></i></b-button>
                                            <b-button @click="cancelEdit(data.item)" size="sm" class="btn btn-danger"> <i class="bx bx-x"></i></b-button>
                                            </div>
                                            <div v-else>
                                            {{ data.item.precio_normal?data.item.precio_normal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."): '-----' }}
                                            <!-- <b-button @click="editPrice(data.item,'normal')" size="sm" variant="primary" v-if="data.item.tipo=='Simple'"> <i class="bx bx-pencil"></i></b-button> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p>{{ data.item.precio_normal?data.item.precio_normal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."): '-----' }}</p>
                                    </div>
                                </template>
                                <template v-slot:cell(precio_rebajado)="data">
                                    <div v-if="data.item.tipo=='Simple'">
                                        <div @dblclick="editPrice(data.item,'rebajado')">
                                            <div v-if="data.item.editing2">
                                            <b-form-input for="text"
                                                v-model="data.item.precio_rebajado"
                                                @keyup.enter="savePrice(data.item)"
                                                @keyup.esc="cancelEdit(data.item)"
                                            />
                                            <b-button @click="savePrice(data.item)" size="sm" class="btn btn-success"> <i class="bx bx-save"></i></b-button>
                                            <b-button @click="cancelEdit(data.item)" size="sm" class="btn btn-danger"> <i class="bx bx-x"></i></b-button>
                                            </div>
                                            <div v-else>
                                            {{ data.item.precio_rebajado? data.item.precio_rebajado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."): '-----' }}
                                            <!-- <b-button @click="editPrice(data.item,'rebajado')" size="sm" variant="primary" v-if="data.item.tipo=='Simple'"> <i class="bx bx-pencil"></i></b-button> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p>{{ data.item.precio_rebajado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</p>
                                    </div>
                                </template>
                                <template v-slot:cell(acciones)="data">
                                    <b-button size="sm" variant="danger" @click="eliminar(data.item)"><i class="bx bx-trash"></i></b-button>
                                    <b-button size="sm" variant="primary" @click="editar(data.item)"><i class="bx bx-pencil"></i></b-button>
                                </template>
                            </b-table>
                        </div>
                        <b-modal ref="modal-editar" :title="producto_editar.nombre" :size="producto_editar.tipo=='Simple'?'lg':'xl'" ok-title="Editar" cancel-title="Cancelar" @ok="validaciones_editar">
                            <b-tabs content-class="mt-3" v-if="producto_editar.tipo!=''">
                                <b-tab title="Producto">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Nombre:" label-for="text">
                                                <b-form-input for="text" placeholder="Nombre del producto" v-model="producto_editar.nombre"></b-form-input>
                                            </b-form-group>
                                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="SKU:" label-for="text">
                                                <b-form-input for="text" placeholder="Código del producto" v-model="producto_editar.sku" @blur="skuexiste(producto_editar.sku)"></b-form-input>
                                            </b-form-group>
                                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Marca:" label-for="text" >
                                                <multiselect
                                                    v-model="producto_editar.marca"
                                                    :options="marcas"
                                                    :custom-label="labelselect_cat" 
                                                    placeholder="Seleccionar categorias" 
                                                    selectLabel="Presiona para seleccionar"
                                                    selectedLabel="Seleccionado"
                                                    deselectLabel="Presiona para remover"
                                                    track-by="nombre"
                                                    >
                                                    <template v-slot:noOptions>
                                                        <p>No hay opciones</p>
                                                    </template>
                                                    <template v-slot:noResult>
                                                        <p>No se encuentra coincidencia</p>
                                                    </template>
                                                </multiselect>
                                            </b-form-group>
                                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Descripción corta:" label-for="text">
                                                <b-form-input for="text" placeholder="Descripción corta del producto" v-model="producto_editar.desccorta"></b-form-input>
                                            </b-form-group>
                                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Descripción Larga:" label-for="text">
                                                <b-form-textarea for="text" placeholder="Descripción larga del producto" v-model="producto_editar.desclarga" rows="3" max-rows="6"></b-form-textarea>
                                            </b-form-group>
                                            <b-form-group :id="producto_editar.nombre +'existencia'" label-cols-sm="2" label-cols-lg="2" label="Estado del inventario:" label-for="text" v-if="producto_editar.tipo=='Simple'">
                                                <select id="estado-inventario" v-model="producto_editar.estado_inventario" class="form-control">
                                                    <option disabled value="">Seleccione un estado</option>
                                                    <option value="hay">Hay Existencias</option>
                                                    <option value="no hay">Sin Existencias</option>
                                                    <option value="reservar">Se puede reservar</option>
                                                </select>
                                            </b-form-group>
                                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Cantidad disponibles:" label-for="text" v-if="producto_editar.tipo=='Simple' && producto_editar.estado_inventario=='hay'">
                                                <b-form-input for="text" placeholder="Unidades disponibles para comprar" v-model="producto_editar.stock"></b-form-input>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Precio" v-if="producto_editar.tipo=='Simple'">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Precio Normal:" label-for="text">
                                                <b-form-input for="text" placeholder="" v-model="producto_editar.precio_normal"></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-6">
                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Precio Rebajado:" label-for="text">
                                                <b-form-input for="text" placeholder="" v-model="producto_editar.precio_rebajado"></b-form-input>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Imagen Principal" >
                                    <b-button v-b-toggle.collapse-imagennueva variant="primary">Cambiar Imagen</b-button>
                                    <b-collapse id="collapse-imagennueva">
                                        <b-form-group id="example text" class="mt-3" label-cols-sm="2" label-cols-lg="2" label="Cargar Imagen:" label-for="text">
                                            <b-form-file
                                            v-model="producto_editar.imagen_nueva"
                                            accept="image/*"
                                            placeholder="Elegir Archivo"
                                            drop-placeholder="Drop file here..."
                                            @change="obtenerImagen_nueva"
                                            ></b-form-file>
                                            <p class="p-0 m-0">Máximo 2mb</p>
                                        </b-form-group>
                                        <div v-if="producto_editar.imagen_nueva!=null">
                                            <p>Previsualización de imagen</p>
                                            <div class="text-center" >
                                                <figure>
                                                    <img :src="imagen_nueva" height="200" alt="Imagen del Taller">
                                                </figure>
                                            </div>
                                        </div>
                                    </b-collapse>
                                    <div class="mt-3" v-if="producto_editar.imagen_nueva==null">
                                        <div class="text-center" >
                                            <figure>
                                                <img :src="producto_editar.imagen" height="200" alt="Imagen del Taller">
                                            </figure>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Categoria" >
                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="4" label="Categorias :" label-for="text">
                                        <multiselect
                                            v-model="producto_editar.categoria"
                                            :options="categorias" 
                                            :multiple="true"
                                            :custom-label="labelselect_cat" 
                                            placeholder="Seleccionar categorias" 
                                            selectLabel="Presiona para seleccionar"
                                            selectedLabel="Seleccionado"
                                            deselectLabel="Presiona para remover"
                                            track-by="nombre"
                                            >
                                            <template v-slot:noOptions>
                                                <p>No hay opciones</p>
                                            </template>
                                            <template v-slot:noResult>
                                                <p>No se encuentra coincidencia</p>
                                            </template>
                                        </multiselect>
                                    </b-form-group>
                                </b-tab>
                                <b-tab title="Atributos" v-if="producto_editar.tipo=='Variable'" >
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-card no-body>
                                                <b-tabs card v-model="tabIndex_editar">
                                                    <b-tab v-for="(atri,index1) in producto_editar.atributo" :key="'dyn-tab-' + index1" :title="'Atributo ' + (index1+1)">
                                                        <div class="row">
                                                            <div class="col-md-12 mb-3">
                                                                <b-button size="sm" variant="danger" class="float-right" @click="closeTab_editar(index1)">Cerrar</b-button>
                                                            </div>
                                                            <div class="col-md-5">
                                                                <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Nombre:" label-for="text">
                                                                    <b-form-input for="text" placeholder="Por ejemplo, la talla o el color" v-model="atri.nombre"></b-form-input>
                                                                </b-form-group>
                                                            </div>
                                                            <div class="col-md-7">
                                                                <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Valores:" label-for="text">
                                                                    <b-form-textarea for="text" placeholder="Introduce las opciones entre las que podrán elegir los clientes, por ejemplo, 'Azul' o 'Talla M'. Utiliza '|' para separar las distintas opciones" v-model="atri.valores" rows="3" max-rows="6"></b-form-textarea>
                                                                </b-form-group>
                                                            </div>
                                                        </div>
                                                    </b-tab>
                                                    <template #tabs-end>
                                                        <b-nav-item role="presentation" @click.prevent="newTab_editar" href="#"><b>Agregar Nuevo</b></b-nav-item>
                                                    </template>
                                                    <template #empty>
                                                        <div class="text-center text-muted">
                                                        No hay atributos agregados<br>
                                                        Agregue uno presionando "Agregar nuevo"
                                                        </div>
                                                    </template>
                                                </b-tabs>
                                            </b-card>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Variaciones" v-if="producto_editar.tipo=='Variable'">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-button variant="primary" size="sm" class="float-right" @click="generar_variaciones_editar">Generar Variaciones</b-button>
                                        </div>
                                        <div class="col-md-12">
                                            <b-tabs content-class="mt-3" pills card vertical v-model="tabIndex_editar2" @changed="cambiartab">
                                                <b-tab :title="variacion.titulo" v-for="(variacion,index) in producto_editar.todas_variaciones" :key="index" >
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Precio Normal:" label-for="text">
                                                                <b-form-input for="text" placeholder="" v-model="variacion.precio_normal"></b-form-input>
                                                            </b-form-group>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Precio Rebajado:" label-for="text">
                                                                <b-form-input for="text" placeholder="" v-model="variacion.precio_rebajado" @change="validacion(variacion.precio_normal,variacion.precio_rebajado)"></b-form-input>
                                                            </b-form-group>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="SKU:" label-for="text">
                                                                <b-form-input for="text" placeholder="" v-model="variacion.sku"></b-form-input>
                                                            </b-form-group>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <b-form-group :id="index+ 'text'" label-cols-sm="6" label-cols-lg="6" label="Estado del inventario:" label-for="text" class="text-left">
                                                                <select id="estado-inventario" v-model="variacion.estado_inventario" class="form-control">
                                                                    <option disabled value="">Seleccione un estado</option>
                                                                    <option value="hay">Hay Existencias</option>
                                                                    <option value="no hay">Sin Existencias</option>
                                                                    <option value="reservar">Se puede reservar</option>
                                                                </select>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Cantidad unidades:" label-for="text">
                                                                <b-form-input for="text" placeholder="" v-model="variacion.stock"></b-form-input>
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                </b-tab>
                                            </b-tabs>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Galeria" >
                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Cargar Imagen:" label-for="text">
                                        <b-form-file
                                        multiple
                                        v-model="producto_editar.galeria"
                                        accept="image/*"
                                        placeholder="Elegir Archivo"
                                        drop-placeholder="Drop file here..."
                                        @change="obtenerGaleria"
                                        :file-name-formatter="formatNames"
                                        browse-text="Examinar"
                                        ></b-form-file>
                                        <p class="p-0 m-0">Máximo 2mb cada imagen.</p>
                                    </b-form-group>
                                    <div :key="count" v-if="producto_editar.files.length > 0">
                                        <h2>Imágenes seleccionadas:</h2>
                                        <div v-for="(file, index) in producto_editar.files" :key="index">
                                            <img :src="file.direccion" alt="Imagen seleccionada" style="max-width: 200px; max-height: 200px; margin: 10px;">
                                            <b-button variant="danger" @click="eliminar_imagen(file.id)"><i class="bx bx-trash"></i></b-button>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="PDF Informativo">
                                    <b-button v-b-toggle.collapse-pdf variant="primary">
                                        <p class="p-0 m-0" v-if="producto_editar.pdf==null">Agregar PDF</p>
                                        <p class="p-0 m-0" v-else>Cambiar PDF</p>
                                    </b-button>
                                    <b-collapse id="collapse-pdf">
                                        <p class="mt-3">Puede subir pdf con información sobre el producto.</p>
                                        <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Archivo:" label-for="text">
                                            <b-form-file
                                            accept=".pdf"
                                            placeholder="Elegir Archivo"
                                            drop-placeholder="Drop file here..."
                                            @change="handleFileUpload2"
                                            ></b-form-file>
                                            <p class="p-0 m-0">Máximo 2mb</p>
                                        </b-form-group>
                                    </b-collapse>
                                    <div class="mt-3 text-center" v-if="producto_editar.pdf2==null && producto_editar.pdf!=null">
                                        <a :href="producto_editar.pdf" target="_blank"><i class="far fa-file-pdf font-size-24"></i> Ver Instructivo</a>
                                    </div>
                                    <!-- <pdf :src="producto_editar.pdf" v-if="producto_editar.pdf!=null">Ver Instructivo</pdf> -->
                                </b-tab>
                            </b-tabs>
                        </b-modal>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>