<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import producto from "../pagina/producto.vue";
import Swal from "sweetalert2";

/**
 * Starter component
 */
export default {
    components: { Layout, PageHeader, Swal },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                text: "Utility",
                href: "/"
                },
                {
                text: "Starter Page",
                active: true
                }
            ],
            status:[],
            marcas:[],
            categorias:[],
            descuento:'',
            productos:[],
            categoria:[],
            category:[],
            marca:[],
            marcary:[],
            seleccionado:[],
            presionado:false,
            esta:false,
            count:0,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "id",
            sortDesc: false,
            fields: [
                { key: "sel", label:"Seleccionar",sortable: true },
                { key: "n", label:"Nº", sortable: true },
                { key: "prod", label:"Producto", sortable: true },
                { key: "marca", label:"Marca", sortable: true },
                { key: "categoria", label:"Categoria", sortable: true },
                { key: "pnormal", label:"Precio Normal", sortable: true },
                { key: "prebajado", label:"Precio Rebajado", sortable: true },
                { key: "descuento", label:"Descuento", sortable: true }
            ]
        };
    },
    computed:{
        cat(){
            if (!this.status.includes('categoria')) {
                this.categoria=[];
                this.category=[];
                return true;
            }
            else{
                return false
            }
        },
        mar(){
            if (!this.status.includes('marca')) {
                this.marca=[];
                this.marcary=[];
                return true;
            }
            else{
                return false
            }
        },
        rows() {
            return this.productos.length;
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods:{
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
        },
        labelselect_cat ({ nombre }) {
            return `${nombre}`
        },
        filtro(){
            this.productos=[];
            this.category=[];
            this.marcary=[];
            this.categoria.forEach(element => {
                this.category.push(element.id);
            });
            this.marca.forEach(element => {
                this.marcary.push(element.id);
            });
            console.log(this.category);
            console.log(this.marcary);
            if(this.category.length!=0 && this.marcary.length==0){
                let formData = new FormData();
                formData.append('tipo', 'categoria');
                formData.append('ids', this.category);
                axios.post('/api/get_productos_web_filtro',formData).then(res=>{
                    this.productos = res.data.productos;
                });
            }else if(this.category.length==0 && this.marcary.length!=0){
                let formData = new FormData();
                formData.append('tipo', 'marca');
                formData.append('ids', this.marcary);
                axios.post('/api/get_productos_web_filtro',formData).then(res=>{
                    this.productos = res.data.productos;
                });
            }else if(this.category.length!=0 && this.marcary.length!=0){
                let formData = new FormData();
                formData.append('tipo', 'categoriamarca');
                formData.append('ids', '');
                formData.append('categorias', this.category);
                formData.append('marcas', this.marcary);
                axios.post('/api/get_productos_web_filtro',formData).then(res=>{
                    this.productos = res.data.productos;
                });
            }else if(this.category.length==0 && this.marcary.length==0){
                this.productos=[];
            }
        },
        seleccionartodo(){
            if (this.presionado==false) {
                this.productos.forEach(element => {
                    if (this.descuento!='') {
                        element.ndescuento=this.descuento;
                        element.descuento=1;
                    }else{
                        element.ndescuento='';
                        element.descuento=1;
                    }
                    this.presionado=true;
                });
            }
            else{
                this.productos.forEach(element => {
                    element.ndescuento='';
                    element.descuento=0;
                    this.presionado=false;
                });
            }
            
        },
        arreglo(){
            this.productos.forEach(element => {
                if (element.descuento=='1') {
                    var c = {
                        id:element.id,
                        descuento:element.ndescuento
                    }
                    if (this.seleccionado.length==0) {
                        this.seleccionado.push(c);
                    }
                    else{
                        this.esta=false;
                        this.seleccionado.forEach(element2 => {
                            if (element2.id== element.id) {
                                element2.descuento=element.ndescuento;
                                this.esta=true;
                            }
                        });
                        if (this.esta==false) {
                            this.seleccionado.push(c);
                        }
                    }
                }
            });
            return this.seleccionado;
        },
        apli_descuento(){
            this.productos.forEach(element => {
                if(element.descuento=='1'){
                    element.ndescuento=this.descuento;
                    this.cambiar_precio(element);
                }
            });
        },
        unselect(item){
            if(item.descuento==0){
                axios.get('/api/eliminar_descuento/'+item.id).then(res=>{
                })
            }
        },
        guardar(){
            let se=this.arreglo();
            let payload = {
                seleccionado: this.seleccionado
            };
            axios.post('/api/guardar_descuentos', payload).then(res=>{
                Swal.fire({
                    //position: "top-end",
                    icon: "success",
                    title: "Descuentos guardados",
                    //text: data,
                    showConfirmButton: false,
                    timer: 1500
                });
                //console.table(this.productos);
            })
        },
        cambiar_precio(producto){
            if (producto.ndescuento>=100) {
                Swal.fire({
                    //position: "top-end",
                    icon: "warning",
                    title: "Descuento no puede ser mas de 99%",
                    //text: data,
                    showConfirmButton: false,
                    timer: 1500
                });
                producto.ndescuento=99;
            }
            if (producto.ndescuento!='') {
                producto.precio_rebajado=Math.round(producto.precio_normal-(producto.precio_normal*(producto.ndescuento/100)));
            }else{
                producto.precio_rebajado=''
            }
        }
    },
    created(){
        axios.get('/api/get_marcas_producpubl').then(res=>{
            this.marcas = res.data.marcas;
            //console.table(this.productos);
        })
        axios.get('/api/get_categorias_productos').then(res=>{
            this.categorias = res.data.categorias;
            //console.table(this.productos);
        })
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">Agregar descuentos</h4>
                <div class="row">
                    <div class="col-md-3">
                        <div class="card bg-soft-primary">
                            <div class="card-body">
                                <h4>Filtro</h4>
                                <b-form-checkbox id="checkbox-1" v-model="status" name="checkbox-1" value="categoria">Categoria</b-form-checkbox>
                                <b-form-group id="example text" label-cols-sm="12" label-cols-lg="12" label-for="text">
                                    <multiselect
                                        v-model="categoria"
                                        :options="categorias"
                                        :multiple="true"
                                        :custom-label="labelselect_cat" 
                                        placeholder="Seleccionar categorias" 
                                        selectLabel="Presiona para seleccionar"
                                        selectedLabel="Seleccionado"
                                        deselectLabel="Presiona para remover"
                                        track-by="nombre"
                                        :disabled="cat"
                                        >
                                        <template v-slot:noOptions>
                                            <p>No hay opciones</p>
                                        </template>
                                        <template v-slot:noResult>
                                            <p>No se encuentra coincidencia</p>
                                        </template>
                                    </multiselect>
                                </b-form-group>
                                <b-form-checkbox id="checkbox-2" v-model="status" name="checkbox-2" value="marca">Marca</b-form-checkbox>
                                <b-form-group id="example text" label-cols-sm="12" label-cols-lg="12" label-for="text">
                                    <multiselect
                                        v-model="marca"
                                        :options="marcas"
                                        :multiple="true"
                                        :custom-label="labelselect_cat" 
                                        placeholder="Seleccionar marcas" 
                                        selectLabel="Presiona para seleccionar"
                                        selectedLabel="Seleccionado"
                                        deselectLabel="Presiona para remover"
                                        track-by="nombre"
                                        :disabled="mar"
                                        >
                                        <template v-slot:noOptions>
                                            <p>No hay opciones</p>
                                        </template>
                                        <template v-slot:noResult>
                                            <p>No se encuentra coincidencia</p>
                                        </template>
                                    </multiselect>
                                </b-form-group>
                                <b-button class="mt-3" variant="primary" block @click="filtro()">Filtrar</b-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="card bg-white" v-if="productos.length>0">
                            <div class="card-body">
                                <div class="row pb-3">
                                    <div class="col-md-3">
                                        <b-button variant="primary" block @click="seleccionartodo">{{presionado==false?'Seleccionar todo':'No seleccionar todo'}}</b-button>
                                    </div>
                                    <div class="col-md-7">
                                        <b-form-group id="example text" label-cols-sm="2" label-cols-lg="4" label="Aplicar % Descuento:" label-for="text" >
                                            <select class="form-control" v-model="descuento" @change="apli_descuento">
                                                <option value="">Selecciona una opción</option>
                                                <option value="0">0%</option>
                                                <option value="5">5%</option>
                                                <option value="10">10%</option>
                                                <option value="15">15%</option>
                                                <option value="20">20%</option>
                                                <option value="25">25%</option>
                                                <option value="30">30%</option>
                                                <option value="35">35%</option>
                                                <option value="40">40%</option>
                                                <option value="45">45%</option>
                                                <option value="50">50%</option>
                                                <option value="55">55%</option>
                                                <option value="60">60%</option>
                                                <option value="65">65%</option>
                                                <option value="70">70%</option>
                                                <option value="75">75%</option>
                                                <option value="80">80%</option>
                                                <option value="85">85%</option>
                                                <option value="90">90%</option>
                                                <option value="95">95%</option>
                                                <option value="99">99%</option>
                                            </select> 
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-2">
                                        <b-button variant="success" block @click="guardar()">Guardar todo</b-button>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-sm-12 col-md-6">
                                        <div id="tickets-table_length" class="dataTables_length">
                                            <label class="d-inline-flex align-items-center">
                                                Mostrar&nbsp;
                                                <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;productos
                                            </label>
                                        </div>
                                    </div>
                                    <!-- Search -->
                                    <div class="col-sm-12 col-md-6">
                                        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                            <label class="d-inline-flex align-items-center">
                                                Buscar:
                                                <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Buscar..."
                                                class="form-control form-control-sm ml-2"
                                                ></b-form-input>
                                            </label>
                                        </div>
                                    </div>
                                    <!-- End search -->
                                </div>
                                <!-- Table -->
                                <div class="table-responsive mb-0">
                                    <b-table
                                        :items="productos"
                                        :fields="fields"
                                        responsive="sm"
                                        :per-page="perPage"
                                        :current-page="currentPage"
                                        :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDesc"
                                        :filter="filter"
                                        :filter-included-fields="filterOn"
                                        @filtered="onFiltered"
                                    >
                                        <template v-slot:cell(sel)="data">
                                            <b-form-checkbox :id="'prod-'+data.index" :name="'prod-'+data.index" v-model="data.item.descuento" :value="'1'" :unchecked-value="'0'" @change="unselect(data.item)"></b-form-checkbox>
                                        </template>
                                        <template v-slot:cell(n)="data">
                                            {{ data.index+1 }}
                                        </template>
                                        <template v-slot:cell(prod)="data">
                                            {{data.item.nombre}}
                                        </template>
                                        <template v-slot:cell(marca)="data">
                                            {{data.item.nombre_marca}}
                                        </template>
                                        <template v-slot:cell(categoria)="data">
                                            <p v-for="(cate,index1) in data.item.cat" :key="index1">{{cate.nombre}}</p>
                                        </template>
                                        <template v-slot:cell(pnormal)="data">
                                            <p v-if="data.item.precio_normal!=null">{{ data.item.precio_normal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</p>
                                        </template>
                                        <template v-slot:cell(prebajado)="data">
                                            <p v-if="data.item.precio_rebajado!=null">{{ data.item.precio_rebajado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</p>
                                        </template>
                                        <template v-slot:cell(descuento)="data">
                                            <b-form-input for="text" placeholder="Descuento" v-model="data.item.ndescuento" @change="cambiar_precio(data.item)"></b-form-input>
                                        </template>
                                    </b-table>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="dataTables_paginate paging_simple_numbers float-right">
                                            <ul class="pagination pagination-rounded mb-0">
                                                <!-- pagination -->
                                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="table-responsive" >
                                    <table class="table table-hover table-bordered mb-0" :key="count">
                                        <thead>
                                            <tr>
                                                <th>Seleccionar</th>
                                                <th>Nº</th>
                                                <th>Productos</th>
                                                <th>Marca</th>
                                                <th>Categoria</th>
                                                <th>Precio Normal</th>
                                                <th>Precio Rebajado</th>
                                                <th>Descuento</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(producto,index) in productos" :key="index+count">
                                                <td>
                                                    <b-form-checkbox :id="'prod-'+index" :name="'prod-'+index" v-model="producto.descuento" :value="'1'" :unchecked-value="'0'"></b-form-checkbox>
                                                </td>
                                                <th scope="row">{{ index+1 }}</th>
                                                <td>{{producto.nombre}}</td>
                                                <td>{{producto.nombre_marca}}</td>
                                                <td>
                                                    <p v-for="(cate,index) in producto.cat" :key="index">{{cate.nombre}}</p>
                                                </td>
                                                <td>{{ producto.precio_normal }}</td>
                                                <td>{{ producto.precio_rebajado }}</td>
                                                <td>
                                                    <b-form-input for="text" placeholder="Descuento" v-model="producto.ndescuento" @change="cambiar_precio(producto)"></b-form-input>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> -->
                            </div>
                        </div>
                        <div v-else>
                            <div class="row">
                                <div class="col-md-12 text-center" v-if="status.length>0" >
                                    <h4 class="text-primary" v-if="category.length>0 || marcary.length>0">No se encontraron productos</h4>
                                </div>
                                <div class="col-md-12 text-center" v-if="status.length==0">
                                    <h4 class="text-primary">Seleccione Categorias y/o Marcas para filtrar los productos</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>