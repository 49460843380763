<script>
//simport Layout from "../../layouts/main";
import Layout from "../pagina/base";
import PageHeader from "../../components/page-header";

export default {
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Términos y Condiciones",
            items: [
                {
                    text: "Home",
                    href: "/"
                },
                {
                    text: "Términos y Condiciones",
                    active: true
                }
            ],

        };
    },
    methods: {
        goBack() {
      this.$router.go(-1);
    }
    },
    mounted() {
    },
};
</script>
<template>
    <Layout>
    <b-container class="mt-5">
      <b-row>
        <b-col>
            
          <b-card>
            <b-card-header class="text-center">
              <h3>Términos y Condiciones</h3>
            </b-card-header>
            <b-card-body>
                <div>
                    <div class="card-body">
                        <b-accordion>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-1 variant="link">
                                    PRIMERO: GENERALIDADES
                                </b-button>
                                </b-card-header>
                                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <p class="card-text">
                                        Este documento regula los términos y condiciones bajo los cuales Ud. tiene derecho a acceder y usar los servicios del sitio web www.vamproden.cl y de cualquier información, texto, video u otro material comunicado en el sitio web. <br>
                                        <br>
                                        En el sitio podrá usar, sin costo, el software y las aplicaciones para equipos móviles que le permitan navegar, visitar, comparar y si lo desea, adquirir los bienes o servicios que se exhiben aquí.<br>
                                        <br>
                                        Le recomendamos que lea detenidamente estas condiciones e imprima o guarde una copia de las mismas en la unidad de disco local para su información.<br>
                                        <br>
                                        Estos Términos y Condiciones serán aplicados y se entenderán incorporados en cada uno de los contratos que celebre con Vamproden SPA por medio de este sitio web.<br>
                                        <br>
                                        El uso de el sitio, la aplicación de estos Términos y Condiciones, los actos que ejecute y los contratos que celebre por medio de este sitio web, se encuentran sujetos y sometidos a las leyes de la República de Chile y en especial a la ley 19.496 de protección de los derechos de los consumidores. Vamproden SPA. por lo tanto, aplicará estrictamente todos los beneficios, garantías y derechos reconocidos en favor de los consumidores en la ley 19.496.<br>
                                        <br>
                                        Vamproden SPA por lo tanto, aplicará estrictamente todos los beneficios, garantías y derechos reconocidos en favor de los consumidores en la ley 19.496. Además, Vamproden Spa adhiere en todas sus partes al Código de Buenas Prácticas para el Comercio Electrónico de la Cámara de Comercio de Santiago, el cual se encuentra disponible en el siguiente link. CÓDIGO DE BUENAS PRÁCTICAS PARA EL COMERCIO ELECTRÓNICO<br>
                                    </p>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-2 variant="link">
                                    SEGUNDO: COMUNICACIONES
                                </b-button>
                                </b-card-header>
                                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <p class="card-text">
                                        Vamproden  se obliga a que en caso de enviarle información publicitaria o promocional, por correo electrónico, ésta contendrá al menos la siguiente información:<br>
                                        <br>
                                        1. Identificación del mensaje, que lo haga reconocible como publicidad o comunicación promocional, en el campo de asunto del mensaje.<br>
                                        <br>
                                        2. Existencia del derecho del consumidor o usuario a solicitar el cese de envíos de publicidad de ese tipo por  Vamproden y la obligación de ésta a suspender dichos envíos.<br>
                                        <br>
                                        3. Un procedimiento simple y eficaz para que el consumidor pueda hacer esa solicitud, indicando una dirección electrónica para estos efectos.<br>
                                        <br>
                                        4. La Identificación del anunciante con su denominación comercial.<br>
                                        <br>
                                        Tratándose de publicidad teaser, se cumplirá con las obligaciones señaladas precedentemente en la medida que se identifique la agencia que realiza la misma.<br>
                                        <br>
                                        Vamproden cesará el envío de los mensajes publicitarios o promocionales por correo electrónico u otros medios de comunicación individual equivalentes, a toda persona que hubiere solicitado expresamente la suspensión de esos envíos.
                                    </p>
                                </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-4 variant="link">
                                    TERCERO: LIBERTAD DE NAVEGACIÓN y ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES
                                </b-button>
                                </b-card-header>
                                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <p class="card-text">
                                    La mera visita de este sitio no impone ningún tipo de obligación para el usuario, a menos que éste exprese de forma inequívoca, por medio de actos positivos, su voluntad de contratar con la empresa para adquirir bienes o servicios, en la forma indicada en estos términos y condiciones.<br>
                                    <br>   
                                    Para aceptar estos Términos y Condiciones, el usuario deberá hacer click donde el sitio web de Vamproden SPA ofrezca esta opción en la interfaz del usuario con la frase “He leído y aceptado” u otra equivalente que permita dar su consentimiento inequívoco respecto de la aceptación.<br>
                                    </p>
                                </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-5 variant="link">
                                    CUARTO: CÓMO COMPRAR
                                </b-button>
                                </b-card-header>
                                <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <p class="card-text">
                                        Todas las compras son enviadas desde Valdivia hacia todo el país.<br>
                                    <br>Para hacer compras en este sitio deberá seguir los siguientes pasos haciendo click en el campo correspondiente:<br>
                                    <br>Para iniciar el proceso de compra es necesario que confirme haber leído y aceptado estos Términos y Condiciones.<br>
                                    <br>1. Seleccione el producto o los productos que le interesa y agréguelo a su “carro de compra”.<br>
                                    <br>2. Cuando ya esté seguro de su compra a realizar presione el carrito en el cual se indica el valor a pagar, complete con sus datos de envío y facturación si es el caso. En caso de no completar los datos de facturación correctamente se considerará boleta.<br>
                                    <br>3. Seleccione el medio de pago. De click  a finalizar compra.<br>
                                    <br>4. Continue pasos indicados por transbank para realizar pago.<br>
                                    <br>5. Una vez realizada y aceptada la compra, se desplegará en la pantalla:<br>
                                        <br>&emsp;&emsp;5.1 Detalle de productos adquiridos y sus cantidades respectivas.<br>
                                        <br>&emsp;&emsp;5.2.Valores por producto y del total de la operación.<br>
                                        <br>&emsp;&emsp;5.3 Estado de aprobación o rechazo de pago.<br>
                                    <br>6. Se le enviará un correo automáticamente con su compra si esta fue aprobada con detalle de la misma.<br>
                                    <br>7. Su compra será despachada en un plazo máximo de 72 horas hábiles a partir de efectuada la compra.<br>
                                    <br>8. Posterior a la facturación se le enviará un número único de la orden de transporte, con la cual podrá hacer seguimiento en línea en pagina web transportista.
                                    </p>
                                </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-6 variant="link">
                                    QUINTO: MEDIOS DE PAGO
                                </b-button>
                                </b-card-header>
                                <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <p class="card-text">
                                        Los productos sólo podrán ser pagados por medio de:<br>
                                        <br>1. Tarjeta de crédito bancarias Visa, Mastercard, Dinners Club International o American Express, emitidas en Chile o en el extranjero, que mantengan un contrato vigente con Transbank<br>
                                        <br>2. Tarjetas de débito bancarias acogidas al sistema Redcompra, emitidas en Chile por bancos nacionales, que mantengan un contrato vigente para tales efectos con  Transbank.<br>
                                        <br>Los usuarios declaran que entienden que estos medios de pago o portales de pago pertenecen a terceras empresas proveedoras de estos servicios, independientes y no vinculadas a Vamproden SPA, por lo que la continuidad de su prestación de servicios en el tiempo, así como el correcto funcionamiento de sus herramientas y botones de pago en línea, será de exclusiva responsabilidad de las empresa proveedoras de estos servicios y en ningún caso de Vamproden Spa<br>
                                    </p>
                                </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-7 variant="link">
                                    SEXTO: DERECHOS Y GARANTÍAS
                                </b-button>
                                </b-card-header>
                                <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <p class="card-text">
                                        <b>Cambios y devoluciones</b>
                                        <br><br>Para cambio o devoluciones se deberá cumplir con las siguientes condiciones:
                                        <br><br>1. El cliente debe presentar la boleta o factura que acrediten la compra
                                        <br><br>2. El producto debe estar nuevo, sin uso, con sus etiquetas, empaque correspondiente y en perfectas condiciones.
                                        <br><br><b>No es lo que compre:</b> Contacte con nosotros a través de nuestros teléfonos, mail contacto@vamproden.cl y/o whatsapp para coordinar el retiro. El costo del transporte será asumido por Vamproden.
                                        <br><br><b>Quieres un cambio de producto:</b> El producto debe encontrarse en su empaque original, sellados y en buen estado, la devolución debe realizarse dentro de 10 días corridos una vez recibido el/los producto(s). Los costos de envío son responsabilidad del cliente. 
                                        <br><br>Una ejecutiva te contactará a fono indicado en tu compra para coordinar los nuevos productos elegidos. Considera que no hay reembolso de dinero.
                                        <br><br><b>Necesitas la devolución de tu dinero?</b> Ten en cuenta que la opción estará disponible dependiendo del estado en que se encuentre tu pedido y el reembolso en tarjeta solo aplica para compras realizadas con tarjeta crédito.
                                        <br><br>Si compraste con retiro en un punto, lo puedes cancelar antes de que pase a estado Listo para retiro.
                                        <br><br>Si compraste con envío a domicilio, solo lo podrás cancelar antes de que pase al estado Pedido en preparación. Luego de eso, tu pedido estará en camino y no podemos detener al transportista.
                                        <br><br>Una vez facturados. Solo podremos realizar por cambio de producto, debido a que los impuestos y comisiones ya estarán cancelados. 
                                        <br><br>El reembolso se realiza a través de sistema Transbank por lo que plazos de devolución y tramite quedan sujetos a este sistema

                                        <br><br><b>Garantías Legales</b>
                                        <br><br>En caso que el producto no cuente con las características técnicas informadas, si estuviera dañado o incompleto, el cliente deberá informar a la empresa en un plazo no superior a 10 días para consumibles y de 30 días para equipamiento desde recibida su compra, siempre que cumpla con las siguientes condiciones:
                                        <br><br>1. El cliente debe presentar la boleta o factura que acrediten la compra
                                        <br><br>2. El producto debe estar nuevo, sin uso, con sus etiquetas correspondientes y en perfectas condiciones.
                                        <br><br>3. Entregar o despachar el producto a nuestra sala de ventas ubicada en Errazuriz 2066, Valdivia para evaluación del producto. El despacho debe venir pago y en caso de comprobar la falla se le hará la devolución del dinero vía transferencia electrónica.
                                        <br><br>4. Si la falla es de Fabrica se coordinará con el cliente las medidas a tomar (cambio o reparación).
                                        <br><br>5. Si la evaluación de la falla determina que no corresponde a una falla de fábrica, se le hará devolución del producto junto con un informe que respalde la conclusión

                                        <br><br><b>Se considerará como falla o defecto:</b> 

                                        <br><br>Productos sujetos a normas de seguridad o calidad de cumplimiento obligatorio que no cumplan con las especificaciones
                                        <br><br>Si los materiales, partes, piezas, elementos, sustancias o ingredientes que constituyan o integren los productos no correspondan a las especificaciones que ostenten o a las menciones del rotulado.
                                        <br><br>Productos que por deficiencias de fabricación, elaboración, materiales, partes, piezas, elementos, sustancias, ingredientes, estructura, calidad o condiciones sanitarias, en su caso, no sea enteramente apto para el uso o consumo al que está destinado o al que el proveedor hubiese señalado en su publicidad.
                                        <br><br>Si el proveedor y consumidor hubieren convenido que los productos objeto del contrato deban reunir determinadas especificaciones y esto no ocurra.
                                        <br><br>Si después de la primera vez de haberse hecho efectiva la garantía y prestado el servicio técnico correspondiente, subsistieren las deficiencias que hagan al bien inapto para el uso o consumo a que se refiere el numeral 3. Este derecho subsistirá para el evento de presentarse una deficiencia a la que fue objeto del servicio técnico, o volviere a presentarse la misma.
                                        <br><br>Si la cosa objeto del contrato tiene efectos o vicios ocultos que imposibiliten el uso a que habitualmente se destine.

                                    </p>
                                </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-8 variant="link">
                                    SEPTIMA: ENTREGA DE PRODUCTOS
                                </b-button>
                                </b-card-header>
                                <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <p class="card-text">
                                        Despacho de Productos
                                        <br><br>Las condiciones de despacho y entrega de los productos adquiridos podrán ser escogidas de entre las opciones ofrecidas en el sitio.
                                        <br><br>Compra Online retirada en tienda
                                        <br><br>Si desea retirar su compra en tienda deberá indicarlo haciendo click en “retiro en tienda” para así tenerlo presente al momento de la facturación. La entrega puede que no sea inmediata, por lo cual se consideran al menos 2 días hábiles después de realizada la compra. Para el retiro de la compra se solicitará el número de orden y cédula de quién hizo la compra.
                                        <br><br>Compra Online despacho domicilio
                                        <br><br>Su compra será despachada, bajo transportes formales o propios de la empresa, donde prioritaría la eficiencia para que recibas lo antes posible su compra. Estó dependerá según comuna ver lista asociada a costos.
                                        <br><br>Nuestra empresa se compromete a despachar dentro de las primeras 48 horas hábiles;  el tiempo de entrega será condicionada a los tiempos de empresa de transporte. Favor tener en cuenta que si el pedido no logra ser entregado por ausencia de receptor, podrían haber recargos asociados al flete.
                                    </p>
                                </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-9 variant="link">
                                    OCTAVO: RESPONSABILIDAD
                                </b-button>
                                </b-card-header>
                                <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <p class="card-text">
                                        En ningún caso Vamproden responderá por:
                                        <br><br>La utilización indebida que los visitantes de www.vamproden.cl puedan hacer de los materiales exhibidos, de los derechos de propiedad industrial y de los derechos de propiedad intelectual.
                                        <br><br>Daños o eventuales daños y perjuicios que se le puedan causar a los Compradores y/o Usuarios por el funcionamiento de las herramientas de búsqueda y de los errores que se generen por los elementos técnicos de El Sitio o motor de búsqueda.
                                        <br><br>Contenidos de las páginas a las que los Compradores o Usuarios puedan acceder con o sin autorización de Vamproden.
                                        <br><br>El acceso de menores de edad o personas sin capacidad, bajo los términos de la legislación correspondiente, a los contenidos adherentes a la relación contractual que surja de El Sitio.
                                        <br><br>Vamproden  no garantiza la disponibilidad y continuidad del funcionamiento de El Sitio y tampoco que en cualquier momento y tiempo, los Usuarios puedan acceder a las promociones y Ofertas del El Sitio.
                                        <br><br>Vamproden no se hace responsable por los virus ni otros elementos en los documentos electrónicos almacenados en los sistemas informáticos de los Usuarios. Vamproden no responderá de los perjuicios ocasionados al Cliente, provenientes del uso inadecuado de las tecnologías puestas a disposición de éste, cualquiera sea la forma en la cual se utilicen inadecuadamente estas tecnologías. Vamproden no responderá de los daños producidos a El Sitio por el uso indebido y de mala fe de los Usuarios y/o Compradores.
                                        <br><br>No obstante, en el evento de realizarse un doble pago por un Usuario o Comprador en El Sitio, Vamproden devolverá la suma del sobrepago, dentro de los 3 días siguientes a la recepción del respectivo reclamo escrito del Usuario o Comprador, en el que se anexen los originales de los comprobantes del pago adicional a lo adquirido.
                                        <br><br>En todo caso, la responsabilidad de Vamproden contractual, extracontractual o legal, con los Usuarios, Compradores o visitantes de El Sitio no excederá del precio efectivamente pagado por el Comprador en contraprestación por el producto o servicio, sin perjuicio de lo que determinen los Tribunales de Justicia.
                                    </p>
                                </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-10 variant="link">
                                    NOVENO: SEGURIDAD DE DATOS
                                </b-button>
                                </b-card-header>
                                <b-collapse id="accordion-10" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <p class="card-text">
                                        Responsabilidad de los Usuarios respecto de la información registrada en el sitio Vamproden adoptará las medidas necesarias y prudentes para resguardar la seguridad de los datos y clave secreta, como sistemas de encriptación de información, certificados de seguridad u otros que la empresa estime pertinente. En caso de detectarse cambios en la información que hayas registrado en el sitio, o bien, ante cualquier irregularidad en las transacciones relacionadas con su identificación o la del medio de pago, o simplemente como medida de protección a su identidad, nuestros ejecutivos podrán contactarlo por vía telefónica o correo electrónico, a fin de corroborar sus datos e intentar evitar posibles fraudes.
                                        <br><br>En caso de no poder establecer el contacto en un plazo de 72 horas, por su propia seguridad, su orden de compra efectuada en nuestro sitio no podrá ser confirmada. Le informaremos vía telefónica o por correo electrónico que su orden ha quedado sin efecto por no poder confirmar su identidad o el medio de pago ofrecido. Además, los comprobantes de las gestiones realizadas para contactarte y poder confirmar la operación, estarán disponibles en nuestras oficinas durante 30 días, para que puedas confirmar la orden de compra. Cualquier consulta puede ser efectuada a contacto@vamproden.cl . Sin embargo los Usuarios y/o Compradores son exclusivamente responsables por la pérdida, mal uso o uso no autorizado del código de validación, ya sea por parte de los mismos o de terceros, luego de realizada la compra en la forma expresada en los Términos y Condiciones.
                                        <br><br>Datos personales:
                                        <br><br>Los Usuarios y/o Compradores garantizan que la información que suministran para la celebración del contrato es veraz, completa, exacta y actualizada.
                                        <br><br>De conformidad con la Ley 19.628 los datos personales que suministren en el Sitio Web pasarán a formar parte de una base de datos de Vamproden y serán destinados única y exclusivamente en para ser utilizados en los fines que motivaron su entrega y especialmente para la comunicación en general entre la empresa y sus clientes, validar los datos de la compra, concretar el despacho y responder sus consultas. Los datos no serán comunicados a otras empresas sin la expresa autorización de su titular ni serán transferidos internacionalmente.
                                        <br><br>Vamproden jamás solicita datos personales o financieros a través de correo electrónico.
                                        <br><br>Vamproden presume que los datos han sido incorporados por su titular o por persona autorizada por éste, así como que son correctos y exactos. Los Usuarios y/o Compradores con la aceptación de los presentes Términos y Condiciones manifiestan que los datos de carácter personal que aporte a través de los formularios online en la página web de Vamproden pueden ser utilizados para Ofertas posteriores y distintas a las ofrecidas en El Sitio.
                                        <br><br>Sin perjuicio de lo anterior, Vamproden garantiza a los usuarios el libre ejercicio de sus derechos de información, modificación, cancelación y bloqueo de sus datos personales establecidos en la Ley 19.628. Por consiguiente, los compradores podrán realizar requerimientos que digan relación con dichos derechos, y en un plazo máximo de dos días corridos, Vamproden deberá dar respuesta e implementar efectivamente esta solicitud.
                                        <br><br>Documentos Electrónicos:
                                        <br><br>El usuario en su calidad de receptor manual de documentos electrónicos, de conformidad con la Resolución Exenta N° 11 del 14 de febrero de 2003 del Servicio de Impuestos Internos (que estableció el procedimiento para que contribuyentes autorizados para emitir documentos electrónicos puedan también enviarlos por estos medios a receptores manuales), declara y acepta lo siguiente:
                                        <br><br>Al aprobar estos términos y condiciones, el usuario autoriza a la empresa Vamproden, RUT 76.962.948-3, para que el documento tributario correspondiente de esta transacción, le sea entregada solamente por un medio electrónico. De igual forma, autoriza que el aviso de publicación del documento tributario sea enviado mediante correo electrónico.
                                    </p>
                                </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-11 variant="link">
                                    DÉCIMO: ALCANCE DE LAS CONDICIONES INFORMADAS EN EL SITIO
                                </b-button>
                                </b-card-header>
                                <b-collapse id="accordion-11" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <p class="card-text">
                                        Vamproden no modificará las condiciones bajo las cuales haya contratado con los consumidores en este sitio. Mientras aparezcan en este sitio, los precios informados estarán a disposición del usuario, aunque no sean los mismos que se ofrezcan en otros canales de venta de Vamproden, como tiendas físicas, catálogos, televisión, radio, u otros.
                                        <br><br>cambio en las informaciones publicadas en este sitio, incluyendo las referidas a mercaderías, servicios, precios, existencias y condiciones, promociones y ofertas, tendrá lugar antes de recibir una orden de compra y solo se referirá a operaciones futuras, sin afectar, en caso alguno, derechos adquiridos por los consumidores.
                                        <br><br>Las promociones que ofrecidas en el sitio no necesariamente serán las mismas que Vamproden ofrezca por otros canales de venta. En las promociones que consistan en la entrega gratuita o rebajada de un producto por la compra de otro, el despacho del bien que se entregue gratuitamente o a precio rebajado se hará en el mismo lugar al cual se despacha el producto comprado, salvo que el adquirente solicite, al aceptar la oferta, que los productos se remitan a direcciones distintas, en cuyo caso deberá pagar el valor del despacho de ambos productos. No se podrá participar en estas promociones sin adquirir conjuntamente todos los productos comprendidos en ellas.
                                    </p>
                                </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-13 variant="link">
                                    DÉCIMO PRIMERO: PROPIEDAD INTELECTUAL
                                </b-button>
                                </b-card-header>
                                <b-collapse id="accordion-13" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <p class="card-text">
                                        Todos los contenidos incluidos en este sitio, como textos, material gráfico, logotipos, íconos de botones, códigos fuente, imágenes, audio clips, descargas digitales y compilaciones de datos, son propiedad de Vamproden o de sus proveedores de contenidos, y están protegidos por las leyes chilenas e internacionales sobre propiedad intelectual. Los materiales gráficos, logotipos, encabezados de páginas, frases publicitarias, iconos de botones, textos escritos y nombres de servicios incluidos en este sitio son marcas comerciales, creaciones o imágenes comerciales de propiedad de Vamproden en Chile y en otros países.
                                        <br><br>Dichas marcas, creaciones e imágenes comerciales no se pueden usar en relación a ningún producto o servicio que pueda causar confusión entre los clientes y en ninguna forma que desprestigie o desacredite a Vamproden. Las demás marcas comerciales que no sean de propiedad de Vamproden y que aparezcan en este sitio pertenecen a sus respectivos dueños.
                                        <br><br>Todos los derechos no expresamente otorgados en estos Términos y Condiciones son reservados por Vamproden o sus cesionarios, proveedores, editores, titulares de derechos u otros proveedores de contenidos. Ningún producto, imagen o sonido pueden ser reproducidos, duplicados, copiados, vendidos, revendidos, visitados o explotados para ningún fin, en todo o en parte, sin el consentimiento escrito previo de Vamproden. No se puede enmarcar o utilizar técnicas de enmarcación para encerrar alguna marca comercial, logotipo u otra información registrada o patentada (incluyendo imágenes, texto, disposición de páginas, o formulario) de Vamproden, sin nuestro consentimiento escrito previo. Tampoco se puede usar meta etiquetas ni ningún otro “texto oculto” que use el nombre o marcas comerciales de Vamproden, sin autorización escrita previa de esta empresa. Se prohíbe hacer un uso indebido de este sitio o de estas marcas, licencias o patentes. Lo anterior, sin perjuicio de las excepciones expresamente señaladas en la ley.
                                        <br><br>Vamproden respeta la propiedad intelectual de otros. Si cree que su trabajo ha sido copiado en forma tal que constituye una violación del derecho de propiedad intelectual, contáctate con nosotros a contacto@vamproden.cl.
                                    </p>
                                </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-12 variant="link">
                                    DÉCIMO SEGUNDO: LEGISLACIÓN APLICABLE y COMPETENCIA
                                </b-button>
                                </b-card-header>
                                <b-collapse id="accordion-12" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <p class="card-text">
                                        Los presentes términos y condiciones se rigen por las leyes de la República de Chile. Cualquier controversia o conflicto derivado de la utilización del sitio web de Vamproden, sus Términos y Condiciones y las Políticas de Privacidad, su validez, interpretación, alcance o cumplimiento, será sometida a las leyes aplicables de la República de Chile.
                                    </p>
                                </b-card-body>
                                </b-collapse>
                            </b-card>
                        
                        </b-accordion>
                    </div>
                </div>
            </b-card-body>
            <b-card-footer class="text-center">
              <b-button variant="primary" @click="goBack">Volver</b-button>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
</Layout>
  </template>