<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import LoadingMessage from '../LoadingMessage.vue';
import moment from "moment";

/**
 * Starter component
 */
export default {
    components: { Layout, PageHeader, LoadingMessage },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                text: "Utility",
                href: "/"
                },
                {
                text: "Starter Page",
                active: true
                }
            ],
            isLoading:false,
            fileProductosWeb: null,
            fileDeudores: null,
            buttonDisabled: false,
            deudores:[],
            filtro: '',
            filtro2: '',
            filtro3: '',
            filtro4: '',
            filtro5: '',
            lote: '',
            user: '',
            mostrarDetalles: false,
            fields_vendedores: [
                { key: "ven_nombre",label:"Vendedor", sortable: true },
                { key: "saldo",label:"Total", sortable: true },
            ],      
            vendedores:[],
            vendedores2:[],      
            totalSaldosVendedor:[],      
            clientes:[],
        };
    },
    methods: {
        formatFecha(fechahora){
            var fecha = moment(fechahora).format('DD-MM-YYYY');
            return fecha
        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        onFileDeudoresChange(e) {
            this.fileDeudores = e.target.files[0];
        },
        uploadFileDeudores() {
            this.isLoading = true;
            if (this.buttonDisabled) {
                return; // Si está deshabilitado, salir sin hacer nada
            }
            this.buttonDisabled = true;

            let formData = new FormData();
            formData.append('file', this.fileDeudores);

            axios({
                url: '/api/upload_csv_deudores', // URL de la solicitud GET
                data: formData,
                method: 'POST', // Método de la solicitud
                responseType: 'blob', // Tipo de respuesta: archivo blob
            }).then(response => { // Función a ejecutar cuando la solicitud se complete con éxito
                // Crear una URL de objeto para el archivo blob
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const disposition = response.headers['content-disposition'];
                // Extraer el nombre de archivo del encabezado Content-Disposition
                const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
                const filename = matches[1].replace(/['"]/g, '');
                // Crear un enlace y configurar la URL y el nombre de archivo para la descarga
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                // Agregar el enlace al cuerpo del documento, simular un clic en el enlace y eliminarlo después
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.isLoading = false;
            }).catch(error => { // Función a ejecutar si hay un error en la solicitud
                console.error('Error al descargar el archivo CSV', error);
                this.isLoading = false;
            });

            this.actualizar()
            setTimeout(() => {
                // Volver a habilitar el botón después de algún tiempo (por ejemplo, 3 segundos)
                this.buttonDisabled = false;
            }, 5000);
        },
        actualizar(){
            this.isLoading = true;
            axios.get('/api/get_deudores').then(res=>{
                if(res.data.estado){
                    this.lote =res.data.lote 
                    this.deudores =res.data.clientesVencidos 
                    this.vendedores =res.data.vendedores 
                    this.vendedores2 =res.data.vendedores2 
                    this.clientes =res.data.clientes 
                    this.user =res.data.user
                    this.totalSaldosVendedor = this.vendedores.reduce((total, vendedor) => total + vendedor.saldo, 0);
                }
                this.isLoading = false;
            });
        },
        stringToColor(str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            const hue = Math.abs(hash % 360);
            return `hsl(${hue}, 70%, 80%)`;
        },
        stringToGrayscale(str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            const grayscale = Math.abs(hash % 256);
            return `rgb(${grayscale}, ${grayscale}, ${grayscale})`;
        }
    },
    mounted() {
        this.actualizar()
        this.totalRows = this.vendedores2.length;
    },
    computed: {
        deudoresFiltrados() {
            let deudoresFiltrados = this.deudores.filter(deudor =>
                deudor.cli_nombre.toLowerCase().includes(this.filtro.toLowerCase()) && 
                deudor.ven_nombre.toLowerCase().includes(this.filtro2.toLowerCase()) &&
                deudor.documento.toLowerCase().includes(this.filtro3.toLowerCase()) &&
                deudor.estado.toLowerCase().includes(this.filtro4.toLowerCase())
            );

            // Sumar los saldos de los deudores filtrados
            let totalSaldo = deudoresFiltrados.reduce((total, deudor) => total + deudor.saldo, 0);
            let totalSaldoFact = deudoresFiltrados.reduce((total, deudor) => total + deudor.saldo_fact, 0);

            // Retornar los deudores filtrados y el total de saldos
            return { deudoresFiltrados, totalSaldo, totalSaldoFact };
        },
        clientesVendedor(){
            let clientesPorVendedor = this.clientes.filter(cliente =>
                cliente.ven_nombre.toLowerCase().includes(this.filtro5.toLowerCase())
            );
            return { clientesPorVendedor };
        }
        
    },
};
</script>
<template>
    <Layout>
        <div class="text-center">
            <loading-message v-if="isLoading" />
        </div>
        <div class="row">
            <!-- <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Exportar ProductosWeb a CSV </h4>
                        <p>Columnas que se exportarán: ID, Tipo, SKU, Nombre, Marca, PrecioNormal, PrecioRebajado, EstadoInventario, Stock, Categoria, Estado, Destacado, Eliminado</p>
                        <a @click="exportar" :disabled="buttonDisabled" class="btn btn-primary">Exportar a CSV</a>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Cargar ProductosWeb desde CSV</h4>
                        <div>
                            <b-form-file
                                v-model="fileProductosWeb"
                                :state="Boolean(fileProductosWeb)"
                                placeholder="Selecciona un archivo o arrástralo aquí..."
                                drop-placeholder="Suelta el archivo aquí..."
                                @change="onFileProductosWebChange"
                            ></b-form-file>

                            <a @click="uploadFileProductosWeb" :disabled="buttonDisabled" class="btn btn-primary">Importar CSV</a>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Cargar Deudores desde CSV </h4>
                        <div>
                            <b-form-file
                                v-model="fileDeudores"
                                :state="Boolean(fileDeudores)"
                                placeholder="Selecciona un archivo o arrástralo aquí..."
                                drop-placeholder="Suelta el archivo aquí..."
                                @change="onFileDeudoresChange"
                            ></b-form-file>

                            <a @click="uploadFileDeudores" :disabled="buttonDisabled" class="btn btn-primary">Importar CSV</a>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="col-12">
                <h2>Ultimos datos cargados el {{ formatFecha(lote.fecha) }} lote {{ lote.id }}:</h2>
                <br>
            </div>
            <div class="col-5">
                <select name="select" id="select" v-model="filtro5">
                    <option value=''>TODAS</option>
                    <option v-for="(vendedor, index) in vendedores" :key="index" :value="vendedor.ven_nombre">{{ vendedor.ven_nombre }}</option>
                </select>
                <table>
                    <thead>
                        <tr>
                            <th>Vendedor</th>
                            <th>Cliente</th>
                            <th>Saldo Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(cliente, index) in clientesVendedor.clientesPorVendedor" :key="index" :style="{ backgroundColor: stringToColor(cliente.ven_nombre)}">
                            <td>{{ cliente.ven_nombre }}</td>
                            <td>{{ cliente.cli_nombre }}</td>
                            <td>{{ cliente.saldo }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br>
            <div class="col-5">
                <table>
                <thead>
                    <tr>
                    <th>Vendedor</th>
                    <th>Saldo Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(vendedor, index) in vendedores" :key="index" :style="{ backgroundColor: stringToColor(vendedor.ven_nombre)}">
                        <td>{{ vendedor.ven_nombre }}</td>
                        <td>{{ vendedor.saldo }}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{{ totalSaldosVendedor }}</td>
                    </tr>
                </tbody>
                </table>
            </div>
            <br>
            <!-- <div class="table-responsive mb-0">
                <b-table
                    :items="vendedores2"
                    :fields="fields_vendedores"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                >
                
                </b-table>
            </div> -->
            <br>
            <div class="col-12" v-if="user.email_verified_at!=null">
                <div class="card">
                    <div class="card-body">
                        <input type="text" v-model="filtro" placeholder="Nombre">
                        <input type="text" v-model="filtro2" placeholder="Vendedor">
                        <input type="text" v-model="filtro3" placeholder="Documento">
                        <select name="select" id="select" v-model="filtro4">
                            <option value=''>TODAS</option>
                            <option value="VENCIDO">VENCIDO</option>
                            <option value="INGRESO">INGRESO</option>
                            <option value="VIGENTE">VIGENTE</option>
                        </select>
                        <label>
                            <input type="checkbox" v-model="mostrarDetalles"> Mostrar detalles
                        </label>
                        <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nombre</th>
                                <th v-show="mostrarDetalles">Dirección</th>
                                <th v-show="mostrarDetalles">Comuna</th>
                                <th v-show="mostrarDetalles">Teléfono</th>
                                <th v-show="mostrarDetalles">ID Vendedor</th>
                                <th>Nombre Vendedor</th>
                                <th>Global</th>
                                <th>Documento</th>
                                <th>Vence</th>
                                <th>Debe</th>
                                <th>Haber</th>
                                <th>Saldo</th>
                                <th>Debe Fact</th>
                                <th>Haber Fact</th>
                                <th>Saldo Fact</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="deudor in deudoresFiltrados.deudoresFiltrados" :key="deudor.id" :style="{ backgroundColor: stringToColor(deudor.cli_nombre) }">
                                <td>{{ deudor.cli_id }}</td>
                                <td>{{ deudor.cli_nombre }}</td>
                                <td v-show="mostrarDetalles">{{ deudor.cli_dir }}</td>
                                <td v-show="mostrarDetalles">{{ deudor.cli_comuna }}</td>
                                <td v-show="mostrarDetalles">{{ deudor.cli_tel }}</td>
                                <td v-show="mostrarDetalles">{{ deudor.ven_id }}</td>
                                <td>{{ deudor.ven_nombre }}</td>
                                <td>{{ deudor.global }}</td>
                                <td>{{ deudor.documento }}</td>
                                <td>{{ deudor.vence }}</td>
                                <td style="text-align: right;">{{ deudor.debe }}</td>
                                <td style="text-align: right;">{{ deudor.haber }}</td>
                                <td style="text-align: right;">{{ deudor.saldo }}</td>
                                <td style="text-align: right;">{{ deudor.debe_fact }}</td>
                                <td style="text-align: right;">{{ deudor.haber_fact }}</td>
                                <td style="text-align: right;">{{ deudor.saldo_fact }}</td>
                                <td>{{ deudor.estado }}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td v-show="mostrarDetalles"></td>
                                <td v-show="mostrarDetalles"></td>
                                <td v-show="mostrarDetalles"></td>
                                <td v-show="mostrarDetalles"></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="text-align: right;">{{deudoresFiltrados.totalSaldo}}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>