<script>
    export default {
        props:{
            orden:{},
            limpiar:{},
        },
        methods: {

        },
        created(){
            console.log(this.limpiar)
            if(this.limpiar){
                localStorage.setItem('cart', JSON.stringify({}));
            }
        }
    }
</script>
<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="offset-md-2 col-md-8 pt-5">
                    <div class="card" v-if="orden.aprobado">
                        <div class="bg-soft-primary text-center pt-3">
                            <div class="row">
                                <div class="col-12">
                                    <img src="/images/Check.png" alt class="img-fluid" width="75"/>
                                    <div class=" text-center text-primary p-3">
                                        <h1 class="text-primary">Detalles de la orden</h1>
                                        <p class="font-size-20">Pago Aprobado</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <p><strong>Estado:</strong> Aprobado</p>
                                </div>
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th>Cantidad</th>
                                                    <th>Precio</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in JSON.parse(orden.cart_items)" :key="item.id">
                                                    <td>{{ item.nombre }}</td>
                                                    <td>{{ item.cantidad }}</td>
                                                    <td>{{ item.precio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-right">
                                        <p><strong>Costo Envío:</strong> {{ orden.envio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</p>
                                        <!-- <p><strong>Descuento:</strong> {{ orden.descuento }}</p> -->
                                        <p class="font-size-20"><strong class="text-primary">Total Pagado:</strong> {{ orden.cart_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</p>
                                    </div>
                                    <div class="text-center">
                                        <a class="btn btn-primary" href="/">Volver a Vamproden</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" v-else>
                        <div class="bg-soft-primary text-center pt-3">
                            <div class="row">
                                <div class="col-12">
                                    <img src="/images/X.png" alt class="img-fluid" width="75"/>
                                    <div class=" text-center text-primary p-3">
                                        <h1 class="text-primary">Detalles de la orden</h1>
                                        <p class="font-size-20">Error en el Pago</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <p><strong>Estado:</strong> Rechazado</p>
                                </div>
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th>Cantidad</th>
                                                    <th>Precio</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in JSON.parse(orden.cart_items)" :key="item.id">
                                                    <td>{{ item.nombre }}</td>
                                                    <td>{{ item.cantidad }}</td>
                                                    <td>{{ item.precio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-right">
                                        <p><strong>Costo Envío:</strong> {{ orden.envio.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</p>
                                        <!-- <p><strong>Descuento:</strong> {{ orden.descuento }}</p> -->
                                        <p class="font-size-20"><strong class="text-primary">Total Pagado:</strong> {{ orden.cart_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</p>
                                    </div>
                                    <div class="text-center">
                                        <p class="text-primary">Intentalo nuevamente</p>
                                        <a class="btn btn-primary" href="/resumen_compra">Volver al carro</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                    

                </div>
            </div>
        </div>

    </div>
</template>

<style lang="scss" scoped>

</style>