<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import LoadingMessage from '../LoadingMessage.vue';

/**
 * Starter component
 */
export default {
    components: { Layout, PageHeader, LoadingMessage },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                text: "Utility",
                href: "/"
                },
                {
                text: "Starter Page",
                active: true
                }
            ],
            isLoading:false,
            fileProductosWeb: null,
            fileDeudores: null,
            buttonDisabled: false,
        };
    },
    methods: {
        exportar(){
            this.isLoading = true;
            if (this.buttonDisabled) {
                return; // Si está deshabilitado, salir sin hacer nada
            }
            this.buttonDisabled = true;
            
            axios({
                url: '/api/csv_productos_web', // URL de la solicitud GET
                method: 'GET', // Método de la solicitud
                responseType: 'blob', // Tipo de respuesta: archivo blob
            }).then(response => { // Función a ejecutar cuando la solicitud se complete con éxito
                // Crear una URL de objeto para el archivo blob
                const url = window.URL.createObjectURL(new Blob([response.data]));

                // Obtener el encabezado Content-Disposition que contiene el nombre de archivo
                const disposition = response.headers['content-disposition'];

                // Extraer el nombre de archivo del encabezado Content-Disposition
                const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
                const filename = matches[1].replace(/['"]/g, '');

                // Crear un enlace y configurar la URL y el nombre de archivo para la descarga
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);

                // Agregar el enlace al cuerpo del documento, simular un clic en el enlace y eliminarlo después
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.isLoading = false;
            }).catch(error => { // Función a ejecutar si hay un error en la solicitud
                console.error('Error al descargar el archivo CSV', error);
                this.isLoading = false;
            });

            setTimeout(() => {
                // Volver a habilitar el botón después de algún tiempo (por ejemplo, 3 segundos)
                this.buttonDisabled = false;
            }, 5000);
        },
        onFileProductosWebChange(e) {
            this.fileProductosWeb = e.target.files[0];
        },
        uploadFileProductosWeb() {
            this.isLoading = true;
            if (this.buttonDisabled) {
                return; // Si está deshabilitado, salir sin hacer nada
            }
            this.buttonDisabled = true;

            let formData = new FormData();
            formData.append('file', this.fileProductosWeb);

            axios.post('/api/upload_csv_productos', formData).then(response => {
                if(response.data.estado){
                    console.log(response.data.mensaje);
                    console.log(response.data.correctos);
                    console.log(response.data.errores);
                }
                this.isLoading = false;
                window.location.href = '/cargar-productos'

            }).catch(error => {
                console.error(response.data.mensaje, error);
                this.isLoading = false;
            });

            setTimeout(() => {
                // Volver a habilitar el botón después de algún tiempo (por ejemplo, 3 segundos)
                this.buttonDisabled = false;
            }, 5000);
        },
        onFileDeudoresChange(e) {
            this.fileDeudores = e.target.files[0];
        },
        uploadFileDeudores() {
            this.isLoading = true;
            if (this.buttonDisabled) {
                return; // Si está deshabilitado, salir sin hacer nada
            }
            this.buttonDisabled = true;

            let formData = new FormData();
            formData.append('file', this.fileDeudores);

            axios({
                url: '/api/upload_csv_deudores', // URL de la solicitud GET
                data: formData,
                method: 'POST', // Método de la solicitud
                responseType: 'blob', // Tipo de respuesta: archivo blob
            }).then(response => { // Función a ejecutar cuando la solicitud se complete con éxito
                // Crear una URL de objeto para el archivo blob
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const disposition = response.headers['content-disposition'];
                // Extraer el nombre de archivo del encabezado Content-Disposition
                const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
                const filename = matches[1].replace(/['"]/g, '');
                // Crear un enlace y configurar la URL y el nombre de archivo para la descarga
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                // Agregar el enlace al cuerpo del documento, simular un clic en el enlace y eliminarlo después
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.isLoading = false;
            }).catch(error => { // Función a ejecutar si hay un error en la solicitud
                console.error('Error al descargar el archivo CSV', error);
                this.isLoading = false;
            });

            setTimeout(() => {
                // Volver a habilitar el botón después de algún tiempo (por ejemplo, 3 segundos)
                this.buttonDisabled = false;
            }, 5000);
        }
    },
};
</script>
<template>
    <Layout>
        <div class="row">
            <loading-message v-if="isLoading"/>
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Exportar ProductosWeb a CSV </h4>
                        <p>Columnas que se exportarán: ID, Tipo, SKU, Nombre, Marca, PrecioNormal, PrecioRebajado, EstadoInventario, Stock, Categoria, Estado, Destacado, Eliminado</p>
                        <b-button  variant="info" @click="exportar" :disabled="buttonDisabled" class="btn btn-primary">Exportar a CSV</b-button>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Cargar ProductosWeb desde CSV</h4>
                        <div>
                            <b-form-file
                                v-model="fileProductosWeb"
                                :state="Boolean(fileProductosWeb)"
                                placeholder="Selecciona un archivo o arrástralo aquí..."
                                drop-placeholder="Suelta el archivo aquí..."
                                @change="onFileProductosWebChange"
                            ></b-form-file>

                            <b-button  variant="info" @click="uploadFileProductosWeb" :disabled="!Boolean(fileProductosWeb)">Importar CSV</b-button>
                        </div>
                        <!-- <export-excel
                            class   = "btn btn-primary btn-block"
                            :data   = "json_data"
                            :fields = "json_fields"
                            worksheet = "My Worksheet"
                            :name    = "nombre"
                            >
                            Exportar
                        </export-excel> -->
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>