<script>
import HorizontalTopbar from "../../components/horizontal-topbar";
import HorizontalNavInicio from "../../components/horizontal-nav-inicio";
import RightBar from "../../components/right-bar";
import Footer from "../../components/footer2";
    export default {
        components: {
            HorizontalTopbar,
            HorizontalNavInicio,
            RightBar,
            Footer
        },
        props:{
            submiturl: {
            type: String,
            required: true
            },
            autherror: {
            type: String,
            required: false,
            default: () => null
            },
            autenticado:'',
        },
        data(){
            return{
                windowWidth5: window.innerWidth,
                windowHeight5: window.innerHeight
            }
        },
        mounted() {
            window.addEventListener('resize', this.handleResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods:{
            toggleRightSidebar() {
                document.body.classList.toggle("right-bar-enabled");
            },
            hideRightSidebar() {
                document.body.classList.remove("right-bar-enabled");
            },
            handleResize() {
                this.windowWidth5 = window.innerWidth;
                this.windowHeight5 = window.innerHeight;
            }
        }
    }
</script>
<template>
    <div>
    <!-- Begin page -->
        <div id="layout-wrapper">
            <a href="https://wa.me/56987232150" class="btn-wsp" target="_blank"></a>
            <HorizontalTopbar :submiturl='submiturl' :autherror="autherror" :autenticado="autenticado"/>
            <!--<HorizontalNavInicio />-->
            <!-- ============================================================== -->
            <!-- Start right Content here -->
            <!-- ============================================================== -->
            <div class="main-content2">
                <div class="page-content2">
                    <div :class="windowWidth5>576? 'container-fluid mt-5':'container-fluid mt-10'">
                        <slot/>
                    </div>
                    <!-- container-fluid -->
                </div>
                <br>
                <br>
                <br>
                <!-- End Page-content -->
                <Footer />
            </div>
        <!-- end main content-->
        </div>
        <!-- END layout-wrapper -->
        <RightBar/>
    </div>
</template>
<style lang="scss" scoped>

</style>